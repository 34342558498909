import { cloneDeep } from 'lodash';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';
import { ReactComponent as Minus } from '../../../../../assets/icons/minus.svg';
import { ReactComponent as Plus } from '../../../../../assets/icons/plus.svg';
import { ReactComponent as Reset } from '../../../../../assets/icons/reset.svg';
import { MovementType, SourceOfMovement, ToothChange } from '../../../../../common/evergine/types';
import { AbilityAction, AbilityContext, OrthoAbilitySubject } from '../../../../../shared';
import { useOrthBoundStore } from '../../../../stores/useStore';
import { TeethMovementsToolsType, teethMovementsTools } from './ToolsIcons';

import { MeasureUnit, TreatmentChangeValue, buildTreatmentChangeKey } from '../../../../models';
import { OrthToolButtonReset } from './orthToolButtonReset/';
import { OrthToolButtonSmall } from './orthToolButtonSmall/OrthToolButtonSmall';

import './orthTeethTools.scss';
import {
  defaultMovementsValues,
  DEGREES_STEP_NUMBER,
  emptyDeltaValues,
  getMaxValue,
  MM_STEP_NUMBER,
  MOVEMENTS_DECIMALS,
  TeethMovementsValue,
  unitTypeForMovement,
  VALID_NUMBER_REGEX
} from './OrthTeethTools.helper';
import { useToothTransformStore } from '../../../../stores/useToothTransformStore';

interface OrthTeethToolsProps {
  onCaseModification?: (isModified: boolean) => void;
}

export function OrthTeethTools({ onCaseModification }: OrthTeethToolsProps) {
  const { treatmentSuggestedChanges, setTreatmentSuggestedChanges, selectedTeethId, stageIsLoaded } = useOrthBoundStore(
    (state) => ({
      treatmentSuggestedChanges: state.treatmentSuggestedChanges,
      setTreatmentSuggestedChanges: state.setTreatmentSuggestedChanges,
      selectedTeethId: state.selectedTeethId,
      stageIsLoaded: state.stageIsLoaded
    })
  );

  const { selectedTeethTransformData, teethTransformDataList } = useToothTransformStore((state) => ({
    selectedTeethTransformData: state.selectedTeethTransformData,
    teethTransformDataList: state.teethTransformDataList
  }));

  const selectedToothId = selectedTeethId[0];

  const [t] = useTranslation();
  const ability = useContext(AbilityContext);

  const [selectedMovement, setSelectedMovement] = useState<SourceOfMovement>(SourceOfMovement.Crown);
  const [movementsValues, setMovementsValues] = useState<TeethMovementsValue[]>([...defaultMovementsValues]);
  const [lastMovement, setLastMovement] = useState<MovementType>();
  const [disabledInput, setDisabledInput] = useState<boolean>(false);
  const [deltaTransform, setDeltaTransform] = useState<number>(0);
  const [isReset, setIsReset] = useState<boolean>();
  const [deltaValues, setDeltaValues] = useState<TeethMovementsValue[]>(emptyDeltaValues);
  const setIprProcessed = useOrthBoundStore().setIprProcessed;

  const deltaValuesRef = useRef(deltaValues);
  const deltaTransformRef = useRef(deltaTransform);

  useEffect(() => {
    if (selectedTeethId.length > 1) {
      setDeltaValues(emptyDeltaValues);
      setIsReset(true);
    }
  }, [selectedTeethId]);

  useEffect(() => {
    if (isReset) {
      setDeltaValues(emptyDeltaValues);
      setIsReset(false);
    }
  }, [isReset]);

  useEffect(() => {
    if (!stageIsLoaded) {
      return;
    }
    onSelectTool(MovementType.MultiplePlane);
  }, [stageIsLoaded]);

  useEffect(() => {
    if (selectedMovement === SourceOfMovement.Root) {
      if (!ability || ability.can(AbilityAction.Manage, OrthoAbilitySubject.TreatmentValidation)) {
        return setDisabledInput(true);
      }
    }
    return setDisabledInput(false);
  }, [selectedMovement, ability]);

  useEffect(() => {
    if (!selectedTeethTransformData) {
      return;
    }

    function updateMovementsValues() {
      const newValues: { [key: string]: number } = {
        [MovementType.ExtrusionIntrusion]: selectedTeethTransformData?.extrusionIntrusion,
        [MovementType.TranslationVL]: selectedTeethTransformData?.translationVL,
        [MovementType.TranslationMD]: selectedTeethTransformData?.translationMD,
        [MovementType.PureRotation]: selectedTeethTransformData?.pureRotation,
        [MovementType.MesialRotation]: 0,
        [MovementType.DistalRotation]: 0,
        [MovementType.TIP]: selectedTeethTransformData?.tip,
        [MovementType.Torque]: selectedTeethTransformData?.torque
      };

      const list = [...defaultMovementsValues];
      const treatmentChangesCloned = cloneDeep(treatmentSuggestedChanges);

      list.forEach((movement) => {
        const newValue = newValues[movement.movementType] || 0;
        movement.value = newValue;

        if (newValue !== 0) {
          const key = buildTreatmentChangeKey(
            movement.movementType,
            selectedMovement,
            selectedTeethTransformData?.toothFdi
          );
          treatmentChangesCloned[key] = {
            value: parseFloat(newValue.toFixed(MOVEMENTS_DECIMALS)),
            measureUnit: unitTypeForMovement[movement.movementType]
          } as TreatmentChangeValue;
        }
      });

      setTreatmentSuggestedChanges(treatmentChangesCloned);
      onSaveMovements(list);
    }

    updateMovementsValues();
  }, [selectedTeethTransformData, defaultMovementsValues]);

  const updateDeltaTransform = useCallback(
    (newDeltaTransform: number, movementType: MovementType, accumulate = true) => {
      setDeltaTransform(newDeltaTransform);
      deltaTransformRef.current = newDeltaTransform;

      setDeltaValues((deltaValues) => {
        const index = deltaValues.findIndex((dv) => dv.movementType === movementType);

        if (index !== -1) {
          const updatedValues = [...deltaValues];
          const currentValue = updatedValues[index].value || 0;
          updatedValues[index] = {
            ...updatedValues[index],
            value: accumulate ? currentValue + newDeltaTransform : newDeltaTransform
          };
          return updatedValues;
        }

        return deltaValues;
      });
    },
    [setDeltaTransform, deltaTransformRef, setDeltaValues, deltaValuesRef]
  );

  const onSaveMovements = useCallback(
    (newValues: TeethMovementsValue[]) => {
      setMovementsValues(newValues);

      //onCaseModification(true);
    },
    [setMovementsValues, movementsValues]
  );

  const saveInputValue = useCallback(
    (value: any, movementType: MovementType, movementsList: TeethMovementsValue[], measureUnit?: MeasureUnit) => {
      const movementIndex = movementsList.findIndex((m) => m.movementType === movementType);
      const movement = movementsList[movementIndex];
      if (movement) {
        movementsList[movementIndex].value = value;
      } else {
        movementsList.push({
          movementType,
          value,
          measureUnitType: measureUnit
        });
      }

      onSaveMovements([...movementsList]);
    },
    [movementsValues, updateDeltaTransform, onSaveMovements]
  );

  const sendDataToEvergine = useCallback(
    (list: TeethMovementsValue[], movement: MovementType, needsShowDelta = false) => {
      if (selectedTeethId.length === 0 || !selectedToothId) {
        return;
      }
      const lastMovementValue = list.find((mov) => mov.movementType === movement);

      if (lastMovementValue) {
        selectedTeethId.forEach((teethId) => {
          const tooth = teethTransformDataList.find((tt) => tt.toothFdi === teethId);

          if (tooth) {
            const formerValue = (tooth as any)[movement] || 0;
            const forcedParsedLastMovementValue = Number(lastMovementValue.value);

            let value;
            if (selectedTeethId.length === 1) {
              value = forcedParsedLastMovementValue;
            } else {
              value = needsShowDelta
                ? formerValue + deltaTransformRef.current
                : formerValue + forcedParsedLastMovementValue;
            }

            window.App.webEventsProxy.movements.setToothChange({
              kind: lastMovementValue?.movementType,
              value: value,
              source: selectedMovement,
              toothFdi: teethId
            } as ToothChange);
            setIprProcessed(false);
          }
        });
      }
    },
    [selectedToothId, selectedMovement, selectedTeethId, teethTransformDataList, deltaTransformRef.current]
  );

  const sumOrRemoveValue = useCallback(
    (movementType: MovementType, isSum: boolean, measureUnit: MeasureUnit, list: TeethMovementsValue[]) => {
      const movementIndex = list.findIndex((m) => m.movementType === movementType);
      const movement = list[movementIndex];
      const isRotationMovement =
        movement.movementType === MovementType.DistalRotation || movement.movementType == MovementType.MesialRotation;
      const step = measureUnit === MeasureUnit.Millimeter ? MM_STEP_NUMBER : DEGREES_STEP_NUMBER;

      let value = movement?.value || 0;
      if (isSum) {
        value += step;
      } else {
        value -= step;
      }

      const newDeltaTransform = isSum ? step : -step;
      if (!isRotationMovement) {
        updateDeltaTransform(newDeltaTransform, movementType);
      }

      const parsedNumber = Math.round(value * 1e12) / 1e12;

      if (movement) {
        list[movementIndex].value = parsedNumber;
      } else {
        list.push({
          movementType,
          value: parsedNumber,
          measureUnitType: measureUnit
        });
      }

      setMovementsValues([...list]);
      sendDataToEvergine(list, movementType, !isRotationMovement);
      setIsReset(isRotationMovement);
    },
    [sendDataToEvergine, movementsValues, selectedTeethTransformData, setDeltaTransform, deltaTransform]
  );

  const onAddValueToInput = useCallback(
    (movementType: MovementType, measureUnit?: MeasureUnit) => {
      sumOrRemoveValue(movementType, true, measureUnit, movementsValues);
    },
    [sumOrRemoveValue, movementsValues, deltaTransform]
  );

  const onRemoveValueToInput = useCallback(
    (movementType: MovementType, measureUnit?: MeasureUnit) => {
      sumOrRemoveValue(movementType, false, measureUnit, movementsValues);
    },
    [sumOrRemoveValue, movementsValues]
  );

  const onChangeInput = (value: string, teethMovementType: MovementType, measureUnitType: MeasureUnit): boolean => {
    const isNegativeSign = value.startsWith('-');

    if ((value.length === 1 && isNegativeSign) || value.length === 0 || !isNaN(Number(value))) {
      const dotPosition = value.indexOf('.');
      const valueToSave =
        value.length > 3 && dotPosition > 0 && VALID_NUMBER_REGEX.test(value)
          ? Number(value).toFixed(MOVEMENTS_DECIMALS)
          : value;
      saveInputValue(valueToSave, teethMovementType, movementsValues, measureUnitType);
      return;
    }

    if (!VALID_NUMBER_REGEX.test(value)) {
      return;
    }

    saveInputValue(value, teethMovementType, movementsValues, measureUnitType);
  };

  const onClickResetAll = useCallback(() => {
    if (selectedTeethId?.length === 0) {
      return;
    }

    defaultMovementsValues.find((m) => m.movementType === MovementType.MesialRotation).value = 0;
    defaultMovementsValues.find((m) => m.movementType === MovementType.DistalRotation).value = 0;

    setMovementsValues(defaultMovementsValues);
    setLastMovement(undefined);
    setTreatmentSuggestedChanges({});

    selectedTeethId.forEach((toothId) => {
      const changeKey: ToothChange = {
        kind: MovementType.Reset,
        toothFdi: toothId
      };
      window.App.webEventsProxy.movements.setToothChange(changeKey);
    });

    setIsReset(true);
  }, [selectedMovement, defaultMovementsValues, selectedTeethId]);

  const getInputValue = useCallback(
    (movementType: MovementType): any => {
      const movement = movementsValues.find((m) => m.movementType === movementType);

      if (selectedTeethId.length === 0 || !movement || isReset) {
        return '0.00';
      }

      if (typeof movement?.value === 'number') {
        if (selectedTeethId.length === 1) {
          return movement?.value.toFixed(MOVEMENTS_DECIMALS);
        } else {
          const value = deltaValues.find((dv) => dv.movementType === movementType)?.value;
          return value !== null ? value?.toFixed(MOVEMENTS_DECIMALS) : '0.00';
        }
      }

      return movement?.value;
    },
    [movementsValues, selectedToothId, selectedTeethId, lastMovement, deltaValues, isReset]
  );

  const onSelectTool = (movementType: MovementType) => {
    setLastMovement(movementType);
    window.App.webEventsProxy.movements.selectTool(movementType);
  };

  const onInputKeyPress = useCallback(
    (e: any, movementType: MovementType) => {
      if (e.key !== Key.Enter || !VALID_NUMBER_REGEX.test(e.target.value)) {
        return;
      }

      sendDataToEvergine(movementsValues, movementType, false);
      const value = movementsValues.find((mv) => mv.movementType === movementType).value;
      const parsedNumber = Math.round(value * 1e12) / 1e12;
      updateDeltaTransform(parsedNumber, movementType, false);
    },
    [sendDataToEvergine, movementsValues, updateDeltaTransform]
  );

  const isButtonDisabled = useCallback(
    (isToolDisabled: boolean) => {
      return !selectedToothId || selectedToothId < 0 || isToolDisabled || disabledInput;
    },
    [selectedToothId, disabledInput]
  );

  const movementToolComponent = useCallback(
    (tool: TeethMovementsToolsType, index: number) => {
      return (
        <div
          className={`orth-movements__content ${tool.teethMovementType === lastMovement ? 'is-active' : ''}`}
          key={`s-${index}`}
        >
          <div className="orth-movements__row" onClick={() => onSelectTool(tool.teethMovementType)}>
            <div className="orth-movements__icon">{<tool.icon />}</div>
            <div className="orth-movements__label">{t(tool.titleKey)}</div>
            {!tool.hideButtons && (
              <>
                <input
                  className="orth-movements__input"
                  disabled={isButtonDisabled(tool.disabled)}
                  value={getInputValue(tool.teethMovementType)}
                  onChange={(e) => onChangeInput(e.target.value, tool.teethMovementType, tool.measureUnitType)}
                  onKeyPress={(e: any) => onInputKeyPress(e, tool.teethMovementType)}
                  placeholder={`0.0 ${tool.measureUnitType}`}
                  max={getMaxValue(tool.measureUnitType)}
                  min={-getMaxValue(tool.measureUnitType)}
                  step={tool.measureUnitType === MeasureUnit.Millimeter ? MM_STEP_NUMBER : DEGREES_STEP_NUMBER}
                  data-testid={tool.teethMovementType}
                />

                <div className="orth-movements__row">
                  <OrthToolButtonSmall
                    Icon={Plus}
                    isDisabled={isButtonDisabled(tool.disabled)}
                    onClick={() => onAddValueToInput(tool.teethMovementType, tool.measureUnitType)}
                  />
                  <OrthToolButtonSmall
                    Icon={Minus}
                    isDisabled={isButtonDisabled(tool.disabled)}
                    onClick={() => onRemoveValueToInput(tool.teethMovementType, tool.measureUnitType)}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      );
    },
    [
      disabledInput,
      lastMovement,
      getInputValue,
      isButtonDisabled,
      onAddValueToInput,
      onRemoveValueToInput,
      sendDataToEvergine,
      onInputKeyPress
    ]
  );

  return (
    <div className="orth-movements__teeth-tool">
      <div className="orth-movements__header">
        {/* <Radio
          active={selectedMovement === SourceOfMovement.Crown}
          label={t('dentalMovements.movementsTool.teeth.crown')}
          callBack={() => setSelectedMovement(SourceOfMovement.Crown)}
        />
        <div>
          <Radio
          active={selectedMovement === SourceOfMovement.Root}
          label={t('dentalMovements.movementsTool.teeth.root')}
          callBack={() => setSelectedMovement(SourceOfMovement.Root)}
        />
        </div> */}
        <OrthToolButtonReset Icon={Reset} isDisabled={!selectedToothId || disabledInput} onClick={onClickResetAll} />
      </div>
      <div className="orth-movements__separator" />
      <div className="separator" />
      <div className="scroll">
        {teethMovementsTools.map((tool: TeethMovementsToolsType, index: number) => movementToolComponent(tool, index))}
      </div>
    </div>
  );
}
