import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BoltonIcon } from '../../../assets/icons/bolton.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { LabelSwitch, RemarkedValueLabel, SimpleTable, Tooth } from '../../../common';

import { useBoltonPanelData } from '../../../hooks/orthodontics/useBolton';
import './boltonPanel.scss';
import { ToolPanelDraggableContainer } from '../evergineToolbarElements';
interface BoltonPanelProps {
  opened: boolean;
  onClickClose: () => void;
}

export function BoltonPanel({ opened, onClickClose }: BoltonPanelProps) {
  const [t] = useTranslation();

  const [anteriorValue, setAnteriorValue] = useState<string>();
  const [totalValue, setTotalValue] = useState<string>();
  const [showPercentage, setShowPercentage] = useState<boolean>();

  const { boltonPanelData } = useBoltonPanelData();

  const classes = classNames('boltonpanel', {
    opened
  });

  useEffect(() => {
    if (boltonPanelData) {
      if (showPercentage) {
        setAnteriorValue(boltonPanelData.bolton3Percent);
        setTotalValue(boltonPanelData.bolton6Percent);
      } else {
        setAnteriorValue(boltonPanelData.bolton3Ratio);
        setTotalValue(boltonPanelData.bolton6Ratio);
      }
    }
  }, [showPercentage, boltonPanelData]);

  const toolPosition = useMemo(() => {
    return document.getElementById('bolton-panel-toggle')?.getBoundingClientRect();
  }, []);

  return (
    <>
      {!!toolPosition && (
        <ToolPanelDraggableContainer panelSize={300} toolPosition={toolPosition} handlerElementClass="handle">
          <div className={`${classes} no-select`}>
            <div className="boltonpanel-header">
              <div className="boltonpanel-header--title handle">
                <BoltonIcon />
                <div>{t('boltonPanel.title')}</div>
              </div>
              <button type="button" className="boltonpanel-header--close" onClick={onClickClose}>
                <CloseIcon className="clickable" />
              </button>
            </div>
            <div className="boltonpanel-indexes">
              <RemarkedValueLabel
                value={anteriorValue}
                label={t('boltonPanel.anteriorIndex')}
                key={`bolton-ratio-3_3`}
              />
              <RemarkedValueLabel value={totalValue} label={t('boltonPanel.totalIndex')} key={`bolton-ratio-6_6`} />
              <LabelSwitch
                defaultValue={showPercentage}
                handleToggle={() => setShowPercentage(!showPercentage)}
                label={t('boltonPanel.percentageMode')}
              />
            </div>
            <div className="boltonpanel-data">
              <div className="boltonpanel-data--row">
                <SimpleTable title={t('boltonPanel.upperRightQuadrant')} rows={boltonPanelData?.upperRightTeeth} />
                <SimpleTable title={t('boltonPanel.upperLeftQuadrant')} rows={boltonPanelData?.upperLeftTeeth} />
              </div>
              <div className="boltonpanel-data--row">
                <SimpleTable title={t('boltonPanel.lowerRightQuadrant')} rows={boltonPanelData?.lowerRightTeeth} />
                <SimpleTable title={t('boltonPanel.lowerLeftQuadrant')} rows={boltonPanelData?.lowerLeftTeeth} />
              </div>
            </div>
          </div>
        </ToolPanelDraggableContainer>
      )}
    </>
  );
}
