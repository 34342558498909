import { useState } from 'react';
import { SlideShowBackdrop } from '../../../surgeries/components/captures/photoCaptures/slideShowBackdrop';

interface FileItemProps {
  label: string;
  src: string;
  onClickBackdrop: () => void;
  handleImageError: () => void;
}

export function FileItem(props: FileItemProps) {
  const { label, src, onClickBackdrop, handleImageError } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [imageError, setImageError] = useState(false);

  const handleError = () => {
    setImageError(true);
    handleImageError();
  };

  return (
    <>
      <p>{label}</p>
      {!imageError && src && (
        <div
          className="file-item"
          onMouseEnter={() => {
            setOpen(true);
          }}
          onMouseLeave={() => {
            setOpen(false);
          }}
          onClick={onClickBackdrop}
        >
          <SlideShowBackdrop open={open}>
            <img className="file-item-image" src={src} alt={src} onError={handleError} />
          </SlideShowBackdrop>
        </div>
      )}
    </>
  );
}
