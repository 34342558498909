import { debouncedSetState } from '../../../../common/utils';
import { useOrthBoundStore } from '../../../stores/useStore';

export function OpenAttachesPanel() {
  const attachesPanelCurrentValue = useOrthBoundStore.getState().showAttachesPanel;

  if (!attachesPanelCurrentValue) {
    window.App.webEventsProxy.layers.showAttachments(true);
  }

  debouncedSetState(useOrthBoundStore, { showAttachesPanel: !attachesPanelCurrentValue });
}

export function OpenTadsPanel() {
  const tadsPanelCurrentValue = useOrthBoundStore.getState().showTadsPanel;

  if (!tadsPanelCurrentValue) {
    window.App.webEventsProxy.layers.showTADs(true);
  }

  debouncedSetState(useOrthBoundStore, { showTadsPanel: !tadsPanelCurrentValue });
}
