export enum AbilityRole {
  Client = 'client',
  Employee = 'employee',
  Developer = 'dev'
}

export enum CommonAbilitySubject {
  All = 'all',
  LoadCase = 'load_case',
  CaseStatePanel = 'state_panel',
  GeneralInfoScreen = 'general_info_screen',
  SpecificInfoScreen = 'specific_info_screen',
  UploadDICOMs = 'upload_dicoms',
  Upload3DModels = 'upload_3dmodels',
  UploadPhotos = 'upload_photos',
  Versions = 'versions'
}

export enum SurgeryAbilitySubject {
  ImplantationDesign = 'implantation_design',
  Export = 'export',
  CheckDesign = 'check_design'
}

export enum OrthoAbilitySubject {
  Import3DModelsScreen = 'import_3dmodels_screen',
  TreatmentViewerScreen = 'treatment_viewer_screen',
  InfoPanel = 'info_panel',
  TreatmentValidation = 'treatment_validation',
  Labels = 'treatment_labels',
  DownloadDentalMovementHistoric = 'download_dental_movement_historic',
  TeethSegmentationScreen = 'teeth_segmentation_screen',
  AxisAndRootsScreen = 'axis_and_roots_screen',
  FinalPositionScreen = 'position_final_screen',
  IntermediateStepsScreen = 'intermediate_steps_screen',
  IPRScreen = 'ipr_screen',
  AttachesScreen = 'attaches_screen',
  TreatmentDesignerScreen = 'treatment_designer_screen'
}

export enum AbilityAction {
  View = 'view',
  Edit = 'edit',
  Manage = 'manage' // This means all
}

export type SecretAlignerAbilitySubject = CommonAbilitySubject | SurgeryAbilitySubject | OrthoAbilitySubject;
