import { useCallback, useEffect, useMemo, useState } from 'react';
import { OrthodonticsOrderedPhasesKeys, orthodonticsAsideConfig } from './orthodonticsAsideConfig';
import { AsideConfigItem } from '../../../../surgeries/components/layout';
import { useOrthBoundStore } from '../../../stores/useStore';
import { useCommonBoundStore } from '../../../../common/stores/useStore';
import { useUtils } from '../../../../hooks';
import { usePhaseChangeValidation } from '../../../../hooks/orthodontics/phaseChangeValidation/usePhaseChangeValidation';

export function useOrthNavigationValidator() {
  const caseStatusFromStore = useOrthBoundStore((state) => state.caseStatus);
  const isCaseReseting = useCommonBoundStore((state) => state.isCaseReseting);
  const { getCurrentPhaseStageByRoute } = useUtils();
  const { getPhaseChangeValidation } = usePhaseChangeValidation();
  const { isValidPhase } = getPhaseChangeValidation();
  const [isValidPhaseResolved, setIsValidPhaseResolved] = useState(false);

  useEffect(() => {
    isValidPhase().then(setIsValidPhaseResolved);
  }, [isValidPhase]);

  const isNavigable = (phaseItem: AsideConfigItem) => {
    if (!phaseItem.route) {
      return false;
    }

    if (isNextPhase(phaseItem.key) && isValidPhaseResolved) {
      return true;
    }

    const completionDate = getPhaseCompletionDate(phaseItem.key);

    if (completionDate !== '' && !isCaseReseting && isPreviousPhase(phaseItem.key)) {
      return true;
    }

    if (completionDate !== '' && !isCaseReseting && !isPreviousPhase(phaseItem.key) && isValidPhaseResolved) {
      return true;
    }

    if (completionDate !== '' && isPreviousPhase(phaseItem.key) && isCaseReseting) {
      return true;
    }

    return false;
  };

  const getLastCompletedPhase = useCallback(() => {
    const completedPhases = caseStatusFromStore.phases.filter((phase) => getPhaseCompletionDate(phase.name) !== '');

    if (completedPhases.length === 0) {
      return undefined;
    }

    return completedPhases[completedPhases.length - 1];
  }, [caseStatusFromStore]);

  const isImmediatePhaseAfterCompletion = useCallback(
    (phaseKey: string) => {
      if (!caseStatusFromStore?.phases) {
        return;
      }

      const lastCompletedPhase = getLastCompletedPhase();
      if (lastCompletedPhase === undefined && phaseKey === OrthodonticsOrderedPhasesKeys.STLLoad) {
        return true;
      }
      const phaseKeys = Object.values(OrthodonticsOrderedPhasesKeys);

      const lastCompletedIndex = phaseKeys.indexOf(lastCompletedPhase?.name as OrthodonticsOrderedPhasesKeys);

      return phaseKeys[lastCompletedIndex + 1] === phaseKey;
    },
    [caseStatusFromStore, getLastCompletedPhase]
  );

  const isPreviousPhase = useCallback(
    (phaseKey: string) => {
      if (!caseStatusFromStore?.phases) {
        return;
      }

      const currentPhase = getCurrentPhaseStageByRoute();
      if (currentPhase === undefined) {
        return false;
      }

      const phaseKeys = Object.values(OrthodonticsOrderedPhasesKeys);

      const currentPhaseIndex = phaseKeys.indexOf(currentPhase as OrthodonticsOrderedPhasesKeys);

      const phaseIndex = phaseKeys.indexOf(phaseKey as OrthodonticsOrderedPhasesKeys);

      return phaseIndex < currentPhaseIndex;
    },
    [caseStatusFromStore, getCurrentPhaseStageByRoute]
  );

  const isNextPhase = useCallback(
    (phaseKey: string) => {
      if (!caseStatusFromStore?.phases) {
        return;
      }

      const currentPhase = getCurrentPhaseStageByRoute();
      if (currentPhase === undefined) {
        return false;
      }

      const phaseKeys = Object.values(OrthodonticsOrderedPhasesKeys);

      const lastCompletedIndex = phaseKeys.indexOf(currentPhase as OrthodonticsOrderedPhasesKeys);

      return phaseKeys[lastCompletedIndex + 1] == phaseKey;
    },
    [caseStatusFromStore]
  );

  const isNextPhaseAfterCurrentPhase = useCallback(
    (phaseKey: string) => {
      if (!caseStatusFromStore?.phases) {
        return;
      }

      const currentPhase = getCurrentPhaseStageByRoute();
      if (currentPhase === undefined) {
        return false;
      }

      const phaseKeys = Object.values(OrthodonticsOrderedPhasesKeys);

      const currentPhaseIndex = phaseKeys.indexOf(currentPhase as OrthodonticsOrderedPhasesKeys);
      const phaseIndex = phaseKeys.indexOf(phaseKey as OrthodonticsOrderedPhasesKeys);

      return phaseIndex > currentPhaseIndex;
    },
    [caseStatusFromStore]
  );

  const getPhaseCompletionDate = useCallback(
    (phaseKey: string) => {
      if (!caseStatusFromStore) {
        return;
      }
      const phase = caseStatusFromStore.phases.find((p) => p.name === phaseKey);
      return phase && phase.completionDate ? new Date(phase.completionDate).toLocaleDateString('es-es') : '';
    },
    [caseStatusFromStore]
  );

  const navigablePhases = useMemo(() => {
    const allItems = orthodonticsAsideConfig.flatMap((config) => config.items);
    return allItems.filter((item) => isNavigable(item)).map((item) => item.key);
  }, [orthodonticsAsideConfig, isNavigable]);

  const isNavigablePhase = useCallback(
    (phaseKey: string) => {
      return navigablePhases.find((p) => p === phaseKey) !== undefined;
    },
    [navigablePhases]
  );

  return {
    isNavigablePhase,
    getPhaseCompletionDate,
    isNextPhase
  };
}
