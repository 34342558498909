import { StateCreator } from 'zustand';
import { MesioDistalLength } from '../../../common';

export interface OrthAxisRootsSlice {
  isAutoAxisRootsConfirmed: boolean;
  setIsAutoAxisRootsConfirmed: (isAutoAxisRootsConfirmed: boolean) => void;
  mesioDistalLength: MesioDistalLength | null;
  setMesioDistalLength: (mesioDistalLength: MesioDistalLength) => void;
  upperZipFileRoute: string | null;
  setUpperZipFileRoute: (upperZipFileRoute: string) => void;
  lowerZipFileRoute: string | null;
  setLowerZipFileRoute: (lowerZipFileRoute: string) => void;
}

export const createOrthAxisRootsSlice: StateCreator<OrthAxisRootsSlice, [], [], OrthAxisRootsSlice> = (set) => ({
  isAutoAxisRootsConfirmed: false,
  setIsAutoAxisRootsConfirmed: (isAutoAxisRootsConfirmed: boolean) => set((_) => ({ isAutoAxisRootsConfirmed })),
  mesioDistalLength: null,
  setMesioDistalLength: (mesioDistalLength: MesioDistalLength) => set((_) => ({ mesioDistalLength })),
  upperZipFileRoute: null,
  setUpperZipFileRoute: (upperZipFileRoute: string) => set((_) => ({ upperZipFileRoute })),
  lowerZipFileRoute: null,
  setLowerZipFileRoute: (lowerZipFileRoute: string) => set((_) => ({ lowerZipFileRoute }))
});
