import { useCallback, useState } from 'react';
import { PointerPosition } from '../../../orthodontics/stores/slices/orthContextMenuSlice';
import { useTimelineStepsManager } from './useTimelineManager';

export function useIntermediateStepsContextMenu() {
  const [showIntermediateStepsContextMenu, setShowIntermediateStepsContextMenu] = useState<boolean>();
  const [contextMenuPosition, setContextMenuPosition] = useState<PointerPosition>({ clientX: 0, clientY: 0 });

  const { setActiveStep, setSelectedStepIndexes } = useTimelineStepsManager();

  const onToggleIntermediateStepsContextMenu = useCallback(() => {
    setShowIntermediateStepsContextMenu(false);
  }, [showIntermediateStepsContextMenu]);

  const onStepContextMenu = useCallback(
    (stepIndex: number, stepElement: HTMLElement) => {
      setSelectedStepIndexes([stepIndex]);
      setActiveStep(stepIndex);
      const rect = stepElement.getBoundingClientRect();
      const { top, left } = rect;
      setContextMenuPosition({ clientX: left, clientY: top });
      setShowIntermediateStepsContextMenu(true);
    },
    [setContextMenuPosition, setSelectedStepIndexes, setShowIntermediateStepsContextMenu, setActiveStep]
  );

  return {
    showIntermediateStepsContextMenu,
    contextMenuPosition,
    onToggleIntermediateStepsContextMenu,
    onStepContextMenu
  };
}
