import { useEvergineStore } from 'evergine-react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { INJECTED_TYPES, PagesWithTools, Stage, Timeline, container } from '../../../common';
import { useAttaches, useAttachmentPanels, useCaseId, useGateKeeper, useRenderModels } from '../../../hooks';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { useOrthBoundStore } from '../../stores/useStore';
import { useCommonBoundStore } from '../../../common/stores/useStore';
import { AbilityAction, AttachesInputDTO, ICalculationAttachesService, OrthoAbilitySubject } from '../../../shared';
import { CustomTooltip } from '../../../common/components/customTooltip';
import { useUtils } from '../../../hooks/shared/useUtils';
import { GeneralPanels } from '../../layout/general-panels';
import { OrthContextualMenu, PagesWithSpecificOptions } from '../contextMenus';
import { OpenAttachesPanel } from '../evergineToolbarElements/tools/evergineToolbar.helper';

export type TooltipAttach = {
  toothFdi: number;
  position: {
    x: number;
    y: number;
  };
  type: number;
  thickness: {
    currentThickness: number;
    canIncrement: boolean;
    canDecrement: boolean;
  };
};

export function Attaches() {
  const navigate = useNavigate();
  useGateKeeper(AbilityAction.View, OrthoAbilitySubject.AttachesScreen, () => navigate('/forbidden'));
  const { evergineReady } = useEvergineStore();
  const {
    lowerDentalMovements,
    upperDentalMovements,
    stageIsLoaded,
    hoveredAttach,
    areAttachesCalculating,
    setAreAttachesCalculating
  } = useOrthBoundStore((state) => ({
    lowerDentalMovements: state.lowerDentalMovements,
    upperDentalMovements: state.upperDentalMovements,
    stageIsLoaded: state.stageIsLoaded,
    hoveredAttach: state.hoveredAttach,
    areAttachesCalculating: state.areAttachesCalculating,
    setAreAttachesCalculating: state.setAreAttachesCalculating
  }));
  const showAttachesPanel = useOrthBoundStore((state) => state.showAttachesPanel);
  const { setMessageInfo, setWebBusy } = useBoundStore((state) => ({
    setMessageInfo: state.setMessageInfo,
    setWebBusy: state.setWebBusy
  }));
  const { activeStep, setActiveStep } = useCommonBoundStore((state) => ({
    activeStep: state.activeStep,
    setActiveStep: state.setActiveStep
  }));

  const [caseId] = useCaseId();
  useRenderModels(caseId, Stage.Attaches);
  const { updateMovements, stepSorter, fixFloat } = useUtils();
  const { typeTooltip, positionTooltip } = useAttaches();
  useAttachmentPanels();

  const calculateAttachesService = container.get<ICalculationAttachesService>(
    INJECTED_TYPES.ICalculationAttachesService
  );

  const [canShowTimeline, setCanShowTimeline] = useState<boolean>(false);
  const [isAIEnabled, setIsAIEnabled] = useState<boolean>(true);

  useEffect(() => {
    if (evergineReady) {
      window.App.webEventsProxy.common.setEditionMode(true);
      setWebBusy(false);
      OpenAttachesPanel();
    }
  }, [evergineReady]);

  useEffect(() => {
    if (areAttachesCalculating && isAIEnabled) {
      setIsAIEnabled(false);
      setWebBusy(true);

      const sendDataToAI = async () => {
        try {
          const resultInputAttach: AttachesInputDTO = await window.App.webEventsProxy.attachments.getInputAttachments();
          const responseIA = await calculateAttachesService.getAIAutomaticAttaches(resultInputAttach);
          await window.App.webEventsProxy.attachments.updateAttachmentsFromAI(responseIA);
          updateMovements();
        } catch (error) {
          console.error(error);
        } finally {
          setIsAIEnabled(true);
          setWebBusy(false);
        }
      };

      sendDataToAI();
    }
    setAreAttachesCalculating(false);
  }, [areAttachesCalculating]);

  useEffect(() => {
    const result =
      (lowerDentalMovements?.steps?.length > 0 || upperDentalMovements?.steps?.length > 0) &&
      stageIsLoaded &&
      evergineReady;
    setCanShowTimeline(result);
  }, [lowerDentalMovements, upperDentalMovements, evergineReady, stageIsLoaded]);

  useEffect(() => {
    setMessageInfo(showAttachesPanel ? 'pageInfoMessages.attaches.intructions' : null);
  }, [showAttachesPanel]);

  const getThicknessText = useCallback(() => {
    return hoveredAttach?.thickness.currentThickness !== undefined
      ? `${fixFloat(hoveredAttach?.thickness.currentThickness)}`
      : '--';
  }, [hoveredAttach]);

  return (
    <>
      <GeneralPanels pageWithTools={PagesWithTools.Attaches}>
        <>
          {hoveredAttach && (
            <CustomTooltip text={`${typeTooltip} ${getThicknessText()} mm`} position={positionTooltip} />
          )}{' '}
          <OrthContextualMenu pageWithSpecificOptions={PagesWithSpecificOptions.Attaches} />
        </>
      </GeneralPanels>
      {canShowTimeline && (
        <Timeline
          upperTeeth={upperDentalMovements?.teeth}
          lowerTeeth={lowerDentalMovements?.teeth}
          upperSteps={upperDentalMovements?.steps?.sort(stepSorter)}
          lowerSteps={lowerDentalMovements?.steps?.sort(stepSorter)}
          upperAttachments={upperDentalMovements?.attachments}
          lowerAttachments={lowerDentalMovements?.attachments}
          comparerActivated={false}
          vertical={false}
          enableFollowUpOverposition={false}
          storeCurrentStep={activeStep}
          updateStoreCurrentStep={(newValue: number) => setActiveStep(newValue)}
          isDesigner={true}
        />
      )}
    </>
  );
}
