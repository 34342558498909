import Draggable from 'react-draggable';

interface ToolPanelDraggableContainerProps {
  handlerElementClass: string;
  panelSize?: number;
  toolPosition?: DOMRect;
  children: React.ReactNode;
}

export function ToolPanelDraggableContainer(props: ToolPanelDraggableContainerProps) {
  const { toolPosition, panelSize, handlerElementClass, children } = props;

  const transformStyle: React.CSSProperties = {
    transform: `scale(${1 / window.devicePixelRatio})`
  };

  if (toolPosition && panelSize) {
    transformStyle.transform = `translate(${toolPosition.x - panelSize * (1 / window.devicePixelRatio)}px, ${
      toolPosition.top * (1 / window.devicePixelRatio)
    }px) scale(${1 / window.devicePixelRatio})`;
    transformStyle.transformOrigin = 'top left';
  }

  return (
    <div style={transformStyle}>
      <Draggable handle={`.${handlerElementClass}`}>{children}</Draggable>
    </div>
  );
}
