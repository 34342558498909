import { CapturePosition } from '../../common';

export type SegmentationFileDTO = {
  file: string;
  teethArch: CapturePosition;
};

export type SegmentationResponseDTO = {
  faces: number[];
  labels: number[];
};

export const SEGMENTATION_FILE_KEY = 'designer-segmentation';
