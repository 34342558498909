import './evolutionTableHeader.scss';

interface EvolutionTableHeaderProps {
  isBodyScrollVisible: boolean;
  highlightedButtons: JSX.Element;
}

export function EvolutionTableHeader(props: EvolutionTableHeaderProps) {
  const { isBodyScrollVisible, highlightedButtons } = props;
  return <div className={`evolutiontableheader ${isBodyScrollVisible ? 'is-scroll' : ''}`}>{highlightedButtons}</div>;
}
