import './labelvalue.scss';

type LavelValueProps = {
  label?: string;
  value?: string;
  valueList?: string[];
};

export function LabelValue(props: LavelValueProps) {
  const { label, value, valueList } = props;

  return (
    <div className="labelvalue">
      <div className="labelvalue-label">{label}</div>
      <div className="labelvalue-value">{value}</div>
      {valueList?.map((v, index) => (
        <div key={`problem_${index}`} className="labelvalue-value">
          {v}
        </div>
      ))}
    </div>
  );
}
