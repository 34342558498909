export type PieceSelectedIndicatorData = {
  pieceId: string;
  cx: string;
  cy: string;
};

export const PieceSelectedIndicatorConsts = {
  rx: '4.29',
  ry: '4.76',
  fill: '#1976d2'
};
