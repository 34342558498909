import { useTranslation } from 'react-i18next';

interface NoFilesProps {
  title: string;
}

export function NoFiles({ title }: NoFilesProps) {
  const [t] = useTranslation();
  return (
    <>
      <p>{title}</p>
      <p className="no-data">{t('imageManagement.common.noFiles')}</p>
    </>
  );
}
