import { useEvergineStore } from 'evergine-react';
import { useCallback, useEffect, useState } from 'react';
import { useFocusCanvas } from '../shared/useFocusCanvas';

const upperLeftQuadrantFDIs = ['18', '17', '16', '15', '14', '13', '12', '11'];
const upperRightQuadrantFDIs = ['28', '27', '26', '25', '24', '23', '22', '21'];
const lowerLeftQuadrantFDIs = ['48', '47', '46', '45', '44', '43', '42', '41'];
const lowerRightQuadrantFDIs = ['38', '37', '36', '35', '34', '33', '32', '31'];

const BRUSH_SIZE_MIN_VALUE = 0.5;
const BRUSH_SIZE_MAX_VALUE = 20;
const BRUSH_SIZE_DEFAULT_VALUE = 1;

export function useTeethSegmentation() {
  const [selectedPaintedToothFdi, setSelectedPaintedToothFdi] = useState<string>();
  const [brushSize, setBrushSize] = useState(BRUSH_SIZE_DEFAULT_VALUE);
  const { evergineReady } = useEvergineStore();
  const { setFocusOnCanvas } = useFocusCanvas();

  useEffect(() => {
    if (!evergineReady) {
      return;
    }
    const handleMouseDown = (event: MouseEvent) => {
      if ((event as MouseEvent).altKey) {
        setSelectedPaintedToothFdi(getPaintedToothFdi());
      }
    };

    document.addEventListener('mousedown', handleMouseDown);

    window.App.webEventsProxy.segmentation.setBrushSize(BRUSH_SIZE_DEFAULT_VALUE);

    return () => document.removeEventListener('mousedown', handleMouseDown);
  }, [evergineReady]);

  const isValidFdi = useCallback(
    (fdi: string) =>
      upperLeftQuadrantFDIs.includes(fdi) ||
      upperRightQuadrantFDIs.includes(fdi) ||
      lowerLeftQuadrantFDIs.includes(fdi) ||
      lowerRightQuadrantFDIs.includes(fdi),
    []
  );

  const getPaintedToothFdi = useCallback((): string | undefined => {
    const fdi = window.App.webEventsProxy.segmentation.queryToothFDI().toString();
    return isValidFdi(fdi) ? fdi : undefined;
  }, [isValidFdi]);

  const getQuadrantOfPiece = useCallback((pieceFdi: string) => {
    if (upperLeftQuadrantFDIs.includes(pieceFdi)) {
      return upperLeftQuadrantFDIs;
    }

    if (upperRightQuadrantFDIs.includes(pieceFdi)) {
      return upperRightQuadrantFDIs;
    }

    if (lowerLeftQuadrantFDIs.includes(pieceFdi)) {
      return lowerLeftQuadrantFDIs;
    }

    if (lowerRightQuadrantFDIs.includes(pieceFdi)) {
      return lowerRightQuadrantFDIs;
    }
  }, []);

  const setToothToBePainted = useCallback((toothFdi: number) => {
    window.App.webEventsProxy.segmentation.setPainterFdi(toothFdi);
  }, []);

  const updateBrushSize = useCallback(
    (value: number) => {
      if (value < BRUSH_SIZE_MIN_VALUE || value > BRUSH_SIZE_MAX_VALUE) {
        return;
      }
      window.App.webEventsProxy.segmentation.setBrushSize(value);
      setBrushSize(value);
      setFocusOnCanvas();
    },
    [setBrushSize]
  );

  return {
    BRUSH_SIZE_MIN_VALUE,
    BRUSH_SIZE_MAX_VALUE,
    selectedPaintedToothFdi,
    brushSize,
    setBrushSize,
    updateBrushSize,
    getQuadrantOfPiece,
    setToothToBePainted
  };
}
