import classNames from 'classnames';

import { ReactComponent as RadioOffIcon } from '../../../assets/icons/radio-off.svg';
import { ReactComponent as RadioOnIcon } from '../../../assets/icons/radio-on.svg';

import './radio.scss';

export type RadioProps = {
  label?: string;
  active?: boolean;
  readOnly?: boolean;
  expanded?: boolean;
  labelOnRight?: boolean;
  callBack?: () => void;
};

export default function Radio({ label, active, readOnly, expanded = true, labelOnRight = true, callBack }: RadioProps) {
  const classes = classNames('radio-button', {
    expanded
  });

  return (
    <label className={classes}>
      {!labelOnRight && label && <span className="radio-button-label">{label}</span>}
      <span className="radio-button-icons">
        <input type="radio" checked={active} onChange={callBack} readOnly={readOnly} />
        <span className="radio-button-icon radio-button-on">
          <RadioOnIcon />
        </span>
        <span className="radio-button-icon radio-button-off">
          <RadioOffIcon />
        </span>
      </span>
      {labelOnRight && label && <span className="radio-button-label">{label}</span>}
    </label>
  );
}
