import { useCallback, useEffect, useMemo, useState } from 'react';
import { INJECTED_TYPES, OutputIntermediateSteps, Step, container } from '../../../common';
import { useCommonBoundStore } from '../../../common/stores/useStore';
import { useOrthBoundStore } from '../../../orthodontics/stores/useStore';
import { IAIMovementsService, ICommandInvoker } from '../../../shared';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { useStepsManager } from '../useStepsManager';
import { useEvergineStore } from 'evergine-react';
import MarkStepAsModifiedWithNoAutoRecalcCommand from '../../../orthodontics/commands/markStepAsModifiedWithNoAutoRecalcCommand';
import { useTimelineStepsManager } from './useTimelineManager';
import { useToothTransformStore } from '../../../orthodontics/stores/useToothTransformStore';
import { useIprCalculation } from '../../shared/useIprCalculation';

const getModifiedWithNoAutoRecalcStepKeyIndexesFromSteps = (steps: Step[]) =>
  steps.filter((s) => s.isModifiedStepWithNoAutoRecalc === true).map((ks) => ks.stepIndex);

export function useTreatmentStepsManager(disableEffects = false) {
  const {
    isAIInterpolationAsked,
    isRecalculateCancelled,
    stageIsLoaded,
    setIsAIInterpolationAsked,
    setCanAskAIInterpolation,
    setIsRecalculateCancelled,
    setAreMadeChangesOnTeeth,
    setUpperAndLowerDentalMovements
  } = useOrthBoundStore((state) => ({
    isAIInterpolationAsked: state.isAIInterpolationAsked,
    isRecalculateCancelled: state.isRecalculateCancelled,
    stageIsLoaded: state.stageIsLoaded,
    setUpperDentalMovements: state.setUpperDentalMovements,
    setLowerDentalMovements: state.setLowerDentalMovements,
    setIsAIInterpolationAsked: state.setIsAIInterpolationAsked,
    setCanAskAIInterpolation: state.setCanAskAIInterpolation,
    setIsRecalculateCancelled: state.setIsRecalculateCancelled,
    setAreMadeChangesOnTeeth: state.setAreMadeChangesOnTeeth,
    setUpperAndLowerDentalMovements: state.setUpperAndLowerDentalMovements
  }));
  const selectedTeethTransformData = useToothTransformStore((state) => state.selectedTeethTransformData);
  const { evergineReady } = useEvergineStore();
  const { setWebBusy } = useBoundStore((state) => ({ setWebBusy: state.setWebBusy }));
  const { setIsPreventedNavigationCancelled } = useCommonBoundStore((state) => ({
    setIsPreventedNavigationCancelled: state.setIsPreventedNavigationCancelled
  }));
  const { goToStep } = useStepsManager();
  const [isAIEnabled, setIsAIEnabled] = useState<boolean>(true);
  const [stepToNavigate, setStepToNavigate] = useState<number>();
  const movementsAIService = container.get<IAIMovementsService>(INJECTED_TYPES.IAIMovementsService);
  const { calculateIpr } = useIprCalculation();

  const {
    upperSteps,
    lowerSteps,
    activeStep,
    selectedStepIndexes,
    setSelectedStepIndexes,
    setActiveStep,
    setLastStepIndex
  } = useTimelineStepsManager();

  const stepsModifiedWithNoAutoRecalcIndexes = useMemo(() => {
    const upperModifiedStepsWithNoAutoRecalcIndexes =
      upperSteps?.length > 2 ? getModifiedWithNoAutoRecalcStepKeyIndexesFromSteps(upperSteps) : [];
    const lowerModifiedStepsWithNoAutoRecalcIndexes =
      lowerSteps?.length > 2 ? getModifiedWithNoAutoRecalcStepKeyIndexesFromSteps(lowerSteps) : [];

    return Array.from(
      new Set([...upperModifiedStepsWithNoAutoRecalcIndexes, ...lowerModifiedStepsWithNoAutoRecalcIndexes])
    );
  }, [upperSteps, lowerSteps]);

  useEffect(() => {
    if (!evergineReady || !stageIsLoaded || disableEffects) {
      return;
    }
    updateMovements();
  }, [selectedTeethTransformData]);

  useEffect(() => {
    if (!isAIInterpolationAsked || !isAIEnabled || selectedStepIndexes.length !== 2 || disableEffects) {
      return;
    }
    const askForInterpolation = async () => {
      setIsAIEnabled(false);
      setWebBusy(true);
      const orderedSelectedStepIndexes = selectedStepIndexes.sort((a, b) => a - b);
      await loadAIInterpolation(orderedSelectedStepIndexes[0], orderedSelectedStepIndexes[1]);
      setIsAIInterpolationAsked(false);
      setAreMadeChangesOnTeeth(false);
      setCanAskAIInterpolation(false);
      setWebBusy(false);
      setIsAIEnabled(true);
    };

    askForInterpolation();
  }, [isAIInterpolationAsked]);

  useEffect(() => {
    if (disableEffects) return;
    if (isRecalculateCancelled) {
      setStepAsModifiedWithNoAIInterpolation();
      setIsRecalculateCancelled(false);
      setAreMadeChangesOnTeeth(false);
      setIsPreventedNavigationCancelled(true);
    }
  }, [isRecalculateCancelled]);

  const setStepAsActiveAndSelected = useCallback(
    (newActiveStep: number) => {
      setActiveStep(newActiveStep);
      setSelectedStepIndexes([newActiveStep]);
    },
    [setActiveStep, setSelectedStepIndexes]
  );

  const setStepAsModifiedWithNoAIInterpolation = useCallback(async () => {
    const commandInvokerService = container.get<ICommandInvoker>(INJECTED_TYPES.ICommandInvokerService);
    const command = new MarkStepAsModifiedWithNoAutoRecalcCommand(
      activeStep,
      stepToNavigate,
      setStepAsActiveAndSelected
    );
    await commandInvokerService.execute(command);
    updateMovements();
  }, [activeStep, stepToNavigate]);

  const getMovementsFromEvergine = () => window.App.webEventsProxy.movements.getTreatmentMovements();

  const updateMovements = useCallback(() => {
    const { upperMovements, lowerMovements } = getMovementsFromEvergine();
    setLastStepIndex(Math.max(upperMovements?.steps?.length, lowerMovements?.steps?.length) - 1);
    setUpperAndLowerDentalMovements(upperMovements, lowerMovements);
  }, [setUpperAndLowerDentalMovements, getMovementsFromEvergine, setLastStepIndex]);

  const getStepsPrediction = useCallback(async (stepFrom: number, stepTo: number): Promise<OutputIntermediateSteps> => {
    const inputIntermediateSteps = await window.App.webEventsProxy.movements.getInputForIntermediateSteps(
      stepFrom,
      stepTo
    );
    const inputStringified = JSON.stringify(inputIntermediateSteps);
    const result = await movementsAIService.getAIMovementsPrediction({
      movementsDelta: inputStringified
    });

    return result as OutputIntermediateSteps;
  }, []);

  const loadAIInterpolation = useCallback(
    async (fromStepIndex: number, toStepIndex: number): Promise<boolean> => {
      const outputIntermediateSteps = await getStepsPrediction(fromStepIndex, toStepIndex);

      if (outputIntermediateSteps && outputIntermediateSteps.steps?.length > 0) {
        await window.App.webEventsProxy.movements.updateIntermediateStepsFromAI(
          outputIntermediateSteps,
          fromStepIndex,
          toStepIndex
        );
        await calculateIpr();
        updateMovements();
        goToStep(fromStepIndex, false);
        setSelectedStepIndexes([fromStepIndex, fromStepIndex + outputIntermediateSteps.steps.length + 1]);
        return Promise.resolve(true);
      }

      return Promise.resolve(false);
    },
    [updateMovements]
  );

  return {
    isAIEnabled,
    setIsAIEnabled,
    selectedStepIndexes,
    stepsModifiedWithNoAutoRecalcIndexes,
    setStepToNavigate,
    updateMovements,
    loadAIInterpolation
  };
}
