import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg';
import { useOrthBoundStore } from '../../../../stores/useStore';

import { AttacheType } from './attacheType/AttacheType';

import { useCommonBoundStore } from '../../../../../common/stores/useStore';
import { useAttaches } from '../../../../../hooks';
import { ModalTypes } from '../../../../../surgeries/components/layout';
import { attacheTypes } from './attacheTypes';
import './attachesTools.scss';
import { ToolPanelDraggableContainer } from '../ToolPanelDraggableContainer';

export function AttachesTools() {
  const { setShowAttachesPanel, setAttachActive, setAreAttachesCalculating, selectedAttache, setShowTadsPanel } =
    useOrthBoundStore((state) => ({
      setShowAttachesPanel: state.setShowAttachesPanel,
      setAttachActive: state.setAttachActive,
      setAreAttachesCalculating: state.setAreAttachesCalculating,
      selectedAttache: state.selectedAttache,
      setShowTadsPanel: state.setShowTadsPanel
    }));

  const selectedAttacheRef = useRef(selectedAttache);
  const [t] = useTranslation();
  const { noTeethSelected, inFirstOrLastStep, noAttacheSelected } = useAttaches();

  const PANEL_SIZE = 312;

  const toolPosition = useMemo(() => {
    return document.getElementById('orthAttachesButton')?.getBoundingClientRect();
  }, []);

  const { toggleModalIsOpened } = useCommonBoundStore();

  const handleClose = useCallback(() => {
    setShowAttachesPanel(false);
    setAttachActive(null);
  }, [setShowAttachesPanel, setAttachActive]);

  const showDeleteModal = useCallback(() => {
    toggleModalIsOpened(ModalTypes.ModalDeleteAttach);
  }, [toggleModalIsOpened]);

  const invokeAttachmentsAICalc = useCallback(() => {
    setAreAttachesCalculating(true);
  }, [setAreAttachesCalculating]);

  const mustDisableRemove = useCallback(() => {
    return (noTeethSelected && noAttacheSelected) || inFirstOrLastStep;
  }, [noTeethSelected, noAttacheSelected, inFirstOrLastStep, selectedAttacheRef.current]);

  useEffect(() => {
    selectedAttacheRef.current = selectedAttache;
  }, [selectedAttache]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Delete' && !!selectedAttacheRef.current) {
        showDeleteModal();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    setShowTadsPanel(false);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  return (
    <ToolPanelDraggableContainer
      handlerElementClass="handleOrthAttaches"
      panelSize={PANEL_SIZE}
      toolPosition={toolPosition}
    >
      <div className="attachestools no-select">
        <div className="attachestools-header">
          <div className="attachestools-title handleOrthAttaches">
            <div>{t('evergineTools.attaches')}</div>
          </div>

          <button type="button" className="attachestools-close" onClick={handleClose}>
            <CloseIcon className="clickable" />
          </button>
        </div>
        <div className="attachestools-content">
          {attacheTypes.map((attacheType) => (
            <AttacheType attacheType={attacheType} key={`attachetype-${attacheType.name}`} />
          ))}
          <button
            onClick={showDeleteModal}
            type="button"
            className="btn btn-outline-primary flex-fill attachestools-content__delete"
            // disabled={noTeethSelected || inFirstOrLastStep}
            disabled={mustDisableRemove()}
          >
            {t('common.delete')}
          </button>
          <button
            onClick={invokeAttachmentsAICalc}
            type="button"
            className="btn btn-outline-primary flex-fill attachestools-content__delete"
            // disabled={noTeethSelected || inFirstOrLastStep}
          >
            {t('evergineTools.autoAttachesCalc')}
          </button>
        </div>
      </div>
    </ToolPanelDraggableContainer>
  );
}
