import { useState } from 'react';
import { useCommonBoundStore } from '../../../../stores/useStore';
import { CaseIdentifierType } from '../../../../stores/slices';

export function useConfigCaseIdentifier() {
  const { caseIdentifierType, setCaseIdentifierType } = useCommonBoundStore((state) => ({
    caseIdentifierType: state.caseIdentifierType,
    setCaseIdentifierType: state.setCaseIdentifierType
  }));

  const [localCurrentCaseIdentifier, setLocalCurrentCaseIdentifier] = useState<CaseIdentifierType>(caseIdentifierType);

  const changeLocalCaseIdentifier = (caseIdentifier: CaseIdentifierType) => {
    setLocalCurrentCaseIdentifier(caseIdentifier);
  };

  const saveCaseIdentifierConfiguration = () => {
    setCaseIdentifierType(localCurrentCaseIdentifier);
  };

  return {
    localCurrentCaseIdentifier,
    changeLocalCaseIdentifier,
    saveCaseIdentifierConfiguration
  };
}
