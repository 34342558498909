import { useNavigate } from 'react-router';
import { useGateKeeper } from '../../../hooks';
import { AbilityAction, CommonAbilitySubject } from '../../../shared';
import { SpecificInfoDetailCard } from './SpecificInfoDetailsCard';
import { SpecificInfoTreatmentCard } from './SpecificInfoTreatmentCard';
import { SpecificInfoIndicationsCard } from './SpecificInfoIndicationsCard';
import { useBoundStore } from '../../../surgeries/stores/useStore';

export function OrthoSpecificInfo() {
  const navigate = useNavigate();
  useGateKeeper(AbilityAction.View, CommonAbilitySubject.SpecificInfoScreen, () => navigate('/forbidden'));

  const patientCase = useBoundStore((state) => state.patientCase);

  return (
    <div className="generalinfo container-md">
      {patientCase && (
        <div className="row height-85">
          <div className="generalinfo-data col-lg-4 col-md-12">
            <SpecificInfoDetailCard patientCase={patientCase} />
          </div>
          <div className="generalinfo-data col-lg-4 col-md-12">
            <SpecificInfoTreatmentCard patientCase={patientCase} />
          </div>
          <div className="generalinfo-data col-lg-4 col-md-12">
            <SpecificInfoIndicationsCard patientCase={patientCase} />
          </div>
        </div>
      )}
    </div>
  );
}
