import { Backdrop, Box } from '@mui/material';
import { EvergineCanvas } from 'evergine-react';
import { useCallback, useEffect, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import './AppRouter.scss';
import { ForbiddenScreen, InfoMessage, NavBar, NotFoundScreen, Spinner } from './common/components';
import { EVERGINE_CANVAS_ID } from './common/evergine/config';
import { useCaseData, useCaseId, useCaseType } from './hooks';
import { useCalculateRoutes } from './hooks/shared/useCalculateRoutes';
import { PathLevels } from './models';
import { OrthodonticsPagesUrl, OrthodonticsRoutes } from './orthodontics/OrthodonticsRoutes';
import { useOrthBoundStore } from './orthodontics/stores/useStore';
import { SurgeryPagesUrl, SurgeryRoutes } from './surgeries/SurgeryRoutes';
import { Home } from './surgeries/components';
import {
  Modal,
  ModalAutoClosed,
  ModalClientValidationsCancelChanges,
  ModalClientValidationsEdit,
  ModalClientValidationsReject,
  ModalClientValidationsSendChanges,
  ModalClientValidationsValidate,
  ModalConfirmDeleteVersion,
  ModalDeleteAttach,
  ModalDownloadCase,
  ModalError,
  ModalImplant,
  ModalModificationsMade,
  ModalNextStep,
  ModalNextStepDirect,
  ModalNoMissingTeethInformation,
  ModalOnlyOneFileInformation,
  ModalPreviousCasePhaseModified,
  ModalPreviousStep,
  ModalPublishCase,
  ModalPendingIprCalculation,
  ModalTypes
} from './surgeries/components/layout';
import { useBoundStore } from './surgeries/stores/useStore';
import { useCanvasSize } from './hooks/shared/useCanvasSize';

export enum BaseRoutes {
  Surgery = 'surgery',
  Orthodontics = 'orthodontics'
}

export enum CommonPagesUrl {
  GeneralInfo = '/general-information',
  SpecificInfo = '/specific-information',
  DicomCaptures = '/dicom-captures',
  StlCaptures = '/stl-captures',
  PhotoCaptures = '/photo-captures',
  Forbidden = '/forbidden',
  NotFound = '/not-found'
}

export const UrlsByCaseState: Record<string, string> = {
  stl_load: CommonPagesUrl.StlCaptures,
  teeth_segmentation: OrthodonticsPagesUrl.TeethSegmentation,
  axis_and_roots: OrthodonticsPagesUrl.AxisAndRoots,
  treatment: OrthodonticsPagesUrl.Treatment,
  attaches: OrthodonticsPagesUrl.Attaches,
  publish: OrthodonticsPagesUrl.DentalMovements
};

export function AppRouter() {
  const { pathname } = useLocation();
  const [caseId] = useCaseId();
  const caseType = useCaseType();
  const [calculateNextRoute, calculatePreviousRoute] = useCalculateRoutes(pathname, caseId, caseType);
  const { evergineBusy, showCanvas, webBusy, messageInfo, versions } = useBoundStore((state) => ({
    evergineBusy: state.evergineBusy,
    showCanvas: state.showCanvas,
    webBusy: state.webBusy,
    messageInfo: state.messageInfo,
    versions: state.versions
  }));
  const { setIsOrthContextMenuVisible, setPointerPosition } = useOrthBoundStore((state) => ({
    setIsOrthContextMenuVisible: state.setIsOrthContextMenuVisible,
    setPointerPosition: state.setPointerPosition
  }));
  const [t] = useTranslation();
  const { fetchPatientCaseVersions } = useCaseData(caseId);

  const currentRoute = useMemo(() => `/${pathname.split('/')[PathLevels.RouteView]}`, [pathname]);

  const allowedPagesToShowCanvas: string[] = [
    CommonPagesUrl.DicomCaptures,
    CommonPagesUrl.StlCaptures,
    SurgeryPagesUrl.Matching,
    SurgeryPagesUrl.ResultMatching,
    SurgeryPagesUrl.Implants,
    OrthodonticsPagesUrl.MeshCorrection,
    OrthodonticsPagesUrl.DentalMovements,
    OrthodonticsPagesUrl.TeethSegmentation,
    OrthodonticsPagesUrl.AxisAndRoots,
    OrthodonticsPagesUrl.Treatment,
    OrthodonticsPagesUrl.IPR,
    OrthodonticsPagesUrl.Attaches,
    OrthodonticsPagesUrl.Publish
  ];

  const canvasSize = useCanvasSize(pathname, currentRoute);

  const pagesWithoutNavbar: string[] = [CommonPagesUrl.Forbidden, CommonPagesUrl.NotFound];

  const pagesInfoMessage = [
    OrthodonticsPagesUrl.TeethSegmentation,
    OrthodonticsPagesUrl.AxisAndRoots,
    OrthodonticsPagesUrl.Treatment,
    OrthodonticsPagesUrl.Attaches
  ];

  useEffect(() => {
    if (!versions) {
      fetchPatientCaseVersions();
    }
  }, [versions]);

  const handleContextMenu = useCallback((e: any) => {
    const position = { clientX: e.clientX, clientY: e.clientY };
    setPointerPosition(position);
    setIsOrthContextMenuVisible(true); // TODO: reactivate this when the ortho context menu is implemented
  }, []);

  const showInfoMessageIfNeeded = useCallback(() => {
    const isPageWithInfoMessage = pagesInfoMessage.map(String).includes(currentRoute);
    return isPageWithInfoMessage && <InfoMessage message={t(messageInfo)} />;
  }, [currentRoute, pagesInfoMessage, messageInfo]);

  const showNavbarIfAllowed = useCallback(() => {
    const isPageWithoutNavbar = pagesWithoutNavbar.map(String).includes(pathname);
    return !isPageWithoutNavbar && <NavBar />;
  }, [pagesWithoutNavbar, pathname]);

  return (
    <>
      <ErrorBoundary
        FallbackComponent={(): JSX.Element => <div className="error-boundary">{t('errors.boundaryError')}</div>}
      >
        {showNavbarIfAllowed()}
      </ErrorBoundary>
      <ErrorBoundary
        FallbackComponent={(): JSX.Element => <div className="error-boundary">{t('errors.boundaryError')}</div>}
      >
        <Modal>
          <ModalNextStep key={ModalTypes.ModalNextStep} nextRoute={calculateNextRoute()} />
          <ModalNextStepDirect key={ModalTypes.ModalNextStepDirect} nextRoute={calculateNextRoute()} />
          <ModalPreviousStep key={ModalTypes.ModalPreviousStep} previousRoute={calculatePreviousRoute()} />
          <ModalError key={ModalTypes.ModalError} />
          <ModalImplant key={ModalTypes.ModalImplant} />
          <ModalClientValidationsValidate key={ModalTypes.ModalClientValidationsValidate} />
          <ModalClientValidationsEdit key={ModalTypes.ModalClientValidationsEdit} />
          <ModalClientValidationsReject key={ModalTypes.ModalClientValidationsReject} />
          <ModalConfirmDeleteVersion key={ModalTypes.ModalConfirmDeleteVersion} />
          <ModalClientValidationsSendChanges key={ModalTypes.ModalClientValidationsSendChanges} />
          <ModalAutoClosed key={ModalTypes.ModalAutoClosed} />
          <ModalClientValidationsCancelChanges key={ModalTypes.ModalClientValidationsCancelChanges} />
          <ModalOnlyOneFileInformation key={ModalTypes.ModalOnlyOneFileInformation} nextRoute={calculateNextRoute()} />
          <ModalPreviousCasePhaseModified key={ModalTypes.ModalPreviousCasePhaseModified} />
          <ModalNoMissingTeethInformation key={ModalTypes.ModalNoMissingTeethInformation} />
          <ModalPublishCase key={ModalTypes.ModalPublishCase} />
          <ModalDownloadCase key={ModalTypes.ModalDownloadCase} />
          <ModalDeleteAttach key={ModalTypes.ModalDeleteAttach} />
          <ModalModificationsMade key={ModalTypes.ModalModificationsMade} />
          <ModalPendingIprCalculation key={ModalTypes.ModalPendingIprCalculation} nextRoute={calculateNextRoute()} />
        </Modal>
        <Routes>
          <Route path="/forbidden" element={<ForbiddenScreen />} />
          <Route path="/not-found" element={<NotFoundScreen />} />
          <Route path="/" element={<Home />} />
          {SurgeryRoutes()}
          {OrthodonticsRoutes()}

          <Route path="*" element={<Navigate replace to="/not-found" />} />
        </Routes>
      </ErrorBoundary>

      <div className="section-content d-flex align-items-center justify-content-center">
        <Backdrop style={{ zIndex: 1000 }} open={evergineBusy || webBusy}>
          <Box sx={{ display: 'block', textAlign: 'center' }}>
            <Spinner />
          </Box>
        </Backdrop>

        <div
          className="app"
          style={{
            display:
              showCanvas && allowedPagesToShowCanvas.includes(`/${pathname.split('/')[PathLevels.RouteView]}`)
                ? 'inline'
                : 'none'
          }}
        >
          <ErrorBoundary
            FallbackComponent={(): JSX.Element => <div className="error-boundary">{t('errors.boundaryError')}</div>}
          >
            <div style={{ marginLeft: `${canvasSize.offsetX}px` }}>
              <EvergineCanvas
                canvasId={EVERGINE_CANVAS_ID}
                width={canvasSize.canvasWidth}
                height={canvasSize.canvasHeight}
                onContextMenu={(e: any) => handleContextMenu(e)}
              />
            </div>
          </ErrorBoundary>
        </div>
      </div>
      {showInfoMessageIfNeeded()}
    </>
  );
}
