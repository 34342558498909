import { StoreApi, UseBoundStore } from 'zustand';
import { CommonBoundState } from '../stores/useStore';
import { OrthBoundState } from '../../orthodontics/stores/useStore';
import { SurgeriesBoundState } from '../../surgeries/stores/useStore';
import { ToothTransformState } from '../../orthodontics/stores/useToothTransformStore';

let updateTimeout: NodeJS.Timeout | null = null;

export const debouncedSetState = (
  store: UseBoundStore<StoreApi<CommonBoundState | OrthBoundState | SurgeriesBoundState | ToothTransformState>>,
  newState: Partial<CommonBoundState | OrthBoundState | SurgeriesBoundState | ToothTransformState>
) => {
  if (updateTimeout) {
    clearTimeout(updateTimeout);
  }

  updateTimeout = setTimeout(() => {
    store.setState({ ...store.getState(), ...newState });
    updateTimeout = null;
  }, 0);
};

export const debounceAndUpdateStore = (
  store: UseBoundStore<StoreApi<CommonBoundState | OrthBoundState | SurgeriesBoundState>>,
  stateKey: string,
  newStateValue: any
) => {
  if (updateTimeout) {
    clearTimeout(updateTimeout);
  }

  updateTimeout = setTimeout(() => {
    store.setState({ [stateKey]: newStateValue });
    updateTimeout = null;
  }, 0);
};
