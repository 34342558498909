import { CapturePosition } from '../../common';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { CaseFile } from '../../shared';
import { useGetFiles } from './useGetFiles';

export function useGetRoots(caseId: string) {
  const { getFileInfo } = useGetFiles(caseId);
  const currentVersion = useOrthBoundStore((state) => state.currentVersion);

  const loadRoots = async (teethArch: CapturePosition) => {
    if (currentVersion === null) {
      return;
    }
    // Load processedRoots
    let rs = await loadProcessedRoots(teethArch);
    // If not processedRoots -> load NotProcessedRoots
    if (!rs) rs = await loadNonProcessedRoots(teethArch);
    // If not, use non real roots
    if (!rs) rs = null;

    return rs;
  };

  const loadProcessedRoots = async (teethArch: CapturePosition): Promise<CaseFile | null> => {
    const fileKey = teethArch == CapturePosition.UPPER ? 'processed-real-roots-upper' : 'processed-real-roots-lower';
    const processed = await getFileInfo(fileKey, null);
    if (processed?.url == null) return null;
    return processed;
  };

  const loadNonProcessedRoots = async (teethArch: CapturePosition): Promise<CaseFile | null> => {
    const fileKey = teethArch == CapturePosition.UPPER ? 'real-roots-upper' : 'real-roots-lower';
    const processed = await getFileInfo(fileKey, null);
    if (processed?.url == null) return null;
    return processed;
  };

  return { loadRoots };
}
