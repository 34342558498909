import { useTranslation } from 'react-i18next';
import { ReactComponent as Minus } from '../../../../../assets/icons/minus.svg';
import { ReactComponent as Plus } from '../../../../../assets/icons/plus.svg';
import { TeethArchPosition } from '../../../../../models';
import { Odontogram } from '../../../../../surgeries/components';

import { Slider } from '@mui/material';
import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { useOrthBoundStore } from '../../../../stores/useStore';
import { OrthToolButtonSmall } from '../teethMovements/orthToolButtonSmall';

import './orthPaintTools.scss';
import { EVERGINE_CANVAS_ID, MAIN_CONTAINER_ID } from '../../../../../common';
import { useHandleEventListener } from '../../../../../hooks';
import { useTeethSegmentation } from '../../../../../hooks/orthodontics';
import { useEvergineStore } from 'evergine-react';
import { useBoundStore } from '../../../../../surgeries/stores/useStore';
import { useFocusCanvas } from '../../../../../hooks/shared/useFocusCanvas';

interface OrthPaintToolsProps {
  selectedArchPosition: TeethArchPosition;
}

const ORTH_PAINT_TOOLS_PANEL_ID = 'orth-paint-tools-panel';
const CHANGE_BRUSH_SIZE_STEP = 0.5;

export function OrthPaintTools({ selectedArchPosition }: OrthPaintToolsProps) {
  const [t] = useTranslation();
  const { selectedPieces, setSelectedPieces, modeBrush, modeEraser, setShowModalEnumeration } = useOrthBoundStore();
  const {
    EventNames,
    attachEventListenerToContainer,
    dettachEventListenerToContainer,
    attachEventListenerToPage,
    dettachEventListenerToPage
  } = useHandleEventListener();
  const { BRUSH_SIZE_MIN_VALUE, BRUSH_SIZE_MAX_VALUE, brushSize, setToothToBePainted, updateBrushSize } =
    useTeethSegmentation();
  const brushSizeValueSliderRef = useRef<number>(brushSize);
  const webBusy = useBoundStore((state) => state.webBusy);
  const { setFocusOnCanvas } = useFocusCanvas();
  const { evergineReady } = useEvergineStore();

  useEffect(() => {
    brushSizeValueSliderRef.current = brushSize;
  }, [brushSize]);

  useEffect(() => {
    if (!webBusy) setFocusOnCanvas();
  }, [webBusy, evergineReady]);

  useEffect(() => {
    const canvasContainerElement = document.getElementById(EVERGINE_CANVAS_ID);
    canvasContainerElement?.focus();
    attachEventListenerToContainer(canvasContainerElement, EventNames.Keydown, handleKeyDown);

    const mainContainerElement = document.getElementById(MAIN_CONTAINER_ID);
    attachEventListenerToContainer(mainContainerElement, EventNames.Wheel, handleWheel);
    attachEventListenerToPage(EventNames.Wheel, handleWheelInPage);

    return () => {
      dettachEventListenerToContainer(canvasContainerElement, EventNames.Keydown, handleKeyDown);
      dettachEventListenerToContainer(mainContainerElement, EventNames.Wheel, handleWheel);
      dettachEventListenerToPage(EventNames.Wheel, handleWheelInPage);
    };
  }, []);

  const handleKeyDown = useCallback((event: Event) => {
    const keyboardEvent = event as KeyboardEvent;
    if (keyboardEvent.shiftKey || keyboardEvent.ctrlKey || keyboardEvent.altKey || keyboardEvent.metaKey) {
      const panelElement = document.getElementById(ORTH_PAINT_TOOLS_PANEL_ID);

      if (panelElement) {
        panelElement.style.userSelect = 'none';
      }
    }
  }, []);

  const handleWheelInPage = (event: Event) => {
    const wheelEvent = event as WheelEvent;
    if (wheelEvent.shiftKey || wheelEvent.ctrlKey || wheelEvent.metaKey) {
      setFocusOnCanvas();
    }
  };

  const handleWheel = useCallback(
    (event: Event) => {
      const wheelEvent = event as WheelEvent;
      if (wheelEvent.shiftKey || wheelEvent.ctrlKey || wheelEvent.metaKey) {
        wheelEvent.preventDefault();
        const newBrushSize =
          brushSizeValueSliderRef.current + (wheelEvent.deltaY > 0 ? -CHANGE_BRUSH_SIZE_STEP : CHANGE_BRUSH_SIZE_STEP);
        updateBrushSize(newBrushSize);
      }
    },
    [brushSizeValueSliderRef, updateBrushSize]
  );

  const toggleSelectedPieces = useCallback(
    (pieceId: string) => {
      if (selectedPieces.includes(pieceId)) {
        setSelectedPieces([]);
      } else {
        setSelectedPieces([pieceId]);
        setToothToBePainted(Number(pieceId));
      }
    },
    [selectedPieces, setSelectedPieces, setToothToBePainted]
  );

  const onClickOdontogramPiece = (pieceId: string) => {
    toggleSelectedPieces(pieceId);
    setFocusOnCanvas();
  };

  const onChangeRangeSlider = useCallback(
    (e: any) => {
      updateBrushSize(e.target.value);
    },
    [updateBrushSize]
  );

  const handleBrushSizeButtons = useCallback(
    (param: number) => {
      updateBrushSize(param);
    },
    [updateBrushSize]
  );

  return (
    <>
      <div className="orth-paint-tools" id={ORTH_PAINT_TOOLS_PANEL_ID}>
        <div className="orth-paint-tools__separator" />
        <div className="orth-paint-tools__header">{t('manualSegmentationPanel.selectTeeth')}</div>
        <div className="orth-paint-tools__content">
          <Odontogram
            archPosition={selectedArchPosition}
            showToggleNumbers={true}
            selectedPieces={selectedPieces}
            onPieceClick={onClickOdontogramPiece}
            colorVersion={true}
          />
          <div className="content-instructions">
            <div className={`content-instructions_instruction ${modeBrush ? 'active' : ''}`}>
              <span>{t('manualSegmentationPanel.paintTool')}</span>
            </div>
            <div className={`content-instructions_instruction ${modeEraser ? 'active' : ''}`}>
              <span>{t('manualSegmentationPanel.eraseTool')}</span>
            </div>
          </div>
        </div>
        <div className="orth-paint-tools__separator" />

        <div className="orth-paint-tools__footer">
          <div className="title">
            <p className="text">{t('manualSegmentationPanel.sizeBrush')}</p>
            <div className="right-side">
              <div className="content-legend">
                <input
                  className="input-size"
                  disabled={true}
                  value={`${brushSize} mm`}
                  placeholder={`${brushSize} mm`}
                  max={100}
                  min={0}
                />
              </div>

              <div className="group-buttons">
                <OrthToolButtonSmall
                  Icon={Plus}
                  isDisabled={false}
                  onClick={() => handleBrushSizeButtons(brushSize + CHANGE_BRUSH_SIZE_STEP)}
                />
                <OrthToolButtonSmall
                  Icon={Minus}
                  isDisabled={false}
                  onClick={() => handleBrushSizeButtons(brushSize - CHANGE_BRUSH_SIZE_STEP)}
                />
              </div>
            </div>
          </div>
          <Slider
            disabled={false}
            value={brushSize}
            onChange={(e) => onChangeRangeSlider(e)}
            min={BRUSH_SIZE_MIN_VALUE}
            max={BRUSH_SIZE_MAX_VALUE}
          />
          <button
            disabled={selectedPieces.length === 0 || !selectedPieces}
            type="button"
            className="btn btn-primary calculate"
            onClick={() => setShowModalEnumeration(true)}
          >
            {t('pageInfoMessages.teethSegmentation.changeNumeration')}
          </button>
        </div>
      </div>
    </>
  );
}
