import { useCallback } from 'react';
import { CapturePosition, INJECTED_TYPES, container } from '../../common';
import { useCommonBoundStore } from '../../common/stores/useStore';
import { IFileService } from '../../shared';
import { useBase64Converter } from '../useBase64Converter';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { useFetchModelFiles } from './useFetchModelFiles';

export function useGetFiles(caseId: string) {
  const fileService = container.get<IFileService>(INJECTED_TYPES.IFileService);
  const dynamicModels = 'DynamicModels';
  const dynamicModelsFullPath = `/Content/${dynamicModels}`;
  const { lowerModel3DId, upperModel3DId } = useCommonBoundStore();
  const { getBase64FromArrayBuffer } = useBase64Converter();
  const { currentVersion } = useOrthBoundStore();
  const { fetchNonCachedModelFile } = useFetchModelFiles();

  const getFileInfo = async (fileKey: string, versionId: string) => {
    const file = await fileService.getFileInfo(caseId, versionId, fileKey);
    return file;
  };

  const getSTLFileFromFS = useCallback(
    async (teethArch: CapturePosition) => {
      if (!currentVersion) {
        return;
      }

      let modelName = '';

      if (teethArch == CapturePosition.UPPER) {
        if (!!upperModel3DId) {
          modelName = `${upperModel3DId}.stl`;
        }
      } else if (!!lowerModel3DId) {
        modelName = `${lowerModel3DId}.stl`;
      }

      if (modelName !== '') {
        const fileName = `${modelName}`;
        const fileFSPath = `${dynamicModelsFullPath}/${fileName}`;
        if (!Module.FS.isFile(fileFSPath)) {
          return null;
        }

        const fileInFS = Module.FS.readFile(fileFSPath);
        const base64content = getBase64FromArrayBuffer(fileInFS);

        return base64content;
      }

      return null;
    },
    [upperModel3DId, lowerModel3DId, currentVersion]
  );

  const getSTLFileFromBackend = useCallback(
    async (teethArch: CapturePosition) => {
      if (!currentVersion) {
        return;
      }

      let modelName = '';
      let fileKey = '';

      if (teethArch == CapturePosition.UPPER) {
        if (!!upperModel3DId) {
          modelName = `${upperModel3DId}.stl`;
          fileKey = 'upper-stl';
        }
      } else if (!!lowerModel3DId) {
        modelName = `${lowerModel3DId}.stl`;
        fileKey = 'lower-stl';
      }

      if (modelName !== '') {
        const fileInfo = await getFileInfo(fileKey, currentVersion.id);

        if (fileInfo) {
          const resFile = await fetchNonCachedModelFile(fileInfo.url);
          const arrayBufferData = await resFile.arrayBuffer();
          const base64content = getBase64FromArrayBuffer(arrayBufferData);
          return base64content;
        }
      }

      return null;
    },
    [upperModel3DId, lowerModel3DId, currentVersion]
  );

  const getSTLFile = useCallback(
    async (teethArch: CapturePosition) => {
      if (teethArch === CapturePosition.UPPER && !upperModel3DId) {
        return null;
      }

      if (teethArch === CapturePosition.LOWER && !lowerModel3DId) {
        return null;
      }

      const fileFromFS = await getSTLFileFromFS(teethArch);
      if (fileFromFS !== null) {
        return fileFromFS;
      }

      const fileFromBackend = await getSTLFileFromBackend(teethArch);
      return fileFromBackend;
    },
    [upperModel3DId, lowerModel3DId, currentVersion, getSTLFileFromBackend, getSTLFileFromFS]
  );

  return {
    getFileInfo,
    getSTLFile
  };
}
