import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg';
import { useOrthBoundStore } from '../../../../stores/useStore';

import { TeethArchPosition } from '../../../../../models';
import { OrthPaintTools } from './OrthPaintTools';

import './orthTeethPaint.scss';
import { ToolPanelDraggableContainer } from '../ToolPanelDraggableContainer';

interface OrthTeethPaintProps {
  selectedArchPosition: TeethArchPosition;
}

const PANEL_SIZE = 312;
const HANDLER_DRAG_ELEMENT_CLASS = 'handleOrthTeethPaint';

export function OrthTeethPaint({ selectedArchPosition }: OrthTeethPaintProps) {
  const [t] = useTranslation();
  const { setShowPaintPanel, setSelectedPieces } = useOrthBoundStore();
  const [toolPosition, setToolPosition] = useState<DOMRect>();

  const handleClose = () => {
    setShowPaintPanel(false);
    setSelectedPieces([]);
  };

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const position = document.getElementById('orthTeethPaintButton')?.getBoundingClientRect();
      if (position) {
        setToolPosition(position);
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      {!!toolPosition && (
        <ToolPanelDraggableContainer
          panelSize={PANEL_SIZE}
          toolPosition={toolPosition}
          handlerElementClass={HANDLER_DRAG_ELEMENT_CLASS}
        >
          <div className="orth-paint">
            <div className="orth-paint__header">
              <div className={`orth-paint__title ${HANDLER_DRAG_ELEMENT_CLASS}`}>
                <div>{t('evergineTools.paintSegmentation')}</div>
              </div>

              <button type="button" className="orth-paint__close" onClick={handleClose}>
                <CloseIcon className="clickable" />
              </button>
            </div>
            <div className="orth-paint__separator" />
            <div className="orth-paint__scroll">
              <OrthPaintTools selectedArchPosition={selectedArchPosition} />
            </div>
          </div>
        </ToolPanelDraggableContainer>
      )}
    </>
  );
}
