import { StateCreator } from 'zustand';
import { ToothTransformInfo } from '../../../common';

export interface ToothTransformSlice {
  selectedTeethTransformData: ToothTransformInfo;
  setSelectedTeethTransformDataData: (newValue: ToothTransformInfo) => void;
  teethTransformDataList: ToothTransformInfo[];
  setTeethTransformDataList: (newValue: ToothTransformInfo[]) => void;
}

export const createToothTransformSlice: StateCreator<ToothTransformSlice, [], [], ToothTransformSlice> = (set) => ({
  selectedTeethTransformData: null,
  toothHoverData: null,
  teethTransformDataList: [],
  setSelectedTeethTransformDataData: (selectedTeethTransformData: ToothTransformInfo) =>
    set((_) => ({ selectedTeethTransformData })),
  setTeethTransformDataList: (teethTransformDataList: ToothTransformInfo[]) => set((_) => ({ teethTransformDataList }))
});
