import { StateCreator } from 'zustand';
import { MovementsTableDto } from '../../../shared';

export interface OrthToolsSlice {
  showMovementsTable: boolean;
  setShowMovementsTable: (showMovementsTable: boolean) => void;
  movementsTable: MovementsTableDto;
  setMovementsTable: (movementsTable: MovementsTableDto) => void;
  showBoltonPanel: boolean;
  setShowBoltonPanel: (showBoltonPanel: boolean) => void;
  showEvolutionPanel: boolean;
  setShowEvolutionPanel: (showEvolutionPanel: boolean) => void;
  expandEvolutionPanel: boolean;
  setExpandEvolutionPanel: (expandEvolutionPanel: boolean) => void;
  isEvolutionPanelSliced: boolean;
  setIsEvolutionPanelSliced: (isEvolutionPanelSliced: boolean) => void;
  showIPR: boolean;
  setShowIPR: (showIPR: boolean) => void;
  showInterdental: boolean;
  setShowInterdental: (showInterdental: boolean) => void;
  showMovements: boolean;
  setShowMovements: (showMovements: boolean) => void;
  showIprPanel: boolean;
  setShowIprPanel: (showIprPanel: boolean) => void;
  showAttachesPanel: boolean;
  setShowAttachesPanel: (showAttachesPanel: boolean) => void;
  axixRootsEditMode: boolean;
  setAxixRootsEditMode: (showAttachesPanel: boolean) => void;
  showTadsPanel: boolean;
  setShowTadsPanel: (showTadsPanel: boolean) => void;
  iprProcessed: boolean;
  setIprProcessed: (iprProcessed: boolean) => void;
}

export const createOrthToolsSlice: StateCreator<OrthToolsSlice, [], [], OrthToolsSlice> = (set) => ({
  axixRootsEditMode: false,
  showMovementsTable: false,
  movementsTable: null,
  showBoltonPanel: false,
  showEvolutionPanel: false,
  expandEvolutionPanel: false,
  isEvolutionPanelSliced: false,
  showIPR: false,
  showInterdental: false,
  showMovements: false,
  showAttachesPanel: false,
  showTadsPanel: false,
  showIprPanel: false,
  iprProcessed: null,
  setAxixRootsEditMode: (axixRootsEditMode: boolean) => set((_) => ({ axixRootsEditMode })),
  setShowMovementsTable: (showMovementsTable: boolean) => set((_) => ({ showMovementsTable })),
  setMovementsTable: (movementsTable: MovementsTableDto) => set((_) => ({ movementsTable })),
  setShowBoltonPanel: (showBoltonPanel: boolean) => set((_) => ({ showBoltonPanel })),
  setShowEvolutionPanel: (showEvolutionPanel: boolean) => set((_) => ({ showEvolutionPanel })),
  setExpandEvolutionPanel: (expandEvolutionPanel: boolean) => set((_) => ({ expandEvolutionPanel })),
  setIsEvolutionPanelSliced: (isEvolutionPanelSliced: boolean) => set((_) => ({ isEvolutionPanelSliced })),
  setShowIPR: (showIPR: boolean) => set((_) => ({ showIPR })),
  setShowInterdental: (showInterdental: boolean) => set((_) => ({ showInterdental })),
  setShowMovements: (showMovements: boolean) => set((_) => ({ showMovements })),
  setShowAttachesPanel: (showAttachesPanel: boolean) => set((_) => ({ showAttachesPanel })),
  setShowTadsPanel: (showTadsPanel: boolean) => set((_) => ({ showTadsPanel })),
  setShowIprPanel: (showIprPanel: boolean) => set((_) => ({ showIprPanel })),
  setIprProcessed: (iprProcessed: boolean) => set((_) => ({ iprProcessed }))
});
