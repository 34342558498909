import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import { ReactComponent as IconDelete } from '../../../assets/icons/delete.svg';
import { ReactComponent as IconMinus } from '../../../assets/icons/minus.svg';
import { ReactComponent as IconPlus } from '../../../assets/icons/plus.svg';
import { IprLabelPosition } from '../../../common/evergine';
import { useOrthBoundStore } from '../../stores/useStore';
import './buttonsIPR.scss';
import React from 'react';
import { useUtils } from '../../../hooks';
import { Key } from 'ts-key-enum';

enum OperationType {
  ADD,
  SUBSTRACT
}

export function IPRButtons() {
  const { iprLabelSelected } = useOrthBoundStore();
  const { updateMovements } = useUtils();
  const [currentIprLabel, setCurrentIprLabel] = useState<IprLabelPosition>();
  const [iprTools, setIprTools] = useState<React.ReactNode | null>();

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setCurrentIprLabel(undefined);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setCurrentIprLabel(iprLabelSelected);
  }, [iprLabelSelected]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === Key.Delete) {
        removeIprLabel();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [currentIprLabel]);

  const handleActionEvergine = useCallback(
    (action: OperationType) => {
      if (currentIprLabel === undefined) {
        return;
      }

      let newValue = currentIprLabel.iprDistance;
      newValue += action === OperationType.ADD ? 0.1 : -0.1;
      newValue = parseFloat(newValue.toFixed(1));

      if (newValue < 0.1) {
        removeIprLabel();
        return;
      }

      setCurrentIprLabel({ ...currentIprLabel, iprDistance: newValue });
      window.App.webEventsProxy.iprs.updateLabel(currentIprLabel.leftToothFdi, currentIprLabel.rightToothFdi, newValue);

      updateMovements();
    },
    [currentIprLabel, updateMovements]
  );

  const removeIprLabel = useCallback(() => {
    if (currentIprLabel === undefined) {
      return;
    }

    window.App.webEventsProxy.iprs.removeLabel(currentIprLabel.leftToothFdi, currentIprLabel.rightToothFdi);
    updateMovements();
  }, [currentIprLabel]);

  useEffect(() => {
    if (currentIprLabel === null || currentIprLabel === undefined) {
      return null;
    }

    const key = `ipr-${currentIprLabel.teethArch}-${currentIprLabel.leftToothFdi}-${currentIprLabel.rightToothFdi}`;
    const id = key;
    const idContainer = `${key}-container`;
    const scale = window.devicePixelRatio || 1;
    const labelsHalfWidthPx = 15 * scale;
    const correctionX = 70 * scale;
    const correctionY = 25 * scale;

    const left = `${currentIprLabel.position.x / scale - labelsHalfWidthPx}px`;
    const top = `${currentIprLabel.position.y / scale - labelsHalfWidthPx}px`;

    const leftMenu = `${(currentIprLabel.position.x - correctionX - labelsHalfWidthPx) / scale}px`;
    const topMenu = `${(currentIprLabel.position.y + correctionY + labelsHalfWidthPx) / scale}px`;

    const style = { left: left, top: top } as CSSProperties;
    const styleMenu = {
      left: leftMenu,
      top: topMenu,
      backgroundColor: 'transparent!important',
      position: 'absolute'
    } as CSSProperties;

    const styleSVGs = {
      width: `${24 / scale}px`,
      height: `${24 / scale}px`
    };

    const buttonprops = {
      'data-for': 'button-ipr',
      className: ''
    };

    const tools = (
      <div ref={wrapperRef} className="contain-ipr-button" key={idContainer}>
        <Dropdown defaultShow={true} className="ipr-dropdown" autoClose="outside" drop="down" style={styleMenu}>
          <Dropdown.Toggle {...buttonprops} id={id} bsPrefix="">
            <div key={id} data-tip data-for={id} style={style} className="button-ipr"></div>
          </Dropdown.Toggle>

          <Dropdown.Menu style={styleMenu}>
            <div className="menu-ipr">
              <button
                data-tip
                className={`tooltip-button`}
                onClick={() => handleActionEvergine(OperationType.SUBSTRACT)}
              >
                <IconMinus />
              </button>
              <button data-tip className={`tooltip-button`} onClick={() => handleActionEvergine(OperationType.ADD)}>
                <IconPlus />
              </button>
              <div className="vertical-line"></div>
              <button data-tip className={`tooltip-button`} onClick={removeIprLabel}>
                <IconDelete />
              </button>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
    setIprTools(tools);
  }, [currentIprLabel]);

  return <>{currentIprLabel !== undefined && <div>{iprTools}</div>}</>;
}
