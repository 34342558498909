import { useProtrusionViewContextMenu } from '../../../hooks';
import { ContextMenu } from '../../../common';
import { CustomContextMenuProps } from './customContextMenuProps';

export function EmptySceneContextMenu({ onToggleAction, positionData, show }: CustomContextMenuProps) {
  const { protrusionOptions } = useProtrusionViewContextMenu();
  const emptySceneOptions = protrusionOptions;

  return (
    <ContextMenu
      options={emptySceneOptions}
      positionData={positionData}
      show={show}
      onToggle={() => onToggleAction()}
      autoClose={true}
    />
  );
}
