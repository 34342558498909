import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useOrthBoundStore } from '../../../stores/useStore';

import { EvolutionStep } from '..';
import { IprLabel } from '../../../../common/evergine';
import { useCommonBoundStore } from '../../../../common/stores/useStore';
import { QuadrantRows } from './QuadrantRows';
import './evolutionTable.scss';
import { useStepsManager } from '../../../../hooks/orthodontics';
import { useHandleEventListener, useWindowSize } from '../../../../hooks';
import { ReactComponent as ShowMoreIcon } from '../../../../assets/icons/chevron-right.svg';
import { ReactComponent as ShowLessIcon } from '../../../../assets/icons/chevron-left.svg';
import { EvolutionTableHeader } from './EvolutionTableHeader';

interface EvolutionTableProps {
  steps: EvolutionStep[];
  applyIPRList: IprLabel[];
  updateIPRList: (applyIPRList: IprLabel[]) => void;
  opened: boolean;
}

const defaultPositionXInPixels = 8;

function EvolutionTable({ steps, applyIPRList, updateIPRList, opened }: EvolutionTableProps) {
  const {
    showEvolutionPanel,
    expandEvolutionPanel,
    currentSliceSteps,
    setCurrentSliceSteps,
    setCurrentSliceRange,
    setFitRowsInPanel,
    setIsEvolutionPanelSliced
  } = useOrthBoundStore((state) => ({
    showEvolutionPanel: state.showEvolutionPanel,
    expandEvolutionPanel: state.expandEvolutionPanel,
    setExpandEvolutionPanel: state.setExpandEvolutionPanel,
    currentSliceSteps: state.currentSliceSteps,
    setCurrentSliceSteps: state.setCurrentSliceSteps,
    setCurrentSliceRange: state.setCurrentSliceRange,
    setFitRowsInPanel: state.setFitRowsInPanel,
    setIsEvolutionPanelSliced: state.setIsEvolutionPanelSliced
  }));
  const { goToStep } = useStepsManager();
  const { EventNames, attachEventListenerToContainer, dettachEventListenerToContainer } = useHandleEventListener();
  const [isBodyScrollVisible, setIsBodyScrollVisible] = useState<boolean>();
  const { activeStep, isNewStepClientModification, setSelectedStepIndexes } = useCommonBoundStore((state) => ({
    activeStep: state.activeStep,
    isNewStepClientModification: state.isNewStepClientModification,
    setSelectedStepIndexes: state.setSelectedStepIndexes
  }));
  const headerStepId = 'evolutiontable-step-';
  const [isKeyShiftDown, setIsKeyShiftDown] = useState<boolean>(false);
  const windowsSize = useWindowSize();
  const columnElementClass = 'evolutiontable-piece';
  const columnBeforeElementClass = 'evolutiontable-piece-number';
  const defaultColumnWidth = 22; // Default value 22px
  const defaultColumnforeWidth = 48; // Default value 48px
  const [columnWidth, setColumnWidth] = useState<number>(defaultColumnWidth);
  const [columnBeforeWidth, setColumnBeforeWidth] = useState<number>(defaultColumnforeWidth);

  const piecesRef = useRef(null);
  const activeStepRef = useRef(null);

  const calculateWidths = useCallback(() => {
    calculateColumnWidth();
    calculateColumnBeforeWidth();
  }, []);

  const calculateColumnWidth = useCallback(() => {
    if (steps?.length === 0) {
      return;
    }
    const widhtEl = document.getElementsByClassName(columnElementClass);
    const widht = widhtEl[0]?.clientWidth || 0;
    const margin = widhtEl[0] ? parseFloat(getComputedStyle(widhtEl[0]).marginRight) : 0;
    setColumnWidth(widht + margin);
  }, [steps]);

  const calculateColumnBeforeWidth = useCallback(() => {
    if (steps?.length === 0) {
      return;
    }
    const widhtEl = document.getElementsByClassName(columnBeforeElementClass);
    const widht = widhtEl[0]?.clientWidth || 0;
    const margin = widhtEl[0] ? parseFloat(getComputedStyle(widhtEl[0]).marginRight) : 0;
    setColumnBeforeWidth(widht + margin);
  }, [steps]);

  useEffect(() => {
    // Calculate 50% of the width of the screen to fit the steps
    if (steps?.length === 0) {
      return;
    }
    // const availableWidth = windowsSize.width / 2 - columnBeforeWidth;
    const availableWidth = expandEvolutionPanel ? windowsSize.width : windowsSize.width / 2 - columnBeforeWidth;
    const fitRows = columnWidth ? Math.floor(availableWidth / columnWidth) : steps.length;
    setFitRowsInPanel(fitRows);

    const sliceRange = { from: 0, to: fitRows };
    setCurrentSliceRange(sliceRange);
    const sliceSteps = steps.slice(sliceRange.from, sliceRange.to);
    setCurrentSliceSteps(sliceSteps);
    setIsEvolutionPanelSliced(sliceSteps.length !== steps.length);
  }, [steps, windowsSize, columnWidth, expandEvolutionPanel]);

  useEffect(() => {
    if (!piecesRef.current) {
      return;
    }

    const isScrollVisible = piecesRef.current?.clientHeight < piecesRef.current?.scrollHeight;
    setIsBodyScrollVisible(isScrollVisible);
  }, [piecesRef, showEvolutionPanel]);

  const handleKeyDown = useCallback((event: Event) => {
    const keyboardEvent = event as KeyboardEvent;
    if (keyboardEvent.shiftKey) {
      setIsKeyShiftDown(true);
    }
  }, []);

  const handleKeyUp = useCallback((event: Event) => {
    const keyboardEvent = event as KeyboardEvent;
    if (!keyboardEvent.shiftKey) {
      setIsKeyShiftDown(false);
    }
  }, []);

  // The following event handlers are just to manage the funny behaviour of shift keyUp event not being captured properly
  const handleWindowBlur = useCallback(() => {
    setIsKeyShiftDown(false);
  }, []);

  const handleVisibilityChange = useCallback(() => {
    if (document.hidden) {
      setIsKeyShiftDown(false);
    }
  }, []);

  const handleContextMenu = useCallback(() => {
    setIsKeyShiftDown(false);
  }, []);

  const handleMouseUp = useCallback(() => {
    setIsKeyShiftDown(false);
  }, []);

  useEffect(() => {
    if (showEvolutionPanel) {
      window.addEventListener(EventNames.Keydown, handleKeyDown);
      window.addEventListener(EventNames.Keyup, handleKeyUp);
      window.addEventListener(EventNames.Blur, handleWindowBlur);
      document.addEventListener(EventNames.VisibilityChange, handleVisibilityChange);
      window.addEventListener(EventNames.ContextMenu, handleContextMenu);
      window.addEventListener(EventNames.MouseUp, handleMouseUp);
    } else {
      window.removeEventListener(EventNames.Keydown, handleKeyDown);
      window.removeEventListener(EventNames.Keyup, handleKeyUp);
      window.removeEventListener(EventNames.Blur, handleWindowBlur);
      document.removeEventListener(EventNames.VisibilityChange, handleVisibilityChange);
      window.removeEventListener(EventNames.ContextMenu, handleContextMenu);
      window.removeEventListener(EventNames.MouseUp, handleMouseUp);
    }

    // Cleanup function to remove listeners if the component unmounts
    return () => {
      window.removeEventListener(EventNames.Keydown, handleKeyDown);
      window.removeEventListener(EventNames.Keyup, handleKeyUp);
      window.removeEventListener(EventNames.Blur, handleWindowBlur);
      document.removeEventListener(EventNames.VisibilityChange, handleVisibilityChange);
      window.removeEventListener(EventNames.ContextMenu, handleContextMenu);
      window.removeEventListener(EventNames.MouseUp, handleMouseUp);
    };
  }, [showEvolutionPanel, handleKeyDown, handleKeyUp, handleWindowBlur, handleVisibilityChange, handleContextMenu]);

  const defaultActiveStepStyle = {
    '--before-height': `${piecesRef.current?.scrollHeight}px`,
    '--position-x': `${defaultPositionXInPixels}px`,
    '--visibility-before': 'block'
  } as React.CSSProperties;

  useEffect(() => {
    const updatePositionX = () => {
      const selectedStepIndexEl = document.getElementById(`${headerStepId}${activeStep}`);
      if (selectedStepIndexEl) {
        const newPositionX = selectedStepIndexEl.offsetLeft || 8;
        if (piecesRef.current) {
          piecesRef.current.style.setProperty('--position-x', `${newPositionX}px`);
          piecesRef.current.style.setProperty('--visibility-before', 'block');
        }
      } else {
        if (piecesRef.current) {
          piecesRef.current.style.setProperty('--visibility-before', 'none');
        }
      }
    };

    updatePositionX();
  }, [activeStep, currentSliceSteps]);

  useEffect(() => {
    activeStepRef.current = document.getElementById(`${headerStepId}${activeStep}`);
  }, [activeStep, currentSliceSteps]);

  const highlightedButtons = useMemo(() => {
    return (
      <>
        {currentSliceSteps.map((step: EvolutionStep) => {
          return (
            <button
              className={`evolutiontableheader--step ${step.index === activeStep ? 'is-active' : ''}`}
              key={`evolutiontable-step-${step.index}`}
              id={`${headerStepId}${step.index}`}
              onClick={() => {
                setSelectedStepIndexes([step.index]);
                goToStep(step.index, false);
              }}
              disabled={isNewStepClientModification}
            >
              {step.index}
            </button>
          );
        })}
      </>
    );
  }, [activeStep, currentSliceSteps, isNewStepClientModification]);

  return (
    <div className="evolutiontable">
      <EvolutionTableHeader highlightedButtons={highlightedButtons} isBodyScrollVisible={isBodyScrollVisible} />
      <div className="evolutiontable-body" id="evolutiontable-body" style={defaultActiveStepStyle} ref={piecesRef}>
        {opened && (
          <div className="evolutiontable-content">
            <div>
              <QuadrantRows
                isKeyShiftDown={isKeyShiftDown}
                steps={currentSliceSteps}
                updateIPRList={updateIPRList}
                applyIPRList={applyIPRList}
                setIsKeyShiftDown={setIsKeyShiftDown}
                onInit={calculateWidths}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default React.memo(EvolutionTable);
