import { injectable } from 'inversify';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { ICommand } from './command';
import { useBoundStore } from '../../surgeries/stores/useStore';
export interface ICommandInvoker {
  execute(command: ICommand): Promise<void>;
  undo(): Promise<void>;
  undoReverse(): Promise<void>;
  redo(): Promise<void>;
  addEvergineCommand(command: ICommand): void;
  clear(): void;
}

@injectable()
export default class CommandInvokerService implements ICommandInvoker {
  private undoStack: ICommand[];
  private redoStack: ICommand[];
  // Limit single command exceution at the time
  private commandQueue: (() => Promise<void>)[];
  private isExecuting: boolean;

  constructor() {
    this.undoStack = [];
    this.redoStack = [];
    this.commandQueue = [];
  }

  private enqueueCommand(command: () => Promise<void>): void {
    this.commandQueue.push(command);
    this.processQueue();
  }

  private async processQueue(): Promise<void> {
    const evergineBusy = useBoundStore.getState().evergineBusy;
    if (this.isExecuting || this.commandQueue.length === 0 || evergineBusy) {
      return;
    }

    this.isExecuting = true;
    const command = this.commandQueue.shift();
    if (command) {
      await command();
    }
    this.isExecuting = false;

    if (this.commandQueue.length > 0) {
      this.processQueue();
    }
  }

  execute(command: ICommand): Promise<void> {
    return new Promise<void>((resolve) => {
      this.enqueueCommand(async () => {
        await command.execute();

        this.undoStack.push(command);
        this.redoStack = [];
        useOrthBoundStore.setState({ canUndo: true });
        useOrthBoundStore.setState({ canRedo: false });
        resolve();
      });
    });
  }

  private handleUndoCommand(getCommand: () => ICommand | undefined): Promise<void> {
    return new Promise<void>((resolve) => {
      this.enqueueCommand(async () => {
        if (this.undoStack.length <= 0) {
          resolve();
          return;
        }

        const command = getCommand();
        if (!command) {
          resolve();
          return;
        }

        await command.undo();

        const { upperMovements, lowerMovements } = window.App.webEventsProxy.movements.getTreatmentMovements();
        useOrthBoundStore.setState({ lowerDentalMovements: lowerMovements, upperDentalMovements: upperMovements });

        this.redoStack.push(command);
        useOrthBoundStore.setState({ canUndo: this.undoStack.length > 0 });
        useOrthBoundStore.setState({ canRedo: true });

        resolve();
      });
    });
  }

  undo(): Promise<void> {
    return this.handleUndoCommand(() => this.undoStack.pop());
  }

  undoReverse(): Promise<void> {
    return this.handleUndoCommand(() => this.undoStack.shift());
  }

  redo(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.enqueueCommand(async () => {
        if (this.redoStack.length <= 0) {
          resolve();
          return;
        }

        const command = this.redoStack.pop();

        if (command) {
          await command.execute();

          const { upperMovements, lowerMovements } = window.App.webEventsProxy.movements.getTreatmentMovements();
          useOrthBoundStore.setState({ lowerDentalMovements: lowerMovements, upperDentalMovements: upperMovements });

          this.undoStack.push(command);
          useOrthBoundStore.setState({ canUndo: true });
          useOrthBoundStore.setState({ canRedo: this.redoStack.length > 0 });
        }

        resolve();
      });
    });
  }

  addEvergineCommand(command: ICommand): void {
    if (command.isEvergineCommand) {
      this.undoStack.push(command);

      if (this.redoStack.length > 0) {
        window.App.webEventsProxy.common.removeActionCommands(this.redoStack);
      }

      this.redoStack.splice(0, this.redoStack.length);
      useOrthBoundStore.setState({ canUndo: true });
      useOrthBoundStore.setState({ canRedo: false });
    }
  }

  clear(): void {
    if (this.undoStack.length > 0 || this.redoStack.length > 0) {
      this.undoStack.splice(0, this.undoStack.length);
      this.redoStack.splice(0, this.redoStack.length);
      useOrthBoundStore.setState({ canUndo: false });
      useOrthBoundStore.setState({ canRedo: false });

      window.App.webEventsProxy.common.clearActionCommands();
    }
  }
}
