import { StateCreator } from 'zustand';

export interface TimelineSlice {
  activeStep: number;
  maxSteps: number;
  isNewStepClientModification: boolean;
  isEditStep: boolean;
  isPreventNavigationCancelled: boolean;
  selectedStepsIndexes: number[];
  setActiveStep: (newActiveStep: number) => void;
  setMaxSteps: (maxSteps: number) => void;
  setIsNewStepClientModification: (newValue: boolean) => void;
  setIsEditStep: (newValue: boolean) => void;
  setIsPreventedNavigationCancelled: (isPreventNavigationCancelled: boolean) => void;
  setSelectedStepIndexes: (selectedStepsIndexes: number[]) => void;
}
export const createTimelineSlice: StateCreator<TimelineSlice, [], [], TimelineSlice> = (set) => ({
  activeStep: 0,
  maxSteps: 0,
  isNewStepClientModification: false,
  isEditStep: true,
  isPreventNavigationCancelled: false,
  selectedStepsIndexes: [],
  setActiveStep: (newActiveStep: number) => set(() => ({ activeStep: newActiveStep })),
  setMaxSteps: (maxSteps: number) => set(() => ({ maxSteps })),
  setIsNewStepClientModification: (newValue: boolean) => set(() => ({ isNewStepClientModification: newValue })),
  setIsEditStep: (newValue: boolean) => set(() => ({ isEditStep: newValue })),
  setIsPreventedNavigationCancelled: (isPreventNavigationCancelled: boolean) =>
    set(() => ({ isPreventNavigationCancelled })),
  setSelectedStepIndexes: (selectedStepsIndexes: number[]) => set(() => ({ selectedStepsIndexes }))
});
