import { useTranslation } from 'react-i18next';
import { ReactComponent as Info } from '../../../assets/icons/info.svg';

import './infoMessage.scss';

interface InfoMessageProps {
  message?: string;
}

export function InfoMessage({ message }: InfoMessageProps) {
  const [t] = useTranslation();
  return (
    <>
      {!!message && (
        <div className="infomessage no-select">
          <Info />
          <span>{t(message)}</span>
        </div>
      )}
    </>
  );
}
