import { useTranslation } from 'react-i18next';
import Accordion from 'react-bootstrap/esm/Accordion';
import Radio from '../../../radio/Radio';
import { CaseIdentifierType } from '../../../../stores/slices/designerSettingsSlice';

interface ConfigCaseIdentifierSelectorProps {
  localCurrentCaseIdentifier: CaseIdentifierType;
  changeLocalCaseIdentifier: (caseIdentifier: CaseIdentifierType) => void;
}

export function ConfigCaseIdentifierSelector({
  localCurrentCaseIdentifier,
  changeLocalCaseIdentifier
}: ConfigCaseIdentifierSelectorProps) {
  const [t] = useTranslation();

  return (
    <Accordion>
      <Accordion.Item eventKey="caseIdentifier">
        <Accordion.Header className="configuration-section--title">
          {t('navbar.actions.configuration.caseIdentifier.title')}
        </Accordion.Header>
        <Accordion.Body>
          <Radio
            labelOnRight={false}
            expanded={true}
            active={localCurrentCaseIdentifier === CaseIdentifierType.CaseId}
            label={t('navbar.actions.configuration.caseIdentifier.caseId')}
            callBack={() => changeLocalCaseIdentifier(CaseIdentifierType.CaseId)}
          />
          <Radio
            labelOnRight={false}
            expanded={true}
            active={localCurrentCaseIdentifier === CaseIdentifierType.PatientName}
            label={t('navbar.actions.configuration.caseIdentifier.patientName')}
            callBack={() => changeLocalCaseIdentifier(CaseIdentifierType.PatientName)}
          />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
