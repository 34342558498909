import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowAside } from '../../../../assets/icons/arrow-right-gray.svg';
import { useCaseType } from '../../../../hooks';
import { CaseType } from '../../../../models';
import { AsideStepsOrtho, orthodonticsAsideConfig } from '../../../../orthodontics/components/layout';
import { AsideStepsSurgery, surgeryAsideConfig } from '../../../../surgeries/components/layout';

type AsidePanelTypes = {
  pathname: string;
};

export function AsidePanel({ pathname }: AsidePanelTypes) {
  const [t] = useTranslation();
  const caseType = useCaseType();
  const [isAsideStepsVisible, setIsAsideStepsVisible] = useState<boolean>(false);

  const toggleIsAsideVisible = useCallback(() => {
    setIsAsideStepsVisible(!isAsideStepsVisible);
  }, [isAsideStepsVisible]);

  const getCurrentStepTitle = useCallback(() => {
    const asideConfig = caseType === CaseType.Surgery ? surgeryAsideConfig : orthodonticsAsideConfig;

    return (
      asideConfig
        .map((config) => config.items.filter((item) => item?.route === pathname))
        .filter(({ length }) => length)
        .flat()[0]?.title || t('aside.openAside')
    );
  }, [caseType, pathname]);

  const getCurrentSectionTitle = useCallback(() => {
    const asideConfig = caseType === CaseType.Surgery ? surgeryAsideConfig : orthodonticsAsideConfig;
    const item = asideConfig.find((config) => config.items.some((item) => item.route === pathname));

    return item ? item.title : '';
  }, [caseType, pathname]);

  return (
    <aside className={`aside d-flex align-items-center ${isAsideStepsVisible ? 'aside-blue' : ''}`}>
      <div
        data-testid="aside-dropdown"
        onClick={toggleIsAsideVisible}
        className="aside-dropdown d-flex justify-content-between align-items-center"
      >
        <div className="aside-info no-select">
          <div className="aside-title" title={t(getCurrentStepTitle())}>
            {t(getCurrentStepTitle())}
          </div>
          <div className="aside-subtitle">{t(getCurrentSectionTitle())}</div>
        </div>

        <div>
          <ArrowAside className={isAsideStepsVisible ? 'aside-arrow-active' : ''} />
        </div>
      </div>
      <div data-testid="aside-steps" className={`aside-steps ${isAsideStepsVisible ? '' : 'd-none'}`}>
        {caseType === CaseType.Surgery ? <AsideStepsSurgery /> : <AsideStepsOrtho />}
      </div>
    </aside>
  );
}
