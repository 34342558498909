import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Layers } from '../../../../assets/icons/evergineTools/layers.svg';
import { AbilityAction, AbilityContext, Constants, OrthoAbilitySubject, PROFILES_TYPE } from '../../../../shared';
import { useOrthBoundStore } from '../../../stores/useStore';
import { ToolsDropdownBase, ToolsDropdownBaseItem } from './ToolsDropdownBase';
import { useBoundStore } from '../../../../surgeries/stores/useStore';
import { OrthodonticsPagesUrl } from '../../../OrthodonticsRoutes';
import { useIprCalculation } from '../../../../hooks/shared/useIprCalculation';

export function OrthLayers(props: any) {
  const { dataFor, handleClick, className } = props;
  const ability = useContext(AbilityContext);
  const canManageLabels = useMemo(
    () => ability && ability.can(AbilityAction.View, OrthoAbilitySubject.Labels),
    [ability]
  );
  const [t] = useTranslation();
  const [toggleAttachments, setToggleAttachments] = useState(true);
  const [toggleLabels, setToggleLabels] = useState(true);
  const [toggleOcclusogram, setToggleOcclusogram] = useState(false);
  const [toggleRoots, setToggleRoots] = useState(false);
  const [opacityLevelRoots, setOpacityLevelRoots] = useState(100);
  const [toggleGum, setToggleGum] = useState(true);
  const [opacityLevelGum, setOpacityLevelGum] = useState(100);
  const [toggleTeeth, setToggleTeeth] = useState(true);
  const [opacityLevelTeeth, setOpacityLevelTeeth] = useState(100);
  const [oclussionZonesLevel, setOclussionZonesLevel] = useState(0);
  const [toggleTADs, setToggleTADs] = useState(true);
  const {
    upperDentalMovements,
    lowerDentalMovements,
    setShowMiniOcclusogram,
    setShowIPR,
    showIPR,
    setShowInterdental,
    showInterdental
  } = useOrthBoundStore((state) => ({
    upperDentalMovements: state.upperDentalMovements,
    lowerDentalMovements: state.lowerDentalMovements,
    setShowMiniOcclusogram: state.setShowMiniOcclusogram,
    setShowIPR: state.setShowIPR,
    showIPR: state.showIPR,
    setShowInterdental: state.setShowInterdental,
    showInterdental: state.showInterdental
  }));
  const [toggleIpr, setToggleIpr] = useState(showIPR);
  const [toggleInterdentalDistances, setToggleInterdentalDistances] = useState(showInterdental);
  const isClient = useBoundStore.getState().userProfile?.type === PROFILES_TYPE.client;
  const { checkIfIprsAreCalculated } = useIprCalculation();

  useEffect(() => {
    if (!upperDentalMovements && !lowerDentalMovements) {
      return;
    }

    if (!canManageLabels) {
      // window.App.webEventsProxy.layers.showLabels(false);
    }
  }, [upperDentalMovements, lowerDentalMovements, canManageLabels]);

  const onToggleLabels = () => {
    const newToggleLabels = !toggleLabels;
    setToggleLabels(newToggleLabels);
    // window.App.webEventsProxy.layers.showLabels(newToggleLabels);
  };

  const onToggleAttachments = () => {
    const newToggleAttachments = !toggleAttachments;
    setToggleAttachments(newToggleAttachments);
    window.App.webEventsProxy.layers.showAttachments(newToggleAttachments);
  };

  const onToggleTADs = () => {
    const newToggleTADs = !toggleTADs;
    setToggleTADs(newToggleTADs);
    window.App.webEventsProxy.layers.showTADs(newToggleTADs);
  };

  const onToggleOcclusogram = () => {
    const newToggleOcclusogram = !toggleOcclusogram;
    setToggleOcclusogram(newToggleOcclusogram);
    setShowMiniOcclusogram(newToggleOcclusogram);
    window.App.webEventsProxy.layers.showOcclusogram(newToggleOcclusogram);
  };

  const onToggleIpr = () => {
    const newToggleIpr = !toggleIpr;
    setToggleIpr(newToggleIpr);
    setShowIPR(newToggleIpr);
    if (newToggleIpr) {
      setToggleInterdentalDistances(false);
      setShowInterdental(false);
    }
    window.App.webEventsProxy.layers.showIprs(newToggleIpr);
  };

  const onToggleInterdentalDistances = () => {
    const newToggleInterdentalDistances = !toggleInterdentalDistances;
    setToggleInterdentalDistances(newToggleInterdentalDistances);
    setShowInterdental(newToggleInterdentalDistances);
    if (newToggleInterdentalDistances) {
      setToggleIpr(false);
      setShowIPR(false);
    }
    window.App.webEventsProxy.layers.showInterdentalDistances(newToggleInterdentalDistances);
  };

  const onToggleRoots = () => {
    const isGoingToShowRoots = !toggleRoots;
    setToggleRoots(isGoingToShowRoots);

    onChangeOpacityGum(isGoingToShowRoots ? Constants.defaultPercentageOpacityGum : 100);

    window.App.webEventsProxy.layers.showRoots(isGoingToShowRoots);
  };

  const onChangeOpacityRoots = (value: number) => {
    setOpacityLevelRoots(value);
    window.App.webEventsProxy.layers.setRootsOpacity(value);
  };

  const onToggleGum = () => {
    const newToggleGum = !toggleGum;
    setToggleGum(newToggleGum);
    window.App.webEventsProxy.layers.showGums(newToggleGum);
  };

  const onChangeOpacityGum = (value: number) => {
    setOpacityLevelGum(value);
    window.App.webEventsProxy.layers.setGumsOpacity(value);
  };

  const onToggleTeeth = () => {
    const newToggleTeeth = !toggleTeeth;
    setToggleTeeth(newToggleTeeth);
    window.App.webEventsProxy.layers.showTeeth(newToggleTeeth);
  };

  const onChangeOpacityTeeth = (value: number) => {
    setOpacityLevelTeeth(value);
    window.App.webEventsProxy.layers.setTeethOpacity(value);
  };

  const onChangeOclussionZones = (value: number) => {
    setOclussionZonesLevel(value);
    window.App.webEventsProxy.layers.setOcclusogramBlend(value);
  };

  const isInDentalMovements = useMemo(() => location.pathname.includes(OrthodonticsPagesUrl.DentalMovements), []);

  const orthLayerItems: ToolsDropdownBaseItem[] = [
    {
      name: t('evergineTools.occlusogram'),
      isDisabled: !upperDentalMovements || !lowerDentalMovements,
      isVisible: toggleOcclusogram,
      withRangeSlider: true,
      rangeSliderValue: oclussionZonesLevel,
      onClick: onToggleOcclusogram,
      handleRangeSlider: onChangeOclussionZones,
      order: 1
    },
    {
      name: t('evergineTools.ipr'),
      isVisible: toggleIpr,
      onClick: onToggleIpr,
      isDisabled: (!upperDentalMovements && !lowerDentalMovements) || !checkIfIprsAreCalculated(),
      order: 2,
      defaultActive: isClient && isInDentalMovements
    },
    {
      name: t('evergineTools.interdentalDistances'),
      isVisible: toggleInterdentalDistances,
      onClick: onToggleInterdentalDistances,
      isDisabled: !upperDentalMovements || !lowerDentalMovements,
      order: 3,
      defaultActive: isClient && isInDentalMovements
    },
    {
      name: t('evergineTools.attaches'),
      isVisible: toggleAttachments,
      onClick: onToggleAttachments,
      isDisabled: !upperDentalMovements && !lowerDentalMovements,
      order: 4
    },
    {
      name: t('evergineTools.tads'),
      isVisible: toggleTADs,
      onClick: onToggleTADs,
      isDisabled: !upperDentalMovements && !lowerDentalMovements,
      order: 4
    },
    {
      name: t('evergineTools.roots'),
      isDisabled: !upperDentalMovements && !lowerDentalMovements,
      isVisible: toggleRoots,
      withRangeSlider: true,
      rangeSliderValue: opacityLevelRoots,
      onClick: onToggleRoots,
      handleRangeSlider: onChangeOpacityRoots,
      order: 5
    },
    {
      name: t('evergineTools.gum'),
      isDisabled: !upperDentalMovements && !lowerDentalMovements,
      isVisible: toggleGum,
      withRangeSlider: true,
      rangeSliderValue: opacityLevelGum,
      onClick: onToggleGum,
      handleRangeSlider: onChangeOpacityGum,
      order: 6
    },
    {
      name: t('evergineTools.teeth'),
      isDisabled: !upperDentalMovements && !lowerDentalMovements,
      isVisible: toggleTeeth,
      withRangeSlider: true,
      rangeSliderValue: opacityLevelTeeth,
      onClick: onToggleTeeth,
      handleRangeSlider: onChangeOpacityTeeth,
      order: 7
    }
  ];

  useEffect(() => {
    if (isClient && isInDentalMovements) {
      const defaultActiveIpr = true;
      setToggleIpr(defaultActiveIpr);
      setShowIPR(defaultActiveIpr);
      window.App.webEventsProxy.layers.showIprs(defaultActiveIpr);
    }
  }, [isClient, isInDentalMovements]);

  // ToDo: Apparently we will no longer be using labels in the app. Remove from everywhere.
  // if (canManageLabels) {
  //   orthLayerItems.push({
  //     name: t('evergineTools.labels'),
  //     isVisible: toggleLabels,
  //     onClick: onToggleLabels,
  //     isDisabled: !upperDentalMovements && !lowerDentalMovements,
  //     order: 6
  //   });
  // }

  return (
    <>
      <ToolsDropdownBase
        title={t('evergineTools.layers')}
        prefix="orthlayers"
        dataFor={dataFor}
        toggleIcon={Layers}
        className={className}
        handleToggleClick={handleClick}
        items={orthLayerItems}
      />
    </>
  );
}
