import { ReactComponent as Attach } from '../../../assets/icons/evergineTools/attach.svg';
import { ReactComponent as DownloadSTL } from '../../../assets/icons/evergineTools/download-stl.svg';
import { ReactComponent as Layers } from '../../../assets/icons/evergineTools/layers.svg';
import { ReactComponent as Panels } from '../../../assets/icons/evergineTools/panels.svg';
import { ReactComponent as Publish } from '../../../assets/icons/evergineTools/publish.svg';
import { ToolbarConfigProps } from '../../../common';
import { ModalTypes } from '../../../surgeries/components/layout/modal/modalTypes/modal-types.enum';
import { OrthLayers, OrthPanels } from './tools';

export const layersToolbarElement: ToolbarConfigProps = {
  icon: Layers,
  tooltipKey: 'evergineTools.layers',
  labelKey: 'evergineTools.layers',
  renderCustomComponent: true,
  customComponent: (props: any) => OrthLayers(props)
};

export const panelsToolbarElement: ToolbarConfigProps = {
  icon: Panels,
  tooltipKey: 'evergineTools.panels',
  labelKey: 'evergineTools.panels',
  renderCustomComponent: true,
  customComponent: (props: any) => OrthPanels(props)
};

export const publishCaseToolbarElement: ToolbarConfigProps = {
  icon: Publish,
  tooltipKey: 'evergineTools.publish',
  labelKey: 'evergineTools.publish',
  openModal: true,
  modalType: ModalTypes.ModalPublishCase,
  hiddenForClient: true
};

export const downloadCaseToolbarElement: ToolbarConfigProps = {
  icon: DownloadSTL,
  tooltipKey: 'evergineTools.downloadSTL',
  labelKey: 'evergineTools.downloadSTL',
  openModal: true,
  modalType: ModalTypes.ModalDownloadCase,
  hiddenForClient: true
};

export const teethLabelToolbarElement: ToolbarConfigProps = {
  icon: Attach,
  tooltipKey: 'evergineTools.label',
  handleClick: () => undefined
};
