import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ReactComponent as Check } from '../../../../../assets/icons/modal/check.svg';
import { INJECTED_TYPES, container } from '../../../../../common';
import { useCommonBoundStore } from '../../../../../common/stores/useStore';
import { useCaseId, useChat, useUserProfileData } from '../../../../../hooks';
import { useOrthBoundStore } from '../../../../../orthodontics/stores/useStore';
import { ClientPlanValidationEnum } from '../../../../../shared/clientPlanValidations/clientPlanValidation';
import { IClientPlanValidationService } from '../../../../../shared/clientPlanValidations/iclientPlanValidation.service';

export function ModalClientValidationsValidate() {
  const [t] = useTranslation();
  const { toggleModalIsOpened, setIsThereFeedbackForTreatment } = useCommonBoundStore((state) => ({
    toggleModalIsOpened: state.toggleModalIsOpened,
    setIsThereFeedbackForTreatment: state.setIsThereFeedbackForTreatment
  }));
  const [caseId] = useCaseId();
  const { sendChatMessage } = useChat(caseId);
  const [userProfile] = useUserProfileData();
  const currentVersion = useOrthBoundStore((state) => state.currentVersion);

  const onCloseButtonClick = () => {
    toggleModalIsOpened('');
  };

  const onContinueButtonClick = () => {
    const clientPlanValidationService = container.get<IClientPlanValidationService>(
      INJECTED_TYPES.IClientPlanValidationService
    );
    clientPlanValidationService
      .clientPlanValidation(caseId, currentVersion?.id, {
        clientId: userProfile?.id,
        result: ClientPlanValidationEnum.Approved,
        requestedMovements: [],
        isClientModification: false,
        treatmentChangeItems: []
      })
      .then(() => {
        setIsThereFeedbackForTreatment(true);
        sendChatMessage(ClientPlanValidationEnum.Approved);
        toast.success(t('modal.clientValidation.validate.accepted'));
      });
    toggleModalIsOpened('');
  };

  return (
    <div className="modal-content" data-testid={'modal-client-validations-validate'}>
      <div className="modal-body d-flex flex-column align-items-center">
        <Check className="modal-icon mb-3" />
        <h3 className="modal-title mb-4">{t('modal.clientValidation.validate.title')}</h3>
        <p className="modal-text text-center mb-0">{t('modal.clientValidation.validate.description', { caseId })}</p>
      </div>

      <div className="modal-footer d-flex flex-grow-1">
        <button
          onClick={onCloseButtonClick}
          type="button"
          className="btn btn-outline-primary flex-fill modal-button"
          data-bs-dismiss="modal"
        >
          {t(`modal.actions.close`)}
        </button>
        <button onClick={onContinueButtonClick} type="button" className="btn btn-primary flex-fill modal-button">
          {t(`modal.actions.approve`)}
        </button>
      </div>
    </div>
  );
}
