import { useCallback, useMemo, useState } from 'react';
import { Step } from '../../common';
import { editCasePhase } from '../../common/utils';
import { EvergineStageByPhase, PhaseByUrl } from '../../orthodontics/components/layout';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { CasePhase } from '../../shared/caseStatus';

export function useUtils() {
  const setUpperAndLowerDentalMovements = useOrthBoundStore((state) => state.setUpperAndLowerDentalMovements);
  const getMovementsFromEvergine = () => window.App.webEventsProxy.movements.getTreatmentMovements();
  const currentVersion = useOrthBoundStore((state) => state.currentVersion);
  const caseStatusFromStore = useOrthBoundStore((state) => state.caseStatus);
  const updateMovements = useCallback(() => {
    const { upperMovements, lowerMovements } = getMovementsFromEvergine();
    setUpperAndLowerDentalMovements(upperMovements, lowerMovements);
  }, [setUpperAndLowerDentalMovements]);

  const stepSorter = (prevStep: Step, nextStep: Step) => {
    return prevStep.stepIndex - nextStep.stepIndex;
  };

  const getCurrentPhaseStageByRoute = () => {
    let currentPath = location.pathname;
    currentPath = currentPath.split('/')[2];
    currentPath = PhaseByUrl[currentPath];
    return currentPath;
  };

  const getStageEvergineByCurrentPhase = () => {
    const currentPhase = getCurrentPhaseStageByRoute();
    return EvergineStageByPhase[currentPhase];
  };

  const uncompleteNextPhases = useCallback(async (): Promise<boolean> => {
    return new Promise((resolve) => {
      const currentPhase = getCurrentPhaseStageByRoute();
      if (!currentPhase) {
        return;
      }
      const currentPhaseIndex = caseStatusFromStore?.phases.findIndex((p) => p.name === currentPhase);
      if (currentPhaseIndex === -1) {
        return;
      }
      const nextPhases = caseStatusFromStore?.phases.slice(currentPhaseIndex + 1);
      const versionId = currentVersion.id.toString();
      const caseId = currentVersion.caseId;
      nextPhases?.map((p: CasePhase) => {
        if (p.completionDate !== null) {
          p.completionDate = null;
          p.editionDate = new Date();
          editCasePhase(caseId, versionId, p);
        }
      });
      resolve(true);
    });
  }, [caseStatusFromStore, getCurrentPhaseStageByRoute]);

  const fixFloat = (value: number) => parseFloat(value.toFixed(1));

  return {
    updateMovements,
    stepSorter,
    getCurrentPhaseStageByRoute,
    getStageEvergineByCurrentPhase,
    uncompleteNextPhases,
    fixFloat
  };
}
