import { MovementType } from '../../../../../common';
import { MeasureUnit } from '../../../../models';

export type TeethMovementsValue = {
  movementType: MovementType;
  value: number;
  measureUnitType?: MeasureUnit;
};

export const defaultMovementsValues: TeethMovementsValue[] = [
  { movementType: MovementType.ExtrusionIntrusion, value: null },
  { movementType: MovementType.TranslationVL, value: null },
  { movementType: MovementType.TranslationMD, value: null },
  { movementType: MovementType.PureRotation, value: null },
  { movementType: MovementType.MesialRotation, value: null },
  { movementType: MovementType.DistalRotation, value: null },
  { movementType: MovementType.Torque, value: null },
  { movementType: MovementType.TIP, value: null }
];

export const emptyDeltaValues: TeethMovementsValue[] = [
  { movementType: MovementType.ExtrusionIntrusion, value: null },
  { movementType: MovementType.TranslationVL, value: null },
  { movementType: MovementType.TranslationMD, value: null },
  { movementType: MovementType.PureRotation, value: null },
  { movementType: MovementType.MesialRotation, value: null },
  { movementType: MovementType.DistalRotation, value: null },
  { movementType: MovementType.Torque, value: null },
  { movementType: MovementType.TIP, value: null }
];

export const unitTypeForMovement: { [key: string]: MeasureUnit } = {
  [MovementType.ExtrusionIntrusion]: MeasureUnit.Millimeter,
  [MovementType.TranslationVL]: MeasureUnit.Millimeter,
  [MovementType.TranslationMD]: MeasureUnit.Millimeter,
  [MovementType.PureRotation]: MeasureUnit.Degrees,
  [MovementType.MesialRotation]: MeasureUnit.Degrees,
  [MovementType.DistalRotation]: MeasureUnit.Degrees,
  [MovementType.TIP]: MeasureUnit.Degrees,
  [MovementType.Torque]: MeasureUnit.Degrees
};

export const getMaxValue = (unitType: MeasureUnit): number => {
  return unitType === MeasureUnit.Millimeter ? 1000 : 360;
};

export const MM_STEP_NUMBER = 0.1;
export const DEGREES_STEP_NUMBER = 1;
export const VALID_NUMBER_REGEX = /^[+-]?\d+(\.\d+)?$/;
export const MOVEMENTS_DECIMALS = 2;
