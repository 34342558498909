import { useCallback, useEffect, useState } from 'react';
import { PointerPosition } from '../../../stores/slices/orthContextMenuSlice';
import { useOrthBoundStore } from '../../../stores/useStore';

export function useTeethContextMenu(onToggleAction: () => void, positionData?: PointerPosition) {
  const { toothHoverData, isToothHovered, selectedTeethId, setSelectedTeethId } = useOrthBoundStore((state) => ({
    toothHoverData: state.toothHoverData,
    isToothHovered: state.isToothHovered,
    selectedTeethId: state.selectedTeethId,
    setSelectedTeethId: state.setSelectedTeethId
  }));

  const selectedToothId = selectedTeethId[0];

  const [canTeethContextMenuBeShown, setCanTeethContextMenuBeShown] = useState<boolean>(false);

  useEffect(() => {
    if (canOpenToothContextMenu()) {
      setSelectedTeethId([toothHoverData.fdi]);
      return;
    }
    setSelectedTeethId([]);
  }, [positionData]);

  useEffect(() => {
    setCanTeethContextMenuBeShown(!!selectedToothId);
  }, [selectedToothId]);

  const canOpenToothContextMenu = useCallback(() => {
    return !!toothHoverData?.fdi && isToothHovered;
  }, [isToothHovered]);

  const removeTeethIfSelected = useCallback(() => {
    if (selectedToothId && selectedToothId > 0) {
      window.App.webEventsProxy.extractions.markToothAsExtracted(selectedToothId);
      setSelectedTeethId([]);
      onToggleAction();
    }
  }, [selectedToothId]);

  return {
    canTeethContextMenuBeShown,
    removeTeethIfSelected
  };
}
