import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Panels } from '../../../../assets/icons/evergineTools/panels.svg';
import { useOrthBoundStore } from '../../../stores/useStore';
import { ToolsDropdownBase } from './ToolsDropdownBase';

export function OrthPanels(props: any) {
  const { dataFor, handleClick, className } = props;
  const [t] = useTranslation();
  const {
    showBoltonPanel,
    setShowBoltonPanel,
    showMovementsTable,
    setShowMovementsTable,
    upperDentalMovements,
    lowerDentalMovements,
    showEvolutionPanel,
    setShowEvolutionPanel
  } = useOrthBoundStore();

  const [toggleEvolution, setToggleEvolution] = useState(false);
  const [toggleMovements, setToggleMovements] = useState(false);
  const [toggleBolton, setToggleBolton] = useState(false);

  const onToggleMovements = () => {
    setShowMovementsTable(!showMovementsTable);
  };

  const onToggleEvolution = () => {
    setShowEvolutionPanel(!toggleEvolution);
  };

  const onToggleBolton = () => {
    setShowBoltonPanel(!showBoltonPanel);
  };

  useEffect(() => {
    setToggleBolton(showBoltonPanel);
  }, [showBoltonPanel]);

  useEffect(() => {
    setToggleMovements(showMovementsTable);
  }, [showMovementsTable]);

  useEffect(() => {
    setToggleEvolution(showEvolutionPanel);
  }, [showEvolutionPanel]);

  return (
    <>
      <ToolsDropdownBase
        title={t('evergineTools.panels')}
        prefix="orthpanels"
        dataFor={dataFor}
        toggleIcon={Panels}
        className={className}
        handleToggleClick={handleClick}
        items={[
          {
            name: t('evergineTools.evolutionPanel'),
            isVisible: toggleEvolution,
            onClick: onToggleEvolution,
            isDisabled: !lowerDentalMovements && !upperDentalMovements,
            order: 1
          },
          {
            name: t('evergineTools.movementsTable'),
            isVisible: toggleMovements,
            onClick: onToggleMovements,
            isDisabled: !lowerDentalMovements && !upperDentalMovements,
            order: 2
          },
          {
            name: t('evergineTools.bolton'),
            id: 'bolton-panel-toggle',
            isVisible: toggleBolton,
            onClick: onToggleBolton,
            isDisabled: !lowerDentalMovements || !upperDentalMovements,
            order: 3
          }
        ]}
      />
    </>
  );
}
