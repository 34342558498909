import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { useOrthBoundStore } from '../../stores/useStore';
import { PointerPosition } from '../../stores/slices/orthContextMenuSlice';
import { EmptySceneContextMenu } from './EmptySceneContextMenu';
import { TeethContextMenu, useTeethContextMenu } from './teethContextMenu';
import { AttachesContextMenu, useAttachesContextMenu } from './attachesContextMenu';

export enum PagesWithSpecificOptions {
  Treatment,
  Attaches
}

type OrthContextualMenuProps = {
  preventContextMenu?: boolean;
  pageWithSpecificOptions?: PagesWithSpecificOptions;
};

export function OrthContextualMenu({ preventContextMenu = false, pageWithSpecificOptions }: OrthContextualMenuProps) {
  const { isOrthContextMenuVisible, pointerPosition, hoveredAttach, setIsOrthContextMenuVisible } = useOrthBoundStore(
    (state) => ({
      isOrthContextMenuVisible: state.isOrthContextMenuVisible,
      pointerPosition: state.pointerPosition,
      hoveredAttach: state.hoveredAttach,
      setIsOrthContextMenuVisible: state.setIsOrthContextMenuVisible
    })
  );
  const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
  const [selectedTeethPosition, setSelectedTeethPosition] = useState<PointerPosition>();

  const closeContextMenu = useCallback(() => {
    setIsOrthContextMenuVisible(false);
    setShowContextMenu(false);
  }, []);

  const { canTeethContextMenuBeShown, removeTeethIfSelected } = useTeethContextMenu(
    closeContextMenu,
    selectedTeethPosition
  );
  const { canAttachesMenuBeShown, hideAttachesMenu } = useAttachesContextMenu();

  useEffect(() => {
    if (preventContextMenu === true) {
      setShowContextMenu(false);
    }
  }, [preventContextMenu]);

  useEffect(() => {
    if (selectedTeethPosition !== pointerPosition) {
      setSelectedTeethPosition(pointerPosition);
    }
    setShowContextMenu(isOrthContextMenuVisible);
  }, [isOrthContextMenuVisible, pointerPosition]);

  const isTeethContextMenuShowable = useCallback(() => {
    return (
      canTeethContextMenuBeShown &&
      // provisional option until Attaches screen integration into Treatment
      (pageWithSpecificOptions === PagesWithSpecificOptions.Treatment ||
        pageWithSpecificOptions === PagesWithSpecificOptions.Attaches) &&
      hoveredAttach === null
    );
  }, [canTeethContextMenuBeShown, pageWithSpecificOptions, hoveredAttach]);

  const isAttacheContextMenuShowable = useCallback(() => {
    return canAttachesMenuBeShown && pageWithSpecificOptions === PagesWithSpecificOptions.Attaches;
  }, [canAttachesMenuBeShown, pageWithSpecificOptions]);

  const positionData: CSSProperties = useMemo(() => {
    return { left: selectedTeethPosition?.clientX, top: selectedTeethPosition?.clientY };
  }, [selectedTeethPosition]);

  if (isTeethContextMenuShowable()) {
    return (
      <TeethContextMenu
        onToggleAction={closeContextMenu}
        positionData={positionData}
        show={showContextMenu}
        teethPosition={selectedTeethPosition}
        // provisional option until Attaches screen integration into Treatment
        showTADSOption={pageWithSpecificOptions === PagesWithSpecificOptions.Attaches}
      />
    );
  }

  if (isAttacheContextMenuShowable()) {
    return (
      <AttachesContextMenu
        onClickOutside={hideAttachesMenu}
        onToggleAction={closeContextMenu}
        positionData={positionData}
        show={showContextMenu}
      />
    );
  }

  return <EmptySceneContextMenu onToggleAction={closeContextMenu} positionData={positionData} show={showContextMenu} />;
}
