import { useCallback } from 'react';
import { AsideConfigItem } from '../../surgeries/components/layout';
import { useSaveAndNavigateStep } from './useSaveAndNavigateStep';

export function useNavigateToPhase(caseId: string) {
  const { saveAndNavigateStep } = useSaveAndNavigateStep({ isDirectNavigation: true, checkPhaseChanges: true });

  const navigateToPhase = useCallback(
    async (phaseItem: AsideConfigItem) => {
      const route = phaseItem.route + `/${caseId}`;
      await saveAndNavigateStep(route);
    },
    [caseId, saveAndNavigateStep]
  );

  return { navigateToPhase };
}
