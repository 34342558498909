import { ArchsToTreat } from '../../../shared/cases';

export const getNumberofPiecesToReplace = (piecesToReplace: any) => {
  const numberOfPiecesToReplace: any = piecesToReplace?.map((pieceToReplace: any) => {
    return pieceToReplace.number;
  });

  return numberOfPiecesToReplace?.toString().replace(/,/g, ', ') || 0;
};

const archsDictionary: { [key: string]: string } = {
  [ArchsToTreat.Lower]: 'lower',
  [ArchsToTreat.Upper]: 'upper',
  [ArchsToTreat.Both]: 'both',
  [ArchsToTreat.ConditioningVeneers]: 'conditioningVeneers'
};

export const getArchKey = (key: ArchsToTreat) => {
  const translateKey = key ? `patientData.archs.${archsDictionary[key]}` : 'notDefined';

  return `generalInfo.${translateKey}`;
};
