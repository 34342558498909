import { PieceSelectedIndicatorData } from './pieceSelectedIndicator';

export const upperPiecesSelectedIndicators: PieceSelectedIndicatorData[] = [
  {
    pieceId: '11',
    cx: '151.14',
    cy: '68.14'
  },
  {
    pieceId: '12',
    cx: '126.14',
    cy: '74.14'
  },
  {
    pieceId: '13',
    cx: '110.14',
    cy: '87.14'
  },
  {
    pieceId: '14',
    cx: '94.14',
    cy: '110.14'
  },
  {
    pieceId: '15',
    cx: '87.14',
    cy: '132.14'
  },
  {
    pieceId: '16',
    cx: '79.14',
    cy: '169.14'
  },
  {
    pieceId: '17',
    cx: '68.14',
    cy: '210.14'
  },
  {
    pieceId: '18',
    cx: '66.14',
    cy: '250.14'
  },
  {
    pieceId: '21',
    cx: '177.14',
    cy: '68.14'
  },
  {
    pieceId: '22',
    cx: '202.14',
    cy: '74.14'
  },
  {
    pieceId: '23',
    cx: '218.14',
    cy: '87.14'
  },
  {
    pieceId: '24',
    cx: '234.14',
    cy: '110.14'
  },
  {
    pieceId: '25',
    cx: '241.14',
    cy: '132.14'
  },
  {
    pieceId: '26',
    cx: '249.14',
    cy: '169.14'
  },
  {
    pieceId: '27',
    cx: '258.14',
    cy: '210.14'
  },
  {
    pieceId: '28',
    cx: '260.14',
    cy: '250.14'
  }
];
