import { inject, injectable } from 'inversify';
import { INJECTED_TYPES } from '../../common/ioc/ioc.types';
import type { IHttpService } from '../http';
import type { FileToUploadDTO, IFileService } from '../file';
import { ITeethSegmentationService } from './iteethSegmentation.service';
import { SEGMENTATION_FILE_KEY, SegmentationFileDTO, SegmentationResponseDTO } from './teethSegmentation';
import { Segmentation } from '../../common';
import { blobToBase64 } from '../functionalities.helper';

@injectable()
export class TeethSegmentationService implements ITeethSegmentationService {
  @inject(INJECTED_TYPES.IHttpService) private _httpService: IHttpService;
  @inject(INJECTED_TYPES.IFileService) private _fileService: IFileService;

  private readonly apiEndpoint = '/ai/segmentation';

  public async getAITeethSegmentation(requestDto: SegmentationFileDTO): Promise<SegmentationResponseDTO> {
    const result = await this._httpService.post<SegmentationResponseDTO>(this.apiEndpoint, requestDto);
    return result.data;
  }

  public async getSegmentation(caseId: string, versionId: string): Promise<Segmentation> {
    try {
      const fileSegmentationInfo = await this._fileService.getFileInfo(caseId, versionId, SEGMENTATION_FILE_KEY);
      if (!fileSegmentationInfo?.url) {
        return Promise.reject();
      }

      const segmentation = (await this._httpService.get(`${fileSegmentationInfo.url}?t=${new Date().getTime()}`))
        .data as Segmentation;

      return segmentation;
    } catch (error) {
      console.error(error);
      return Promise.reject();
    }
  }

  public async updateSegmentation(caseId: string, versionId: string, segmentation: Segmentation): Promise<void> {
    const json = JSON.stringify(segmentation);
    const blob = new Blob([json], { type: 'text/plain;charset=utf-8' });
    const base64 = await blobToBase64(blob);

    const fileDto: FileToUploadDTO = {
      key: SEGMENTATION_FILE_KEY,
      mimeType: 'text/plain;charset=utf-8',
      originalName: `${SEGMENTATION_FILE_KEY}.json`,
      file: base64,
      totalSize: 0
    };

    try {
      await this._fileService.uploadFile(caseId, versionId, SEGMENTATION_FILE_KEY, fileDto);
    } catch (error) {
      console.error(error);
      return Promise.reject();
    }
  }
}
