import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Close } from '../../../assets/icons/close.svg';
import { ReactComponent as Details } from '../../../assets/icons/details.svg';
import { MovementsInfo } from './MovementsInfo';
import { MovementsTable } from './movementsTable/MovementsTable';

import { useEffect, useRef, useState } from 'react';
import { MovementCalculationType, SourceOfMovement } from '../../../common';
import { Shift } from '../dentalMovements';
import './movementspanel.scss';
import { CustomDropdown, DropdownItem } from '../../../surgeries/components/layout/custom-dropdown/CustomDropdown';

interface MovementsPanelProps {
  opened: boolean;
  shiftMode?: Shift;
  evolutionPanelWidth?: number | undefined;
  onClickClose: () => void;
}

type CSSProperties = {
  [key: string]: string | number;
};

export function MovementsPanel({ opened, shiftMode: shift, evolutionPanelWidth, onClickClose }: MovementsPanelProps) {
  const [t] = useTranslation();

  const [selectedMovementKind, setSelectedMovementKind] = useState<MovementCalculationType>(
    MovementCalculationType.Total
  );

  const dropdownItems: DropdownItem[] = [
    { id: MovementCalculationType.Total.toString(), text: t('movementsPanel.valueSelector.total'), isSelected: true },
    {
      id: MovementCalculationType.Accumulated.toString(),
      text: t('movementsPanel.valueSelector.accumulated'),
      isSelected: false
    },
    { id: MovementCalculationType.Step.toString(), text: t('movementsPanel.valueSelector.step'), isSelected: false }
  ];

  const classes = classNames('movementspanel', {
    opened,
    shift: shift !== Shift.None,
    ['shift-1--evolution']: shift === Shift.EvolutionPanelOpened,
    ['shift-1--info']: shift === Shift.InfoPanelOpened,
    ['shift-2']: shift === Shift.EvolutionAndInfoPanelsOpened
  });

  const customStyles: CSSProperties = {
    '--evolution-panel-width': `${evolutionPanelWidth}px`
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMovementKind(Number(event.target.value) as MovementCalculationType);
  };

  return (
    <div className={`${classes} no-select`} style={customStyles}>
      <div className="movementspanel-tools">
        <div className="movementspanel-top">
          <div>
            <Details /> <span className="movementspanel-top-title">{t('movementsPanel.title')}</span>
          </div>
          <div>
            <MovementsInfo />

            <Close className="clickable" onClick={onClickClose} />
          </div>
        </div>
        <div className="movementspanel-tabs">
          <div className="movementspanel-tabs-kind">
            <label htmlFor="movementKind">{t('movementsPanel.valueSelector.label')}</label>
            <CustomDropdown
              items={dropdownItems}
              customClass="movement-customdropdown"
              onClick={(selectedItem) => setSelectedMovementKind(Number(selectedItem) as MovementCalculationType)}
              withBorder={true}
              stretch={true}
            ></CustomDropdown>
          </div>
        </div>
      </div>
      {opened && <MovementsTable calculationType={selectedMovementKind} />}
    </div>
  );
}
