import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { INJECTED_TYPES, ModalReject, container } from '../../../../../common';
import { useCommonBoundStore } from '../../../../../common/stores/useStore';
import { useCaseId, useChat, useUserProfileData } from '../../../../../hooks';
import { useOrthBoundStore } from '../../../../../orthodontics/stores/useStore';
import { ClientPlanValidationEnum } from '../../../../../shared/clientPlanValidations/clientPlanValidation';
import { IClientPlanValidationService } from '../../../../../shared/clientPlanValidations/iclientPlanValidation.service';

export function ModalClientValidationsReject() {
  const [t] = useTranslation();
  const { toggleModalIsOpened, setIsThereFeedbackForTreatment } = useCommonBoundStore((state) => ({
    toggleModalIsOpened: state.toggleModalIsOpened,
    setIsThereFeedbackForTreatment: state.setIsThereFeedbackForTreatment
  }));
  const currentVersion = useOrthBoundStore((state) => state.currentVersion);
  const [caseId] = useCaseId();
  const { sendChatMessage } = useChat(caseId);
  const [userProfile] = useUserProfileData();

  const onCloseButtonClick = () => {
    toggleModalIsOpened('');
  };

  const onContinueButtonClick = () => {
    const clientPlanValidationService = container.get<IClientPlanValidationService>(
      INJECTED_TYPES.IClientPlanValidationService
    );
    clientPlanValidationService
      .clientPlanValidation(caseId, currentVersion?.id, {
        clientId: userProfile?.id,
        result: ClientPlanValidationEnum.Rejected,
        requestedMovements: [],
        isClientModification: false,
        treatmentChangeItems: []
      })
      .then(() => {
        setIsThereFeedbackForTreatment(true);
        sendChatMessage(ClientPlanValidationEnum.Rejected);
        toast.success(t('modal.clientValidation.reject.rejected'));
      });

    toggleModalIsOpened('');
  };

  return (
    <ModalReject
      title={t('modal.clientValidation.reject.title')}
      message={t('modal.clientValidation.reject.description', { caseId })}
      onClickClose={onCloseButtonClick}
      onClickContinue={onContinueButtonClick}
      yesText={t(`modal.actions.refuse`)}
      noText={t(`modal.actions.close`)}
    />
  );
}
