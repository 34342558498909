import { useCallback } from 'react';
import { useOrthBoundStore } from '../../../orthodontics/stores/useStore';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { SaveChangesHook } from '../../shared';
import { useSaveProgress } from '../useSaveProgress';
import { OrthodonticsOrderedPhasesKeys } from '../../../orthodontics/components/layout';

export function useSaveTreatment(): SaveChangesHook {
  const setWebBusy = useBoundStore((state) => state.setWebBusy);
  const { setUpperAndLowerDentalMovements } = useOrthBoundStore((state) => ({
    setUpperAndLowerDentalMovements: state.setUpperAndLowerDentalMovements
  }));
  const { saveProgressInBackend } = useSaveProgress();

  // TODO revisar con Rafa que esto es lo que se debe seguir haciendo al pasar de fase
  // posteriormente se irán añadiendo operaciones conforme vayamos reunificando fases, aquí o mediante composición con otros hooks
  const saveChanges = useCallback(async (): Promise<boolean> => {
    setWebBusy(true);

    const { upperMovements, lowerMovements } = window.App.webEventsProxy.movements.getTreatmentMovements();
    setUpperAndLowerDentalMovements(upperMovements, lowerMovements);

    await saveProgressInBackend(
      { lower: lowerMovements, upper: upperMovements },
      OrthodonticsOrderedPhasesKeys.Treatment
    );

    setWebBusy(false);
    return Promise.resolve(true);
  }, [setUpperAndLowerDentalMovements, saveProgressInBackend]);

  return { saveChanges };
}
