import { useState } from 'react';
import { STORE_LANG_KEY, i18n } from '../../../../../app.i18n';

export function useConfigLanguage() {
  const [localCurrentLanguage, setLocalCurrentLanguage] = useState<string>(i18n.language);

  const changeLocalLanguage = (language: string) => {
    setLocalCurrentLanguage(language);
  };

  const saveLanguageConfiguration = () => {
    i18n.changeLanguage(localCurrentLanguage);
    localStorage.setItem(STORE_LANG_KEY, localCurrentLanguage);
  };

  return {
    localCurrentLanguage,
    changeLocalLanguage,
    saveLanguageConfiguration
  };
}
