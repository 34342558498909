import { StateCreator } from 'zustand';

export interface UndoRedoSlice {
  canUndo: boolean;
  canRedo: boolean;
  setCanUndo: (newCanUndo: boolean) => void;
  setCanRedo: (newCanRedo: boolean) => void;
  numberOfUndo: number;
  setNumberOfUndo: (newNumberOfUndo: number) => void;
  isUndoReverse: boolean;
  setUndoReverse: (newIsUndoReverse: boolean) => void;
}

export const createUndoRedoSlice: StateCreator<UndoRedoSlice, [], [], UndoRedoSlice> = (set) => ({
  canUndo: false,
  canRedo: false,
  setCanUndo: (newCanUndo: boolean) => set(() => ({ canUndo: newCanUndo })),
  setCanRedo: (newCanRedo: boolean) => set(() => ({ canRedo: newCanRedo })),
  numberOfUndo: 1,
  setNumberOfUndo: (newNumberOfUndo: number) => set(() => ({ numberOfUndo: newNumberOfUndo })),
  isUndoReverse: false,
  setUndoReverse: (newIsUndoReverse: boolean) => set(() => ({ isUndoReverse: newIsUndoReverse }))
});
