import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Info } from '../../../../../assets/icons/modal/info.svg';
import { useCommonBoundStore } from '../../../../../common/stores/useStore';
import { editCasePhase } from '../../../../../common/utils';
import { useGetDentalMovements } from '../../../../../hooks';
import { OrthodonticsOrderedPhasesKeys } from '../../../../../orthodontics/components/layout';
import { useOrthBoundStore } from '../../../../../orthodontics/stores/useStore';
import { CaseVersionState } from '../../../../../shared';
import { CasePhase } from '../../../../../shared/caseStatus';
import { useBoundStore } from '../../../../stores/useStore';

export function ModalPublishCase() {
  const [t] = useTranslation();
  const toggleModalIsOpened = useCommonBoundStore((state) => state.toggleModalIsOpened);
  const { currentVersion, isCasePublished, setIsCasePublished } = useOrthBoundStore((state) => ({
    currentVersion: state.currentVersion,
    isCasePublished: state.isCasePublished,
    setIsCasePublished: state.setIsCasePublished
  }));
  const settings = useCommonBoundStore((state) => state.settings);
  const { updateTreatmentState } = useGetDentalMovements();
  const setWebBusy = useBoundStore((state) => state.setWebBusy);

  const redirectToPlatformWithPublishedCase = useCallback(
    (caseId: string) => {
      const redirectionURLTemplate = settings.redirectionAfterPublishSettings.urlTemplate;
      const placeholder = settings.redirectionAfterPublishSettings.placeholder;

      const redirectionURL = redirectionURLTemplate.replace(placeholder, caseId);
      window.location.href = redirectionURL;
    },
    [settings]
  );

  const onCloseButtonClick = () => {
    toggleModalIsOpened('');
  };

  const onContinueButtonClick = useCallback(async () => {
    if (!currentVersion) {
      return;
    }

    toggleModalIsOpened('');
    setWebBusy(true);
    await updateTreatmentState(currentVersion.caseId, currentVersion, CaseVersionState.published);
    await updatePhaseState();
    setIsCasePublished(true);
    setWebBusy(false);

    redirectToPlatformWithPublishedCase(currentVersion.caseId);
  }, [currentVersion, redirectToPlatformWithPublishedCase]);

  const updatePhaseState = useCallback(async () => {
    const buildPhase: CasePhase = {
      id: OrthodonticsOrderedPhasesKeys.Publish,
      name: OrthodonticsOrderedPhasesKeys.Publish,
      completionDate: new Date(),
      editionDate: new Date()
    };
    const versionId = currentVersion.id.toString();
    const caseId = currentVersion.caseId;

    await editCasePhase(caseId, versionId, buildPhase);
  }, [currentVersion, isCasePublished]);

  return (
    <div className="modal-content" data-testid={'modal-publishCase'}>
      <div className="modal-body d-flex flex-column align-items-center">
        <Info className="modal-icon mb-3" />
        <h3 className="modal-title mb-4">{t(`modal.publishCase.title`)}</h3>
        <p className="modal-text text-center mb-0">{t(`modal.publishCase.description`)}</p>
      </div>

      <div className="modal-footer d-flex flex-grow-1">
        <button
          onClick={onCloseButtonClick}
          type="button"
          className="btn btn-outline-primary flex-fill modal-button"
          data-bs-dismiss="modal"
        >
          {t(`modal.actions.cancel`)}
        </button>
        <button onClick={onContinueButtonClick} type="button" className="btn btn-primary flex-fill modal-button">
          {t(`modal.actions.publish`)}
        </button>
      </div>
    </div>
  );
}
