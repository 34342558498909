import { Backdrop, Box } from '@mui/material';
import { useEvergineStore } from 'evergine-react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  InterdentalDistance,
  PagesWithTools,
  Spinner,
  Split,
  Stage,
  Step,
  Timeline,
  ToothMovement,
  VerticalDivider
} from '../../../common';
import { ClientValidation } from '../../../common/components/clientValidation';
import {
  ApplyIprState,
  Attachment,
  AttachmentState,
  DentalMovementPair,
  IprLabel,
  Tooth
} from '../../../common/evergine';
import { useCommonBoundStore } from '../../../common/stores/useStore';
import { useCaseData, useCaseId, useCaseStatus, useGateKeeper, useRenderModels, useWindowSize } from '../../../hooks';
import {
  AbilityAction,
  AbilityContext,
  CaseVersionState,
  Constants,
  MinorVersion,
  OrthoAbilitySubject,
  PROFILES_TYPE
} from '../../../shared';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { useOrthBoundStore } from '../../stores/useStore';
import { IPRButtons } from '../buttonsIPR';
import { EvolutionAttachment, EvolutionInterdentalDistance, EvolutionStep, EvolutionTooth } from '../evolutionPanel';
import { OcclusogramLegend } from '../occlusogramLegend';
import { FooterDental } from './footer/footerDental';

import { useDentalMovements } from '../../../hooks/orthodontics';
import { useUtils } from '../../../hooks/shared/useUtils';

import './dentalMovements.scss';
import { GeneralPanels } from '../../layout/general-panels';
import { useMovementTable } from '../../../hooks/shared/useMovementTable';

export enum Shift {
  None,
  EvolutionPanelOpened,
  InfoPanelOpened,
  EvolutionAndInfoPanelsOpened
}

export function DentalMovements(props: any) {
  const [t] = useTranslation();
  const [caseId] = useCaseId();
  const { fetchCaseStatus } = useCaseStatus(caseId);
  const params = new URLSearchParams(window.location.search);
  const isLegacyFromParam = params.get('legacy');

  if (!isLegacyFromParam || isLegacyFromParam !== 'true' || props.isDesigner) {
    useRenderModels(caseId, Stage.Publish);
  }

  const navigate = useNavigate();
  useGateKeeper(AbilityAction.View, OrthoAbilitySubject.TreatmentViewerScreen, () => navigate('/forbidden'));
  const ability = useContext(AbilityContext);

  const {
    upperDentalMovements,
    lowerDentalMovements,
    isCompareTreatmentsActivated,
    showMovementsTable,
    showMiniOcclusogram,
    setShowMovements,
    isCasePublished,
    isVersionLoaded
  } = useOrthBoundStore();
  const { splitMode, setSplitMode, userProfile, isConfirmed, setIsConfirmed, setCanBeConfirmed, setWebBusy } =
    useBoundStore();
  const { versionToCompare, currentVersion, showIPR, stageIsLoaded } = useOrthBoundStore();
  const { activeStep, setActiveStep, isNewStepClientModification, isEditStep } = useCommonBoundStore();
  const { evergineReady } = useEvergineStore();
  const [showBackdrop] = useState(false);
  const [isClientModifiyng, setIsClientModifiyng] = useState(false);
  const [isVerticalComparingViewport, setIsVerticalComparingViewport] = useState(false);
  const [isChildVerticalComparingState, setIsChildVerticalComparingState] = useState(false);
  const [hasMovementsInDB, setHasMovementsInDB] = useState(false);
  const [dentalMovementsSteps, setDentalMovementsSteps] = useState<EvolutionStep[]>([]);
  const [dentalMovementsApplyIPRList, setDentalMovementsApplyIPRList] = useState<IprLabel[]>([]);
  const { stepSorter } = useUtils();
  const { getMovementsTable, getRelativeDentalMovementsByStep } = useMovementTable();

  const size = useWindowSize();

  const { loadLegacyModels } = useDentalMovements(caseId);
  const { getMinorVersions } = useCaseData(caseId);
  const [minorVersions, setMinorVersions] = useState<MinorVersion[]>();

  const maxStoreStepsLength = useMemo(
    () => Math.max(upperDentalMovements?.steps?.length || 0, lowerDentalMovements?.steps?.length || 0),
    [upperDentalMovements, lowerDentalMovements]
  );
  const isCompareAvailable = useMemo(
    () => isCompareTreatmentsActivated && versionToCompare !== null,
    [isCompareTreatmentsActivated, versionToCompare]
  );

  const isClient = useBoundStore.getState().userProfile?.type === PROFILES_TYPE.client;

  useEffect(() => {
    if (window.testE2E === true) {
      return;
    }

    setWebBusy(true);
  }, []);

  useEffect(() => {
    if (isVersionLoaded === false) {
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const stateParam = urlParams.get('disableload');
    const isLegacyParam = urlParams.get('legacy');

    if (!isCasePublished && isClient && isLegacyParam !== 'true') {
      navigate('/forbidden');
    }
  }, [isVersionLoaded]);

  useEffect(() => {
    fetchCaseStatus();
  }, [isVersionLoaded, isCasePublished]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const stateParam = urlParams.get('disableload');
    const isLegacyParam = urlParams.get('legacy');

    if (!currentVersion || !evergineReady) {
      return;
    }

    if (stateParam === 'true' || isLegacyParam !== 'true') {
      return;
    }

    loadLegacyModels(currentVersion.id);
  }, [currentVersion, evergineReady]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const isLegacyParam = urlParams.get('legacy');

    if (isLegacyParam !== 'true') {
      return;
    }

    const setMovements = async () => {
      if (lowerDentalMovements !== null) {
        await window.App.webEventsProxy.movements.setMovements(lowerDentalMovements, false);
        if (isCompareAvailable) {
          await window.App.webEventsProxy.movements.setMovements(lowerDentalMovements, true);
        }
      }
    };
    setMovements().catch(console.error);
  }, [lowerDentalMovements]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const isLegacyParam = urlParams.get('legacy');

    if (isLegacyParam !== 'true') {
      return;
    }

    const setMovements = async () => {
      if (upperDentalMovements !== null) {
        await window.App.webEventsProxy.movements.setMovements(upperDentalMovements, false);
        if (isCompareAvailable) {
          await window.App.webEventsProxy.movements.setMovements(upperDentalMovements, true);
        }
      }
    };
    setMovements().catch(console.error);
  }, [upperDentalMovements]);

  useEffect(() => {
    if ((!upperDentalMovements && !lowerDentalMovements) || !evergineReady || !stageIsLoaded) {
      return;
    }

    const fetchData = async () => {
      const allTeeth = [...(lowerDentalMovements?.teeth ?? []), ...(upperDentalMovements?.teeth ?? [])];
      const allIprLabels = [...(lowerDentalMovements?.iprLabels ?? []), ...(upperDentalMovements?.iprLabels ?? [])];
      const groupedSteps = groupObjectsByStepIndex(lowerDentalMovements?.steps, upperDentalMovements?.steps);
      const movementTable = await getMovementsTable();
      const result: EvolutionStep[] = groupedSteps.map((step: Step) => {
        const isUpperPassiveAligner =
          step.stepIndex !== 0 &&
          !upperDentalMovements?.steps
            .find((s) => s.stepIndex === step.stepIndex)
            ?.toothMovements?.some((tm) => tm.movedInCurrentStep);

        const isLowerPassiveAligner =
          step.stepIndex !== 0 &&
          !lowerDentalMovements?.steps
            .find((s) => s.stepIndex === step.stepIndex)
            ?.toothMovements?.some((tm) => tm.movedInCurrentStep);
        const relativeDentalMovement = getRelativeDentalMovementsByStep(step.stepIndex, movementTable);

        return {
          index: step.stepIndex,
          teeth: calculateTeeth(step, allTeeth, isUpperPassiveAligner, isLowerPassiveAligner),
          attachments: calculateAttachments(step, allTeeth),
          interdentalDistances: calculateInterdentalDistances(step, allTeeth, allIprLabels),
          relativeDentalMovement: relativeDentalMovement
        };
      });

      const iprLabelsListToSave = [
        ...(lowerDentalMovements?.iprLabels || []),
        ...(upperDentalMovements?.iprLabels || [])
      ];

      setDentalMovementsSteps(result);
      setDentalMovementsApplyIPRList(iprLabelsListToSave);
    };

    fetchData();
  }, [lowerDentalMovements, upperDentalMovements, evergineReady, stageIsLoaded]);

  function calculateTeeth(
    step: Step,
    allTeeth: Tooth[],
    isUpperPassiveAligner: boolean,
    isLowerPassiveAligner: boolean
  ): EvolutionTooth[] {
    return step.toothMovements.map((toothMovement: ToothMovement) => {
      const tooth = allTeeth.find((tooth: Tooth) => tooth.id === toothMovement.toothId);
      return {
        fdi: tooth?.fdi,
        isExtracted: tooth?.extractedInStep?.valueOf() <= step.stepIndex || false,
        isMoved: toothMovement.movedInCurrentStep,
        isPassiveAligner: tooth?.fdi < 30 ? isUpperPassiveAligner : isLowerPassiveAligner
      };
    });
  }

  function calculateAttachments(step: Step, allTeeth: Tooth[]): EvolutionAttachment[] {
    const allAttachments = [...(lowerDentalMovements?.attachments ?? []), ...(upperDentalMovements?.attachments ?? [])];

    return Object.values(
      allAttachments
        .map(
          (attachment: Attachment): EvolutionAttachment => ({
            toothFdi: allTeeth.find((tooth: Tooth) => tooth.id === attachment.toothId)?.fdi,
            state: calculateAttachmentStateForStep(attachment, step)
          })
        )
        .reduce((acc: any, { toothFdi, state }) => {
          if (acc[toothFdi]) {
            acc[toothFdi].state = mergeAttachmentState(acc[toothFdi].state, state);
          } else {
            acc[toothFdi] = { toothFdi, state };
          }
          return acc;
        }, {})
    );
  }

  function calculateAttachmentStateForStep(attachment: Attachment, step: Step) {
    if (attachment.firstStep === step.stepIndex) {
      if (
        attachment.lastStep === step.stepIndex ||
        (attachment.lastStep === Constants.maxLastStepForAttachments && step.stepIndex === maxStoreStepsLength - 1)
      ) {
        return AttachmentState.Both;
      } else {
        return AttachmentState.Start;
      }
    } else if (
      attachment.lastStep === step.stepIndex ||
      (attachment.lastStep === Constants.maxLastStepForAttachments && step.stepIndex === maxStoreStepsLength - 1)
    ) {
      return AttachmentState.End;
    } else {
      return AttachmentState.None;
    }
  }

  function mergeAttachmentState(state1: AttachmentState, state2: AttachmentState): AttachmentState {
    if (state1 === AttachmentState.None) return state2;
    if (state2 === AttachmentState.None) return state1;
    if (state1 === state2) return state1;
    return AttachmentState.Both;
  }

  function calculateInterdentalDistances(
    step: Step,
    allTeeth: Tooth[],
    allIprLabels: IprLabel[]
  ): EvolutionInterdentalDistance[] {
    return step.interdentalDistances.map((interdentalDistance: InterdentalDistance) => {
      const leftTooth = allTeeth.find((tooth: Tooth) => tooth.id === interdentalDistance.leftToothId);
      const rightTooth = allTeeth.find((tooth: Tooth) => tooth.id === interdentalDistance.rightToothId);
      const iprLabel = allIprLabels?.find((l) => l.fdiLeft === leftTooth.fdi);
      const applyIprStepIndex = iprLabel?.applyStepIndex;
      const applyIprState = calculateApplyIprState(applyIprStepIndex, step.stepIndex);
      return {
        leftToothFdi: leftTooth.fdi,
        rightToothFdi: rightTooth.fdi,
        distance: applyIprState !== ApplyIprState.None ? iprLabel.value : interdentalDistance.distance,
        applyIprState: applyIprState,
        isSpaceVisible: applyIprState === ApplyIprState.None && interdentalDistance.distance > 0
      };
    });
  }

  function calculateApplyIprState(applyIprStepIndex: number | undefined, stepIndex: number): ApplyIprState {
    if (applyIprStepIndex === undefined) {
      return ApplyIprState.None;
    } else if (applyIprStepIndex === stepIndex) {
      return ApplyIprState.ApplyIpr;
    } else if (stepIndex < applyIprStepIndex) {
      return ApplyIprState.PreIpr;
    } else {
      return ApplyIprState.PostIpr;
    }
  }

  useEffect(() => {
    if (!isCompareAvailable) {
      return;
    }
    const isVertical =
      showMovementsTable || bestLayout(size.width, size.height - Constants.canvasHeightOffset) === Split.Split2H;

    setIsVerticalComparingViewport(isVertical);
  }, [showMovementsTable, isCompareAvailable, size]);

  useEffect(() => {
    if (!getMinorVersions || !currentVersion || (!upperDentalMovements && !lowerDentalMovements)) {
      return;
    }

    const fetchMinorVersions = async () => {
      const fetchedMinorVersions = await getMinorVersions(
        currentVersion.id,
        (upperDentalMovements?.steps.length || 0) + (lowerDentalMovements?.steps.length || 0)
      );
      setMinorVersions(fetchedMinorVersions);
    };

    fetchMinorVersions();
  }, [getMinorVersions, currentVersion, upperDentalMovements, lowerDentalMovements]);

  useEffect(() => {
    if (evergineReady) {
      if (!props.isDesigner) {
        window.App.webEventsProxy.common.setStage(Stage.Client);
      }
      window.App.webEventsProxy.layers.showAxis(false);
    }
  }, [evergineReady]);

  useEffect(() => {
    if (evergineReady && (upperDentalMovements !== null || lowerDentalMovements !== null)) {
      const layout = isCompareAvailable ? Split.Split2 : Split.Single;
      const viewPortMode = isVerticalComparingViewport ? Split.Split2H : layout;
      setIsChildVerticalComparingState(isVerticalComparingViewport);
      setSplitMode(viewPortMode);
      if (!props.isDesigner) {
        // TODO: revisar esto especialmente, y el useEffect entero cuando se aborde la comparación de tratamientos
        window.App.webEventsProxy.common.setStage(Stage.Client);
      }
    }
  }, [
    evergineReady,
    isCompareAvailable,
    lowerDentalMovements,
    upperDentalMovements,
    isVerticalComparingViewport,
    versionToCompare
  ]);

  useEffect(() => {
    if (!isNewStepClientModification || (!upperDentalMovements && !lowerDentalMovements) || isClientModifiyng) {
      setIsClientModifiyng(false);
      if (!!upperDentalMovements !== false) {
        upperDentalMovements.steps = removeLastStepOnClientModificationCancelled(upperDentalMovements.steps);
      }

      if (!!lowerDentalMovements !== false) {
        lowerDentalMovements.steps = removeLastStepOnClientModificationCancelled(lowerDentalMovements.steps);
      }

      if (window.App?.webEventsProxy && maxStoreStepsLength) {
        const actualStep = maxStoreStepsLength - 1;
        window.App.webEventsProxy.movements.goToStep(actualStep, false);
        setActiveStep(actualStep);
      }

      return;
    }

    const dentalMovementsPair: DentalMovementPair = {
      upperDentalMovements: null,
      lowerDentalMovements: null
    };

    if (!!upperDentalMovements !== false) {
      upperDentalMovements.steps = addNewStepOnClientModification(upperDentalMovements.steps);
      dentalMovementsPair.upperDentalMovements = upperDentalMovements;
    }

    if (!!lowerDentalMovements !== false) {
      lowerDentalMovements.steps = addNewStepOnClientModification(lowerDentalMovements.steps);
      dentalMovementsPair.lowerDentalMovements = lowerDentalMovements;
    }

    window.App.webEventsProxy.movements.updateMovements(dentalMovementsPair);

    window.App.webEventsProxy.movements.goToStep(maxStoreStepsLength, false);
    setActiveStep(maxStoreStepsLength);
    setIsClientModifiyng(true);

    if (userProfile?.type === PROFILES_TYPE.client) {
      setShowMovements(true);
    }
  }, [isNewStepClientModification]);

  useEffect(() => {
    if (!isClientModifiyng || (!upperDentalMovements && !lowerDentalMovements)) {
      return;
    }

    setActiveStep(maxStoreStepsLength);
  }, [isClientModifiyng, maxStoreStepsLength, upperDentalMovements, lowerDentalMovements]);

  useEffect(() => {
    if (!upperDentalMovements && !lowerDentalMovements) {
      setCanBeConfirmed(false);
      return;
    }
    setCanBeConfirmed(true);
  }, [lowerDentalMovements, upperDentalMovements]);

  const addNewStepOnClientModification = (list: Step[]) => {
    if (!!list === false) {
      return;
    }

    const lastStep = list.length - 1;

    const duplicatedItem = { ...list[lastStep] };
    duplicatedItem.isClientModification = true;
    duplicatedItem.stepIndex += 1;

    const newList = [...list, duplicatedItem];
    return newList;
  };

  const removeLastStepOnClientModificationCancelled = (list: Step[]): Step[] => {
    if (!!list === false) {
      return list;
    }

    const lastStep = list.length - 1;

    if (list[lastStep].isClientModification !== true) {
      return list;
    }

    const newList = [...list.slice(0, -1)];
    return newList;
  };

  const showClientValidationIfAllowed = useCallback(() => {
    if (!ability || ability.can(AbilityAction.Manage, OrthoAbilitySubject.TreatmentValidation)) {
      return (
        <ClientValidation
          isInEditionMode={isNewStepClientModification}
          isCaseBlocked={!currentVersion?.permissions?.acceptance || false}
        />
      );
    }
  }, [isNewStepClientModification, ability, currentVersion]);

  const groupObjectsByStepIndex = (lowerSteps: Step[], upperSteps: Step[]) => {
    const groupedObjects: { [key: number]: Step } = {};

    [...(lowerSteps ?? []), ...(upperSteps ?? [])].forEach((obj) => {
      const { stepIndex, toothMovements, interdentalDistances, isKeyStep } = obj;
      if (!groupedObjects[stepIndex]) {
        groupedObjects[stepIndex] = {
          stepIndex,
          toothMovements: [],
          interdentalDistances: [],
          jawTransform: [],
          isKeyStep: false
        };
      }

      if (Array.isArray(toothMovements)) {
        groupedObjects[stepIndex].toothMovements = groupedObjects[stepIndex].toothMovements.concat(toothMovements);
      }

      if (Array.isArray(interdentalDistances)) {
        groupedObjects[stepIndex].interdentalDistances =
          groupedObjects[stepIndex].interdentalDistances.concat(interdentalDistances);
      }

      groupedObjects[stepIndex].isKeyStep = groupedObjects[stepIndex].isKeyStep || isKeyStep;
    });

    return Object.values(groupedObjects);
  };

  const showTimeLine = () => {
    if ((upperDentalMovements || lowerDentalMovements) && !isNewStepClientModification) {
      return true;
    }
    return false;
  };

  return (
    <div className="captures container-fluid g-0 d-flex">
      <GeneralPanels pageWithTools={PagesWithTools.DentalMovements}>
        <>
          {isNewStepClientModification && (
            <div className="dental-movements__info-bar dental-movements__info-bar--edition no-select">
              <span> {t('clientValidations.editionMode')} </span>
            </div>
          )}
          {isCasePublished && !isClient && (
            <div className="dental-movements__info-bar dental-movements__info-bar--published no-select">
              <span> {t('clientValidations.publishedCase')} </span>
            </div>
          )}
          {showMiniOcclusogram && isCompareAvailable && (
            <OcclusogramLegend comparerActivated isVerticalComparer={isChildVerticalComparingState} />
          )}
          {showIPR && !isNewStepClientModification && <IPRButtons />}
          {showClientValidationIfAllowed()}
          <Backdrop style={{ zIndex: 9999 }} open={showBackdrop}>
            <Box sx={{ display: 'block', textAlign: 'center' }}>
              <Spinner />
            </Box>
          </Backdrop>

          <div className={`flex-fill captures-item captures-item-border`}></div>
          {isNewStepClientModification && maxStoreStepsLength && <FooterDental stepsLenght={maxStoreStepsLength} />}
          {splitMode === Split.Split2 && isVerticalComparingViewport && <VerticalDivider />}
        </>
      </GeneralPanels>
      {showTimeLine() && (
        <Timeline
          upperSteps={upperDentalMovements?.steps?.sort(stepSorter)}
          lowerSteps={lowerDentalMovements?.steps?.sort(stepSorter)}
          upperTeeth={upperDentalMovements?.teeth}
          lowerTeeth={lowerDentalMovements?.teeth}
          upperAttachments={upperDentalMovements?.attachments}
          lowerAttachments={lowerDentalMovements?.attachments}
          upperIprLabels={upperDentalMovements?.iprLabels}
          lowerIprLabels={lowerDentalMovements?.iprLabels}
          comparerActivated={isCompareAvailable}
          vertical={splitMode === Split.Split2H}
          versionName={currentVersion?.name}
          storeCurrentStep={activeStep}
          updateStoreCurrentStep={(newValue: number) => setActiveStep(newValue)}
          enableFollowUpOverposition={currentVersion?.result === CaseVersionState.accepted && !!minorVersions}
          minorVersions={minorVersions}
          isDesigner={!isClient}
        />
      )}
      {isCompareAvailable && (
        <Timeline
          upperSteps={upperDentalMovements?.steps?.sort(stepSorter)}
          lowerSteps={lowerDentalMovements?.steps?.sort(stepSorter)}
          comparerActivated
          secondaryTimeline
          vertical={splitMode === Split.Split2H}
          versionName={versionToCompare?.name}
          enableFollowUpOverposition={currentVersion?.result === CaseVersionState.accepted && !!minorVersions}
          minorVersions={minorVersions}
        />
      )}
    </div>
  );

  function bestLayout(width: number, height: number): Split {
    return width >= height ? Split.Split2 : Split.Split2H;
  }
}
