import { StateCreator } from 'zustand';
export interface IntermediateStepsSlice {
  areMadeChangesOnTeeth: boolean;
  isRecalculateCancelled: boolean;
  isAIInterpolationAsked: boolean;
  canAskAIInterpolation: boolean;
  setAreMadeChangesOnTeeth: (areMadeChangesOnTeeth: boolean) => void;
  setIsRecalculateCancelled: (isRecalculateCancelled: boolean) => void;
  setIsAIInterpolationAsked: (isAIInterpolationAsked: boolean) => void;
  setCanAskAIInterpolation: (canAskAIInterpolation: boolean) => void;
}

export const createIntermediateStepsSlice: StateCreator<IntermediateStepsSlice, [], [], IntermediateStepsSlice> = (
  set
) => ({
  areMadeChangesOnTeeth: false,
  isRecalculateCancelled: false,
  isAIInterpolationAsked: false,
  canAskAIInterpolation: false,
  setAreMadeChangesOnTeeth: (areMadeChangesOnTeeth: boolean) => set((_) => ({ areMadeChangesOnTeeth })),
  setIsRecalculateCancelled: (isRecalculateCancelled: boolean) => set((_) => ({ isRecalculateCancelled })),
  setIsAIInterpolationAsked: (isAIInterpolationAsked: boolean) => set((_) => ({ isAIInterpolationAsked })),
  setCanAskAIInterpolation: (canAskAIInterpolation: boolean) => set((_) => ({ canAskAIInterpolation }))
});
