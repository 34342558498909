import { useTranslation } from 'react-i18next';
import { SpecificInfoDetailCard } from '../specificInfo/SpecificInfoDetailsCard';
import { SpecificInfoIndicationsCard } from '../specificInfo/SpecificInfoIndicationsCard';
import { SpecificInfoTreatmentCard } from '../specificInfo/SpecificInfoTreatmentCard';
import './specificInfoTab.scss';
import { useBoundStore } from '../../../surgeries/stores/useStore';

export function SpecificInfoTab() {
  const [t] = useTranslation();
  const patientCase = useBoundStore((state) => state.patientCase);

  return (
    <div className="specific-info-tab">
      {patientCase ? (
        <>
          <SpecificInfoDetailCard noCard={true} patientCase={patientCase} />
          <SpecificInfoTreatmentCard noCard={true} patientCase={patientCase} />
          <SpecificInfoIndicationsCard noCard={true} patientCase={patientCase} />
        </>
      ) : (
        ''
      )}
    </div>
  );
}
