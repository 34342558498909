export const isStringVoid = (str: string | undefined): boolean =>
  str === undefined || str === null || str.trim() === '';
export const isBlobURL = (str: string): boolean => str.startsWith('blob:');
export const blobToBase64 = async (blob: Blob): Promise<string> => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
};

export const areObjectsEqual = (obj1: any, obj2: any): boolean => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};
