import { ReactComponent as GoToNextIcon } from '../../../assets/icons/player-next.svg';
import { ReactComponent as GoToPreviousIcon } from '../../../assets/icons/player-previous.svg';
import { ReactComponent as EllipsisIcon } from '../../../assets/icons/ellipsis.svg';
import { EvolutionStep } from './EvolutionPanel';
import { useOrthBoundStore } from '../../stores/useStore';
import { useCommonBoundStore } from '../../../common/stores/useStore';

import './evolutionPanelNavigator.scss';
import { useCallback, useEffect } from 'react';
import React from 'react';

interface EvolutionPanelNavigatorProps {
  steps: EvolutionStep[];
}

function EvolutionPanelNavigator(props: EvolutionPanelNavigatorProps) {
  const { steps } = props;
  const { setCurrentSliceSteps, currentSliceRange, setCurrentSliceRange, fitRowsInPanel } = useOrthBoundStore(
    (state) => ({
      setCurrentSliceSteps: state.setCurrentSliceSteps,
      currentSliceRange: state.currentSliceRange,
      setCurrentSliceRange: state.setCurrentSliceRange,
      fitRowsInPanel: state.fitRowsInPanel
    })
  );
  const { activeStep, setActiveStep, setSelectedStepIndexes } = useCommonBoundStore((state) => ({
    activeStep: state.activeStep,
    setActiveStep: state.setActiveStep,
    setSelectedStepIndexes: state.setSelectedStepIndexes
  }));

  useEffect(() => {
    if (isFirstStep()) {
      handleChangeInActiveStepWhenFirst();
    } else if (isLastStep()) {
      handleChangeInActiveStepWhenLast();
    }
  }, [activeStep]);

  const markAsCurrentStep = useCallback(
    (stepIndex: number) => {
      setActiveStep(stepIndex);
      setSelectedStepIndexes([stepIndex]);
    },
    [setActiveStep, setSelectedStepIndexes]
  );

  const handleChangeInActiveStepWhenFirst = useCallback(() => {
    const newCurrentSliceStep = {
      from: 0,
      to: fitRowsInPanel
    };
    setCurrentSliceRange(newCurrentSliceStep);
    setCurrentSliceSteps(steps.slice(newCurrentSliceStep.from, newCurrentSliceStep.to));
  }, [steps, fitRowsInPanel]);

  const handleChangeInActiveStepWhenLast = useCallback(() => {
    const newCurrentSliceStep = {
      from: steps.length > fitRowsInPanel ? steps.length - fitRowsInPanel : 0,
      to: steps.length
    };

    setCurrentSliceRange(newCurrentSliceStep);
    setCurrentSliceSteps(steps.slice(newCurrentSliceStep.from, newCurrentSliceStep.to));
  }, [steps, fitRowsInPanel]);

  const isFirstSlice = useCallback(() => currentSliceRange?.from === 0, [currentSliceRange]);

  const isFirstStep = useCallback(() => {
    return activeStep === 0;
  }, [activeStep]);

  const isLastSlice = useCallback(() => {
    if (!currentSliceRange) {
      return true;
    }

    return currentSliceRange.to >= steps.length;
  }, [currentSliceRange, steps]);

  const isLastStep = useCallback(() => {
    return activeStep === steps.length - 1;
  }, [activeStep]);

  const goToFirstStep = useCallback(() => {
    markAsCurrentStep(0);
  }, [fitRowsInPanel, setCurrentSliceRange, setCurrentSliceSteps, markAsCurrentStep, steps, isFirstSlice]);

  const goToPreviousStep = useCallback(() => {
    if (isFirstStep()) {
      return;
    }

    if (currentSliceRange && currentSliceRange.from > 0) {
      const newCurrentSliceStep = {
        from: currentSliceRange?.from - 1,
        to: currentSliceRange?.to - 1
      };
      setCurrentSliceRange(newCurrentSliceStep);
      setCurrentSliceSteps(steps.slice(newCurrentSliceStep.from, newCurrentSliceStep.to));
    }

    markAsCurrentStep(activeStep - 1);
  }, [currentSliceRange, isFirstSlice, setCurrentSliceRange, setCurrentSliceSteps, steps, activeStep, isFirstStep]);

  const goToNextStep = useCallback(() => {
    if (isLastStep()) {
      return;
    }

    if (!isLastSlice()) {
      const newCurrentSliceStep = {
        from: currentSliceRange?.from + 1,
        to: currentSliceRange?.to + 1
      };

      setCurrentSliceRange(newCurrentSliceStep);
      setCurrentSliceSteps(steps.slice(newCurrentSliceStep.from, newCurrentSliceStep.to));
    }

    markAsCurrentStep(activeStep + 1);
  }, [currentSliceRange, isLastSlice, setCurrentSliceRange, setCurrentSliceSteps, steps, activeStep, isLastStep]);

  const goToLastStep = useCallback(() => {
    markAsCurrentStep(steps.length - 1);
  }, [fitRowsInPanel, setCurrentSliceRange, setCurrentSliceSteps, setActiveStep, steps, isLastSlice]);

  return (
    <div className="evolutionpanelnavigator">
      <div
        className={`evolutionpanelnavigator-control evolutionpanelnavigator-index ${
          isFirstStep() ? 'disabled' : 'enabled'
        }`}
        onClick={goToFirstStep}
      >
        0
      </div>
      <div>
        <EllipsisIcon />
      </div>
      <div
        className={`evolutionpanelnavigator-control ${isFirstStep() ? 'disabled' : 'enabled'}`}
        onClick={goToPreviousStep}
      >
        <GoToPreviousIcon />
      </div>
      <div>{activeStep}</div>
      <div
        className={`evolutionpanelnavigator-control ${isLastStep() ? 'disabled' : 'enabled'}`}
        onClick={goToNextStep}
      >
        <GoToNextIcon />
      </div>
      <div>
        <EllipsisIcon />
      </div>
      <div
        className={`evolutionpanelnavigator-control evolutionpanelnavigator-index ${
          isLastStep() ? 'disabled' : 'enabled'
        }`}
        onClick={goToLastStep}
      >
        {steps[steps.length - 1].index}
      </div>
    </div>
  );
}

export default React.memo(EvolutionPanelNavigator);
