import { PieceSelectedIndicatorData } from './pieceSelectedIndicator';

export const lowerPiecesSelectedIndicators: PieceSelectedIndicatorData[] = [
  {
    pieceId: '41',
    cx: '150.14',
    cy: '225.14'
  },
  {
    pieceId: '42',
    cx: '127.14',
    cy: '221.14'
  },
  {
    pieceId: '43',
    cx: '108.14',
    cy: '204.14'
  },
  {
    pieceId: '44',
    cx: '98.14',
    cy: '188.14'
  },
  {
    pieceId: '45',
    cx: '88.14',
    cy: '164.14'
  },
  {
    pieceId: '46',
    cx: '80.14',
    cy: '128.14'
  },
  {
    pieceId: '47',
    cx: '70.14',
    cy: '88.14'
  },
  {
    pieceId: '48',
    cx: '66.14',
    cy: '48.14'
  },
  {
    pieceId: '31',
    cx: '178.14',
    cy: '225.14'
  },
  {
    pieceId: '32',
    cx: '203.14',
    cy: '221.14'
  },
  {
    pieceId: '33',
    cx: '222.14',
    cy: '204.14'
  },
  {
    pieceId: '34',
    cx: '232.14',
    cy: '188.14'
  },
  {
    pieceId: '35',
    cx: '242.14',
    cy: '164.14'
  },
  {
    pieceId: '36',
    cx: '250.14',
    cy: '128.14'
  },
  {
    pieceId: '37',
    cx: '260.14',
    cy: '88.14'
  },
  {
    pieceId: '38',
    cx: '264.14',
    cy: '48.14'
  }
];
