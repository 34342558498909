import Slider from '@mui/material/Slider';
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { ReactComponent as Eye } from '../../../../assets/icons/eye.svg';
import { ReactComponent as EyeOff } from '../../../../assets/icons/visible-off.svg';
import { CustomButton } from '../../../../common/components/contextMenu/CustomButton';

import './toolsDropdownBase.scss';

export interface ToolsDropdownBaseItem {
  name: string;
  isVisible: boolean;
  order: number;
  isDisabled?: boolean | undefined;
  withRangeSlider?: boolean;
  rangeSliderValue?: number;
  defaultActive?: boolean;
  id?: string;
  onClick: () => void;
  handleRangeSlider?: (value: number) => void;
}

interface ToolsDropdownBaseProps {
  title: string;
  prefix: string;
  dataFor: any;
  toggleIcon: React.ElementType;
  className: string;
  items: ToolsDropdownBaseItem[];
  handleToggleClick: () => void;
}

function ToolsDropdownItem(
  item: ToolsDropdownBaseItem,
  prefix: string,
  order: number,
  showIconFunction: (isVisible: boolean) => JSX.Element
) {
  const onChangeRangeSlider = (_: Event, value: number | number[]) => {
    item.handleRangeSlider && item.handleRangeSlider(value as number);
  };

  return (
    <Dropdown.Item
      as="button"
      disabled={item.isDisabled === true}
      bsPrefix={prefix}
      key={`${prefix}-${order}`}
      className={`tools-item ${item.isDisabled === true ? 'is-disabled' : ''} `}
      id={item.id}
    >
      <div className="tools-item_toggle" onClick={item.isDisabled ? null : item.onClick}>
        {item.name}
        {showIconFunction(item.isVisible)}
      </div>
      {item.withRangeSlider && (
        <div className="tools-item_slider">
          <Slider
            disabled={!item.isVisible}
            value={item.rangeSliderValue}
            onChange={onChangeRangeSlider}
            min={0}
            max={100}
          />
        </div>
      )}
    </Dropdown.Item>
  );
}

export function ToolsDropdownBase(props: ToolsDropdownBaseProps) {
  const { title, prefix, dataFor, toggleIcon: ToggleIcon, className, items, handleToggleClick } = props;

  const buttonprops = {
    'data-for': dataFor,
    className: className
  };

  const showIcon = (isVisible: boolean) => (isVisible ? <Eye /> : <EyeOff />);

  return (
    <>
      <Dropdown className="tools-dropdown no-select" onToggle={handleToggleClick} autoClose="outside" drop="start">
        <Dropdown.Toggle as={CustomButton} {...buttonprops} id="layer" bsPrefix="orthlayers">
          <ToggleIcon />
        </Dropdown.Toggle>

        <Dropdown.Menu className="tools-dropdown_menu">
          <h3 className="tools-title">{title}</h3>
          {items
            .sort((itemA, itemB) => itemA.order - itemB.order)
            .map((item, i) => ToolsDropdownItem(item, prefix, i, showIcon))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
