import { useCallback, useEffect, useState } from 'react';
import { useOrthBoundStore } from '../../../stores/useStore';

export function useAttachesContextMenu() {
  const [canAttachesMenuBeShown, setCanBeAttachesMenuBeShown] = useState<boolean>();

  const { selectedAttache } = useOrthBoundStore((state) => ({
    selectedAttache: state.selectedAttache
  }));

  useEffect(() => {
    setCanBeAttachesMenuBeShown(selectedAttache !== null);
  }, [selectedAttache]);

  const hideAttachesMenu = useCallback(() => {
    if (selectedAttache === null) {
      setCanBeAttachesMenuBeShown(false);
    }
  }, [setCanBeAttachesMenuBeShown, selectedAttache]);

  return {
    canAttachesMenuBeShown,
    hideAttachesMenu
  };
}
