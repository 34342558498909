import { CSSProperties, ReactNode, useMemo } from 'react';
import { PointerPosition } from '../../stores/slices/orthContextMenuSlice';

import { ContextMenu } from '../../../common';

type StepsContextMenuItem = {
  labelKey: string;
  action: () => void;
  icon: ReactNode;
  hide?: boolean;
};

type IntermediateStepsContextMenuProps = {
  position: PointerPosition;
  items: StepsContextMenuItem[];
  onToggleMenu: () => void;
};

const sizesInRems = {
  itemHeightWhenAlone: 3.9,
  itemHeightWhenManyItems: 2.9,
  menuMinWidth: 13
};

export function IntermediateStepsContextMenu(props: IntermediateStepsContextMenuProps) {
  const { position, items, onToggleMenu } = props;
  const menuItems = useMemo(() => items.filter((i) => !i.hide), [items]);

  const styles: CSSProperties = useMemo(() => {
    const visibleItemsCount = menuItems.length;
    const height =
      visibleItemsCount === 1
        ? sizesInRems.itemHeightWhenAlone
        : visibleItemsCount * sizesInRems.itemHeightWhenManyItems;
    const documentFontSizeInRems = parseFloat(getComputedStyle(document.documentElement).fontSize);

    let left = position.clientX;
    const top = position.clientY - height * documentFontSizeInRems;

    const menuWidth = sizesInRems.menuMinWidth * documentFontSizeInRems;
    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (left + menuWidth > windowWidth) {
      left = windowWidth - menuWidth;
    }

    return {
      left,
      top
    };
  }, [position, menuItems]);

  return <ContextMenu options={menuItems} positionData={styles} show={true} onToggle={onToggleMenu} />;
}
