import { useCallback } from 'react';

export function useCustomNavigate() {
  const getRouteWithVersionId = useCallback((route: string) => {
    const currentLocation = window.location;
    const searchParams = new URLSearchParams(currentLocation.search);
    if (searchParams.has('versionId')) {
      const versionId = searchParams.get('versionId');
      route += `?versionId=${versionId}`;
    }
    return route;
  }, []);

  const getRouteWithNewVersionId = useCallback((route: string, versionId: string) => {
    route += `?versionId=${versionId}`;
    return route;
  }, []);

  return { getRouteWithVersionId, getRouteWithNewVersionId } as const;
}
