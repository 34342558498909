import { StateCreator } from 'zustand';
import { AttachmentType } from '../../../common';
import { TooltipAttach } from '../../components';
export interface OrthAttachesSlice {
  areAttachesCalculating: boolean;
  setAreAttachesCalculating: (areAttachesCalculating: boolean) => void;
  attachActive: AttachmentType | null;
  setAttachActive: (attachActive: AttachmentType) => void;
  hoveredAttach: TooltipAttach | null;
  setHoveredAttach: (hoveredAttach: TooltipAttach | null) => void;
  isAttacheSelected: boolean;
  setIsAttacheSelected: (isAttacheSelected: boolean) => void;
  selectedAttache: TooltipAttach | null;
  setSelectedAttache: (selectedAttache: TooltipAttach | null) => void;
}

export const createOrthAttachesSlice: StateCreator<OrthAttachesSlice, [], [], OrthAttachesSlice> = (set) => ({
  areAttachesCalculating: false,
  setAreAttachesCalculating: (areAttachesCalculating: boolean) => set((_) => ({ areAttachesCalculating })),
  attachActive: null,
  setAttachActive: (attachActive: AttachmentType) => set((_) => ({ attachActive })),
  hoveredAttach: null,
  setHoveredAttach: (hoveredAttach: TooltipAttach | null) => set((_) => ({ hoveredAttach })),
  isAttacheSelected: false,
  setIsAttacheSelected: (isAttacheSelected: boolean) => set((_) => ({ isAttacheSelected })),
  selectedAttache: null,
  setSelectedAttache: (selectedAttache: TooltipAttach | null) => set((_) => ({ selectedAttache }))
});
