import { useEvergineStore } from 'evergine-react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import Axis from '../../../../../assets/icons/axis.svg';
import { NavBarAction } from '../../../../../common';
import { useFocusCanvas } from '../../../../../hooks/shared/useFocusCanvas';

export default () => {
  const [t] = useTranslation();
  const [showAxis, setShowAxis] = useState(true);
  const { webAssemblyLoaded, evergineReady } = useEvergineStore();
  const { setFocusOnCanvas } = useFocusCanvas();

  useEffect(() => {
    if (webAssemblyLoaded && evergineReady) {
      toggleAxis();
      // TODO: this should be right way, however some error is thrown from showAxis which im not sure
      // how to avoid.
      // window.App.webEventsProxy.layers && window.App.webEventsProxy.layers.showAxis(showAxis);
    }
  }, [webAssemblyLoaded, evergineReady]);

  const toggleAxis = useCallback(() => {
    window.App.webEventsProxy.layers.showAxis(!showAxis);
    setShowAxis(!showAxis);
    setFocusOnCanvas();
  }, [showAxis]);

  const buttonId = 'toggleAxis';

  return (
    <>
      <NavBarAction icon={Axis} dataFor={buttonId} altText={t('navbar.actions.nextStep')} action={toggleAxis} />
      <ReactTooltip id={buttonId} place="bottom" effect="solid" className="tooltip" type="dark">
        {t(`navbar.actions.information.axis`)}
      </ReactTooltip>
    </>
  );
};
