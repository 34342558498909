import { ToolbarConfigProps } from '../../../common';
import { PagesWithTools } from '../../../common/evergine';
import {
  downloadCaseToolbarElement,
  layersToolbarElement,
  panelsToolbarElement,
  publishCaseToolbarElement
} from './commonToolbarElements';
import { teethMovementsToolbarElement } from './dentalMovementsToolbarElements';
import { attachesToolbarElement, calcAIAttachesToolbarElement, tadsToolbarElement } from './designerToolbarElements';
import { calcAIIntermediateStepsToolbarElement } from './intermediateStepsToolbarElements';
import { iprMovementsToolbarElement } from './iprMovementsToolbarElements';
import {
  autofixToolbarElement,
  deleteToolbarElement,
  expandToolbarElement,
  extrusionToolbarElement,
  flatSliceToolbarElement,
  gapToolbarElement,
  inspectorToolbarElement,
  softToolbarElement
} from './meshCorrectionToolbarElements';

import { calcAIAxisRootsToolbarElement, manualFixToolbarElement } from './rootsToolbarElements';
import { calcAISegmentationToolbarElement, paintToolbarElement } from './segmentationToolbarElements';
import { testToolbarElement } from './testElement';

export const evergineOrthoToolbarConfig: { [key: number]: ToolbarConfigProps[] } = {
  [PagesWithTools.MeshCorrection]: [
    testToolbarElement,
    autofixToolbarElement,
    inspectorToolbarElement,
    softToolbarElement,
    expandToolbarElement,
    deleteToolbarElement,
    extrusionToolbarElement,
    gapToolbarElement,
    flatSliceToolbarElement
  ],
  [PagesWithTools.DentalMovements]: [
    layersToolbarElement,
    panelsToolbarElement,
    teethMovementsToolbarElement,
    publishCaseToolbarElement,
    downloadCaseToolbarElement
  ],
  [PagesWithTools.Attaches]: [
    layersToolbarElement,
    panelsToolbarElement,
    attachesToolbarElement,
    calcAIAttachesToolbarElement,
    tadsToolbarElement
  ],
  [PagesWithTools.Roots]: [manualFixToolbarElement, calcAIAxisRootsToolbarElement],
  [PagesWithTools.Segmentation]: [paintToolbarElement, calcAISegmentationToolbarElement],
  [PagesWithTools.Treatment]: [
    layersToolbarElement,
    panelsToolbarElement,
    teethMovementsToolbarElement,
    calcAIIntermediateStepsToolbarElement,
    iprMovementsToolbarElement
  ],
  [PagesWithTools.IPR]: [layersToolbarElement, panelsToolbarElement]
};
