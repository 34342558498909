import { Stage } from '../../../../common/evergine/types';
import { AsideConfig, StepState } from '../../../../surgeries/components/layout/aside';

export enum OrthodonticsOrderedRoutes {
  STLLoad = '/orthodontics/stl-captures',
  TeethSegmentation = '/orthodontics/teeth-segmentation',
  AxisAndRoots = '/orthodontics/axis-roots',
  Treatment = '/orthodontics/treatment',
  Attaches = '/orthodontics/attaches',
  Publish = '/orthodontics/publish'
}

export enum OrthodonticsOrderedPhasesKeys {
  STLLoad = 'stl_load',
  TeethSegmentation = 'teeth_segmentation',
  AxisAndRoots = 'axis_and_roots',
  Treatment = 'treatment',
  Attaches = 'attaches',
  Publish = 'publish'
}

export const PhaseByUrl: Record<string, OrthodonticsOrderedPhasesKeys> = {
  'stl-captures': OrthodonticsOrderedPhasesKeys.STLLoad,
  'teeth-segmentation': OrthodonticsOrderedPhasesKeys.TeethSegmentation,
  'axis-roots': OrthodonticsOrderedPhasesKeys.AxisAndRoots,
  treatment: OrthodonticsOrderedPhasesKeys.Treatment,
  attaches: OrthodonticsOrderedPhasesKeys.Attaches,
  publish: OrthodonticsOrderedPhasesKeys.Publish
};

export const EvergineStageByPhase: Record<string, Stage> = {
  [OrthodonticsOrderedPhasesKeys.STLLoad]: Stage.LoadSTL,
  [OrthodonticsOrderedPhasesKeys.TeethSegmentation]: Stage.TeethSegmentation,
  [OrthodonticsOrderedPhasesKeys.AxisAndRoots]: Stage.AxisAndRoots,
  [OrthodonticsOrderedPhasesKeys.Treatment]: Stage.Treatment,
  [OrthodonticsOrderedPhasesKeys.Attaches]: Stage.Attaches,
  [OrthodonticsOrderedPhasesKeys.Publish]: Stage.Publish
};

export const orthodonticsAsideConfig: AsideConfig[] = [
  {
    title: 'aside.orthodontics.images.title',
    items: [
      {
        title: 'aside.orthodontics.images.items.STLLoad',
        route: OrthodonticsOrderedRoutes.STLLoad,
        defaultState: StepState.Pending,
        key: OrthodonticsOrderedPhasesKeys.STLLoad
      }
    ]
  },
  {
    title: 'aside.orthodontics.preparation.title',
    items: [
      {
        title: 'aside.orthodontics.preparation.items.teethSegmentation',
        route: OrthodonticsOrderedRoutes.TeethSegmentation,
        defaultState: StepState.Pending,
        key: OrthodonticsOrderedPhasesKeys.TeethSegmentation
      },
      {
        title: 'aside.orthodontics.preparation.items.axisAndRoots',
        route: OrthodonticsOrderedRoutes.AxisAndRoots,
        defaultState: StepState.Pending,
        key: OrthodonticsOrderedPhasesKeys.AxisAndRoots
      }
    ]
  },
  {
    title: 'aside.orthodontics.treatment.title',
    items: [
      {
        title: 'aside.orthodontics.treatment.items.treatment',
        route: OrthodonticsOrderedRoutes.Treatment,
        defaultState: StepState.Pending,
        key: OrthodonticsOrderedPhasesKeys.Treatment
      },
      {
        title: 'aside.orthodontics.treatment.items.attaches',
        route: OrthodonticsOrderedRoutes.Attaches,
        defaultState: StepState.Pending,
        key: OrthodonticsOrderedPhasesKeys.Attaches
      }
    ]
  },
  {
    title: 'aside.orthodontics.production.title',
    items: [
      {
        title: 'aside.orthodontics.production.items.publish',
        route: OrthodonticsOrderedRoutes.Publish,
        defaultState: StepState.Pending,
        key: OrthodonticsOrderedPhasesKeys.Publish
      }
    ]
  }
];
