import { useLocation } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';
import { useEvergineStore } from 'evergine-react';
import { useCommonBoundStore } from '../../../common/stores/useStore';
import { PROFILES_TYPE } from '../../../shared';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { MovementsPanel } from '../../components/movementsPanel';
import { useOrthBoundStore } from '../../stores/useStore';
import { PathLevels } from '../../../models';
import { LateralPanel } from '../../../common/components/lateralPanel';
import { useCaseId, useGeneralPanels } from '../../../hooks';
import { useDentalMovements, useDentalMovementsStepsManager } from '../../../hooks/orthodontics';
import { EvergineToolbar, PagesWithTools, toolbarConfig } from '../../../common';
import {
  AttachesTools,
  BoltonPanel,
  EvolutionPanel,
  IprPanel,
  OcclusogramLegend,
  OrthTeethMovements,
  Shift,
  TadsTools
} from '../../components';

type GeneralPanelsProps = {
  children: JSX.Element;
  pageWithTools?: PagesWithTools;
};

export function GeneralPanels({ children, pageWithTools }: GeneralPanelsProps) {
  const [caseId] = useCaseId();
  const { pathname } = useLocation();
  const { evergineReady } = useEvergineStore();
  const { isEditStep } = useCommonBoundStore();
  const {
    showMiniOcclusogram,
    showMovements,
    showBoltonPanel,
    setShowBoltonPanel,
    showEvolutionPanel,
    setShowEvolutionPanel,
    showMovementsTable,
    setShowMovementsTable,
    showAttachesPanel,
    showTadsPanel,
    showIprPanel
  } = useOrthBoundStore((state) => ({
    showMiniOcclusogram: state.showMiniOcclusogram,
    showMovements: state.showMovements,
    showBoltonPanel: state.showBoltonPanel,
    setShowBoltonPanel: state.setShowBoltonPanel,
    showEvolutionPanel: state.showEvolutionPanel,
    setShowEvolutionPanel: state.setShowEvolutionPanel,
    showMovementsTable: state.showMovementsTable,
    setShowMovementsTable: state.setShowMovementsTable,
    showAttachesPanel: state.showAttachesPanel,
    showTadsPanel: state.showTadsPanel,
    showIprPanel: state.showIprPanel
  }));
  const { showLateralPanelIfAllowed, getWidthFromEvolutionPanel, evolutionPanelWidth } = useGeneralPanels();
  const { showInfoPanel, handleInfoPanelToggle } = useDentalMovements(caseId);

  const { dentalMovementsSteps, dentalMovementsApplyIPRList, setDentalMovementsApplyIPRList } =
    useDentalMovementsStepsManager();
  const pageTools = toolbarConfig[pageWithTools];

  const isClient = useMemo(() => {
    return useBoundStore.getState().userProfile?.type === PROFILES_TYPE.client;
  }, []);

  const isInPublishScreen = useMemo(() => {
    const shortedPathName = `${pathname.split('/')[PathLevels.RouteView]}`;
    if (shortedPathName === 'publish') {
      return true;
    }
    return false;
  }, []);

  const toolsWithoutMove = useMemo(() => {
    if (isInPublishScreen) {
      return pageTools.filter((item) => item.tooltipKey !== 'evergineTools.teethMovements');
    }
    return;
  }, [pageTools]);

  const shiftMovementsPanel = useCallback(() => {
    if (showMovementsTable) {
      if (showEvolutionPanel && !showInfoPanel) {
        return Shift.EvolutionPanelOpened;
      }

      if (showInfoPanel && !showEvolutionPanel) {
        return Shift.InfoPanelOpened;
      }

      if (showInfoPanel && showEvolutionPanel) {
        return Shift.EvolutionAndInfoPanelsOpened;
      }
    }

    return Shift.None;
  }, [showMovementsTable, showEvolutionPanel, showInfoPanel]);

  return (
    <>
      {/* starting from load stl phase*/}
      {showLateralPanelIfAllowed && (
        <div>
          <LateralPanel toggleShow={showInfoPanel} handleToggle={handleInfoPanelToggle} />
        </div>
      )}
      {pageTools && evergineReady && (
        <>
          {!isInPublishScreen ? (
            <EvergineToolbar tools={pageTools} />
          ) : (
            <EvergineToolbar tools={isEditStep && isClient ? pageTools : toolsWithoutMove} />
          )}
        </>
      )}
      {/* starting from finalPosition phase*/}
      {showMiniOcclusogram && <OcclusogramLegend />}
      {showMovements && <OrthTeethMovements />}
      {/* starting from intermediateSteps phase*/}
      {showBoltonPanel && <BoltonPanel opened={showBoltonPanel} onClickClose={() => setShowBoltonPanel(false)} />}
      {dentalMovementsSteps.length > 0 && (
        <EvolutionPanel
          opened={showEvolutionPanel}
          onClickClose={() => setShowEvolutionPanel(false)}
          shift={showEvolutionPanel && showInfoPanel ? Shift.InfoPanelOpened : Shift.None}
          getWidthFunction={(width) => getWidthFromEvolutionPanel(width)}
          steps={dentalMovementsSteps}
          updateIPRList={setDentalMovementsApplyIPRList}
          applyIPRList={dentalMovementsApplyIPRList}
        />
      )}
      <MovementsPanel
        opened={showMovementsTable}
        onClickClose={() => setShowMovementsTable(false)}
        shiftMode={shiftMovementsPanel()}
        evolutionPanelWidth={evolutionPanelWidth}
      />
      {showIprPanel && <IprPanel />}

      {/* starting from attaches phase*/}
      {showAttachesPanel && <AttachesTools />}
      {showTadsPanel && <TadsTools />}
      {children}
    </>
  );
}
