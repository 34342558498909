import create from 'zustand';
import { CompareTreatmentsSlice, createCompareTreatmentsSlice } from './slices/compareTreatmentsSlice';
import { DentalMovementsSlice, createDentalMovementsSlice } from './slices/dentalMovementsSlice';
import { EditTreatmentSlice, createEditTreatmentSlice } from './slices/editTreatmentSlice';
import { HistoryModificationsSlice, createHistoryModificationsSlice } from './slices/historyModificationsSlice';
import { IntermediateStepsSlice, createIntermediateStepsSlice } from './slices/intermediateStepsSlice';
import { OrthAttachesSlice, createOrthAttachesSlice } from './slices/orthAttachesSlice';
import { OrthAxisRootsSlice, createOrthAxisRootsSlice } from './slices/orthAxisRootsSlice';
import { OrthCaseStatusSlice, createOrthCaseStatusSlice } from './slices/orthCaseStatusSlice';
import { OrthContextMenuSlice, createOrthContextMenuSlice } from './slices/orthContextMenuSlice';
import { OrthIPRSlice, createOrthIPRSlice } from './slices/orthIPRSlice';
import { OrthTeethArchSlice, createOrthTeethArchSlice } from './slices/orthTeethArchSlice';
import { OrthTeethSegmentationSlice, createOrthTeethSegmentationSlice } from './slices/orthTeethSegmentationSlice';
import { OrthToolsSlice, createOrthToolsSlice } from './slices/orthToolsSlice';
import { RenderModelsSlice, createRenderModelsSlice } from './slices/renderModelsSlice';
import { UndoRedoSlice, createUndoRedoSlice } from './slices/undoRedoSlice';
import {
  NavigationEvolutionStepsSlice,
  createNavigationEvolutionStepsSlice
} from './slices/navigationEvolutionStepsSlice';

/* eslint-disable */
export type OrthBoundState = OrthTeethArchSlice &
  UndoRedoSlice &
  DentalMovementsSlice &
  CompareTreatmentsSlice &
  OrthIPRSlice &
  EditTreatmentSlice &
  HistoryModificationsSlice &
  OrthContextMenuSlice &
  OrthCaseStatusSlice &
  OrthTeethSegmentationSlice &
  OrthToolsSlice &
  OrthAxisRootsSlice &
  IntermediateStepsSlice &
  OrthAttachesSlice &
  RenderModelsSlice &
  NavigationEvolutionStepsSlice;

/* eslint-enable*/

export const useOrthBoundStore = create<OrthBoundState>()((...a) => ({
  ...createOrthTeethArchSlice(...a),
  ...createUndoRedoSlice(...a),
  ...createDentalMovementsSlice(...a),
  ...createCompareTreatmentsSlice(...a),
  ...createOrthIPRSlice(...a),
  ...createEditTreatmentSlice(...a),
  ...createHistoryModificationsSlice(...a),
  ...createOrthContextMenuSlice(...a),
  ...createOrthCaseStatusSlice(...a),
  ...createOrthTeethSegmentationSlice(...a),
  ...createOrthToolsSlice(...a),
  ...createOrthAxisRootsSlice(...a),
  ...createIntermediateStepsSlice(...a),
  ...createOrthAttachesSlice(...a),
  ...createRenderModelsSlice(...a),
  ...createNavigationEvolutionStepsSlice(...a)
}));
