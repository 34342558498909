import { CapturePosition } from '../../common';
import { TeethArchPosition } from '../../models';

export function useShowVisibleTeetharch() {
  const showEvergineArches = (archToShow: CapturePosition) => {
    window.App.webEventsProxy.layers.showArches(archToShow);
  };

  const showVisibleCaptureFromPosition: {
    [key: string]: (isUpperVisible?: boolean, isLowerVisible?: boolean) => void;
  } = {
    [TeethArchPosition.UPPER]: (/* isUpperVisible: boolean */) => {
      /* const archToShow = isUpperVisible ? CapturePosition.UPPER : CapturePosition.LOWER;
      showEvergineArches(CapturePosition.NONE);
      showEvergineArches(archToShow); */
      showEvergineArches(CapturePosition.UPPER);
    },
    [TeethArchPosition.LOWER]: (/* _, isLowerVisible: boolean */) => {
      /* const archToShow = isLowerVisible ? CapturePosition.LOWER : CapturePosition.UPPER;
      showEvergineArches(CapturePosition.NONE);
      showEvergineArches(archToShow); */
      showEvergineArches(CapturePosition.LOWER);
    },
    [TeethArchPosition.BOTH]: () => {
      showEvergineArches(CapturePosition.BOTH);
    },
    [TeethArchPosition.BOTH_OPEN]: () => {
      showEvergineArches(CapturePosition.BOTH_OPEN);
    }
  };

  return { showVisibleCaptureFromPosition };
}
