import { ContextMenu } from '../../../../common';
import { CustomContextMenuProps } from '../customContextMenuProps';
import { PointerPosition } from '../../../stores/slices/orthContextMenuSlice';
import { OpenAttachesPanel, OpenTadsPanel } from '../../evergineToolbarElements/tools/evergineToolbar.helper';
import { useTeethContextMenu } from './useTeethContextMenu';

interface TeethContextMenuProps extends CustomContextMenuProps {
  teethPosition?: PointerPosition;
  // provisional option until Attaches screen integration into Treatment
  showTADSOption: boolean;
}

export function TeethContextMenu({
  onToggleAction,
  teethPosition,
  positionData,
  show,
  showTADSOption
}: TeethContextMenuProps) {
  const { removeTeethIfSelected } = useTeethContextMenu(onToggleAction, teethPosition);

  const teethOptions = [
    {
      labelKey: 'contextMenuOptions.removeFromStepOne',
      action: () => {
        removeTeethIfSelected();
      }
    },
    // uncomment this on working at US#2601
    // {
    //   labelKey: 'contextMenuOptions.addAttache',
    //   action: () => {
    //     OpenAttachesPanel();
    //   }
    // },
    {
      labelKey: 'contextMenuOptions.addTAD',
      action: () => {
        OpenTadsPanel();
      }
    }
  ];

  // provisional option until Attaches screen integration into Treatment
  const attachmentsOptions = [
    {
      labelKey: 'contextMenuOptions.addTAD',
      action: () => {
        OpenTadsPanel();
      }
    },
    {
      labelKey: 'contextMenuOptions.addAttache',
      action: () => {
        OpenAttachesPanel();
      }
    }
  ];

  return (
    <ContextMenu
      options={showTADSOption ? attachmentsOptions : teethOptions}
      positionData={positionData}
      show={show}
      onToggle={() => onToggleAction()}
      autoClose={true}
    />
  );
}
