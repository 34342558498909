import { StateCreator } from 'zustand';
import { DentalMovementGroup, ToothHoverData, ToothTransformInfo } from '../../../common';

export interface DentalMovementsSlice {
  upperDentalMovements: DentalMovementGroup;
  setUpperDentalMovements: (data: DentalMovementGroup) => void;
  lowerDentalMovements: DentalMovementGroup;
  setLowerDentalMovements: (data: DentalMovementGroup) => void;
  setUpperAndLowerDentalMovements: (
    upperDentalMovements: DentalMovementGroup,
    lowerDentalMovements: DentalMovementGroup
  ) => void;
  showMiniOcclusogram: boolean;
  setShowMiniOcclusogram: (newValue: boolean) => void;
  selectedTeethId: number[];
  setSelectedTeethId: (newValue: number[]) => void;
  toothHoverData: ToothHoverData;
  setToothHoverData: (newValue: ToothHoverData) => void;
  isCasePublished: boolean;
  setIsCasePublished: (newValue: boolean) => void;
}

export const createDentalMovementsSlice: StateCreator<DentalMovementsSlice, [], [], DentalMovementsSlice> = (set) => ({
  upperDentalMovements: null,
  lowerDentalMovements: null,
  showMiniOcclusogram: false,
  selectedTeethId: [],
  toothHoverData: null,
  isCasePublished: false,
  setUpperDentalMovements: (upperDentalMovements: DentalMovementGroup) => set((_) => ({ upperDentalMovements })),
  setLowerDentalMovements: (lowerDentalMovements: DentalMovementGroup) => set((_) => ({ lowerDentalMovements })),
  setShowMiniOcclusogram: (showMiniOcclusogram: boolean) => set((_) => ({ showMiniOcclusogram })),
  setSelectedTeethId: (selectedToothId: number[]) => set((_) => ({ selectedTeethId: selectedToothId })),
  setToothHoverData: (toothHoverData: ToothHoverData) => set((_) => ({ toothHoverData })),
  setIsCasePublished: (isCasePublished: boolean) => set((_) => ({ isCasePublished })),
  setUpperAndLowerDentalMovements: (
    upperDentalMovements: DentalMovementGroup,
    lowerDentalMovements: DentalMovementGroup
  ) => set((_) => ({ upperDentalMovements, lowerDentalMovements }))
});
