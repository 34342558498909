import { useNavigate } from 'react-router-dom';
import { AbilityAction, OrthoAbilitySubject } from '../../../shared';
import {
  useCaseId,
  useGateKeeper,
  useRenderModels,
  useTimelineStepsManager,
  useIntermediateSteps,
  useIntermediateStepsContextMenu
} from '../../../hooks';
import { PagesWithTools, Stage, Timeline } from '../../../common';
import { GeneralPanels } from '../../layout';
import { IntermediateStepsContextMenu } from '../intermediateSteps';
import { useOrthBoundStore } from '../../stores/useStore';
import { OrthContextualMenu, PagesWithSpecificOptions } from '../contextMenus';

const firstStepIndex = 0;

export function Treatment() {
  const navigate = useNavigate();
  useGateKeeper(AbilityAction.Manage, OrthoAbilitySubject.TreatmentDesignerScreen, () => navigate('/forbidden'));
  const [caseId] = useCaseId();
  useRenderModels(caseId, Stage.Treatment);

  const {
    canShowTimeline,
    activeStep,
    upperSteps,
    lowerSteps,
    upperTeeth,
    lowerTeeth,
    upperAttachments,
    lowerAttachments
  } = useTimelineStepsManager();

  const {
    selectedStepIndexes,
    handlePreventedNavigation,
    addStepToSelection,
    getIntermediateStepsContextMenuOptions,
    keyStepIndexes,
    stepsModifiedWithNoAutoRecalcIndexes
  } = useIntermediateSteps();

  const {
    showIntermediateStepsContextMenu,
    contextMenuPosition,
    onToggleIntermediateStepsContextMenu,
    onStepContextMenu
  } = useIntermediateStepsContextMenu();

  const { areMadeChangesOnTeeth } = useOrthBoundStore((state) => ({
    areMadeChangesOnTeeth: state.areMadeChangesOnTeeth
  }));

  return (
    <>
      <GeneralPanels pageWithTools={PagesWithTools.Treatment}>
        <>
          <OrthContextualMenu
            preventContextMenu={activeStep === firstStepIndex}
            pageWithSpecificOptions={PagesWithSpecificOptions.Treatment}
          />
        </>
      </GeneralPanels>
      {canShowTimeline && (
        <>
          <Timeline
            upperTeeth={upperTeeth}
            lowerTeeth={lowerTeeth}
            upperSteps={upperSteps}
            lowerSteps={lowerSteps}
            upperAttachments={upperAttachments}
            lowerAttachments={lowerAttachments}
            comparerActivated={false}
            vertical={false}
            enableFollowUpOverposition={false}
            onStepContextMenu={onStepContextMenu}
            updateStoreCurrentStep={addStepToSelection}
            storeCurrentStep={activeStep}
            selectedSteps={selectedStepIndexes}
            isDesigner={true}
            keyStepsIndexes={keyStepIndexes}
            stepsModifiedWithNoAutoRecalcIndexes={stepsModifiedWithNoAutoRecalcIndexes}
            preventNavigation={areMadeChangesOnTeeth}
            handlePreventedNavigation={handlePreventedNavigation}
          />
          {showIntermediateStepsContextMenu && selectedStepIndexes.length === 1 && (
            <IntermediateStepsContextMenu
              position={contextMenuPosition}
              onToggleMenu={onToggleIntermediateStepsContextMenu}
              items={getIntermediateStepsContextMenuOptions()}
            />
          )}
        </>
      )}
    </>
  );
}
