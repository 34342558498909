import { StateCreator } from 'zustand';
import { TeethArchPosition } from '../../../models';

export interface TeethArchSlice {
  teethArch: TeethArchPosition;
  setTeethArch: (teethArchPosition: TeethArchPosition) => void;
}

export const createTeethArchSlice: StateCreator<TeethArchSlice, [], [], TeethArchSlice> = (set) => ({
  teethArch: TeethArchPosition.BOTH,
  setTeethArch: (teethArch: TeethArchPosition) => set((_) => ({ teethArch }))
});
