import { EVERGINE_CANVAS_ID } from '../../common';

export function useFocusCanvas() {
  const setFocusOnCanvas: () => void = () => {
    const canvasContainerElement = document.getElementById(EVERGINE_CANVAS_ID);
    canvasContainerElement?.focus();
  };

  return { setFocusOnCanvas };
}
