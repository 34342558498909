import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Info } from '../../../../../assets/icons/modal/info.svg';
import { useCommonBoundStore } from '../../../../../common/stores/useStore';
import { useCaseId } from '../../../../../hooks';
import { useFiles } from '../../../../../hooks/useFiles';
import { useOrthBoundStore } from '../../../../../orthodontics/stores/useStore';
import { useBoundStore } from '../../../../stores/useStore';
import { INJECTED_TYPES, container } from '../../../../../common';
import { IErrorHandlerService } from '../../../../../shared';

export function ModalDownloadCase() {
  const [t] = useTranslation();
  const { toggleModalIsOpened } = useCommonBoundStore();
  const { currentVersion } = useOrthBoundStore();
  const [caseId] = useCaseId();
  const setWebBusy = useBoundStore((state) => state.setWebBusy);
  const { downloadFile } = useFiles();
  const errorHandlerService = container.get<IErrorHandlerService>(INJECTED_TYPES.IErrorHandlerService);

  const onCloseButtonClick = () => {
    toggleModalIsOpened('');
  };

  const onContinueButtonClick = useCallback(async () => {
    toggleModalIsOpened('');
    setWebBusy(true);
    try {
      const folderToSaveZip = '/Content/CasePublications';
      const fileNameToDownload = 'Case-' + caseId + '.zip';
      const zipPath = await window.App.webEventsProxy.common.exportForPublishing(caseId, folderToSaveZip);

      downloadFile(zipPath, fileNameToDownload);
    } catch (error) {
      errorHandlerService.showError(t('errors.generateDownloadFileError'));
      console.error(error);
    } finally {
      setWebBusy(false);
    }
  }, [currentVersion]);

  return (
    <div className="modal-content" data-testid={'modal-downloadSTL'}>
      <div className="modal-body d-flex flex-column align-items-center">
        <Info className="modal-icon mb-3" />
        <h3 className="modal-title mb-4">{t(`modal.downloadCase.title`)}</h3>
        <p className="modal-text text-center mb-0">{t(`modal.downloadCase.description`)}</p>
      </div>

      <div className="modal-footer d-flex flex-grow-1">
        <button
          onClick={onCloseButtonClick}
          type="button"
          className="btn btn-outline-primary flex-fill modal-button"
          data-bs-dismiss="modal"
        >
          {t(`modal.actions.cancel`)}
        </button>
        <button onClick={onContinueButtonClick} type="button" className="btn btn-primary flex-fill modal-button">
          {t(`modal.actions.downloadSTL`)}
        </button>
      </div>
    </div>
  );
}
