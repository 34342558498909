import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as EvolutionIcon } from '../../../assets/icons/evolution.svg';
import { ReactComponent as ExpandIcon } from '../../../assets/icons/expand.svg';
import { ReactComponent as CollapseIcon } from '../../../assets/icons/collapse.svg';

import { useTranslation } from 'react-i18next';
import { AttachmentState } from '../../../common';
import { ApplyIprState, IprLabel } from '../../../common/evergine';
import { Shift } from '../dentalMovements';
import { EvolutionPanelInfo } from './EvolutionPanelInfo';
import './evolutionPanel.scss';
import EvolutionTable from './evolutionTable/EvolutionTable';
import { useOrthBoundStore } from '../../stores/useStore';
import EvolutionPanelNavigator from './EvolutionPanelNavigator';
import { useIntermediateSteps } from '../../../hooks';
import { useCommonBoundStore } from '../../../common/stores/useStore';
import { DentalMovementDTO } from '../../../shared';

interface EvolutionPanelProps {
  opened: boolean;
  shift: Shift;
  steps: EvolutionStep[];
  applyIPRList: IprLabel[];
  updateIPRList: (list: IprLabel[]) => void;
  onClickClose: () => void;
  getWidthFunction: (getWidth: () => number) => void;
}

export type EvolutionTooth = {
  fdi: number;
  isExtracted: boolean;
  isMoved: boolean;
  isPassiveAligner: boolean;
};

export type EvolutionInterdentalDistance = {
  leftToothFdi: number;
  rightToothFdi: number;
  distance: number;
  applyIprState: ApplyIprState;
  isSpaceVisible: boolean;
};

export type EvolutionAttachment = {
  toothFdi: number;
  state: AttachmentState;
};

export type EvolutionStep = {
  index: number;
  teeth: EvolutionTooth[];
  attachments: EvolutionAttachment[];
  interdentalDistances: EvolutionInterdentalDistance[];
  relativeDentalMovement: DentalMovementDTO[];
};

export function EvolutionPanel({
  opened,
  shift,
  steps,
  applyIPRList,
  onClickClose,
  getWidthFunction,
  updateIPRList
}: EvolutionPanelProps) {
  const [t] = useTranslation();
  const evolutionPanelRef = useRef(null);
  const { expandEvolutionPanel, setExpandEvolutionPanel, isEvolutionPanelSliced } = useOrthBoundStore((state) => ({
    expandEvolutionPanel: state.expandEvolutionPanel,
    setExpandEvolutionPanel: state.setExpandEvolutionPanel,
    isEvolutionPanelSliced: state.isEvolutionPanelSliced
  }));
  const [panelWidth, setPanelWidth] = useState(0);
  const { activeStep, selectedStepIndexes } = useCommonBoundStore((state) => ({
    activeStep: state.activeStep,
    selectedStepIndexes: state.selectedStepsIndexes
  }));

  const updateWidth = useCallback(() => {
    if (evolutionPanelRef.current) {
      const width = evolutionPanelRef.current.getBoundingClientRect().width;
      setPanelWidth(width);
    }
  }, [steps, expandEvolutionPanel, isEvolutionPanelSliced]);

  const { addStepAction, removeStepsAction } = useIntermediateSteps(true);

  useEffect(() => {
    if (isEvolutionPanelSliced) {
      const observer = new ResizeObserver(updateWidth);
      if (evolutionPanelRef.current) {
        observer.observe(evolutionPanelRef.current);
      }

      return () => {
        if (evolutionPanelRef.current) {
          observer.unobserve(evolutionPanelRef.current);
        }
      };
    }
  }, [isEvolutionPanelSliced, updateWidth]);

  const getWidth = useCallback(() => {
    return panelWidth;
  }, [panelWidth]);

  useEffect(() => {
    if (evolutionPanelRef.current) {
      getWidthFunction(getWidth);
    }
  }, [getWidthFunction, getWidth, evolutionPanelRef?.current]);

  useEffect(() => {
    updateWidth();
  }, []);

  const classes = classNames('evolutionpanel', {
    opened,
    ['shift-1']: shift === Shift.InfoPanelOpened
  });

  useEffect(() => {
    if (!evolutionPanelRef?.current) {
      return;
    }

    getWidthFunction(getWidth);
  }, [evolutionPanelRef?.current]);

  const handleExpandToggle = () => {
    setExpandEvolutionPanel(!expandEvolutionPanel);
  };

  const removeStep = () => {
    if (selectedStepIndexes.length === 0) return;
    removeStepsAction();
  };

  const addStep = () => {
    if (selectedStepIndexes.length === 0) return;
    addStepAction();
  };

  return (
    <div className={`${classes} no-select`} ref={evolutionPanelRef}>
      <div className={`evolutionpanel-container ${expandEvolutionPanel ? 'expanded' : ''}`}>
        <div className="evolutionpanel-header">
          <div className="evolutionpanel-tools">
            <div className="evolutionpanel-top">
              <div>
                <EvolutionIcon /> <span className="evolutionpanel-top-title">{t('evolutionPanel.title')}</span>
              </div>
              <div>
                <EvolutionPanelInfo />
                <div className="clickable" onClick={handleExpandToggle}>
                  {expandEvolutionPanel ? <CollapseIcon /> : <ExpandIcon />}
                </div>
                <CloseIcon className="clickable" onClick={onClickClose} />
              </div>
            </div>
            <div className="evolutionpanel-navigator">
              <EvolutionPanelNavigator steps={steps} />
              <CloseIcon
                className={`clickable ${selectedStepIndexes.length === 0 ? 'disabled' : 'enabled'}`}
                onClick={removeStep}
              />
              <div
                className={`evolutionpanel-navigator-add ${selectedStepIndexes.length === 0 ? 'disabled' : 'enabled'}`}
                onClick={addStep}
              >
                {t('evolutionPanel.addStep')}
              </div>
            </div>
          </div>
        </div>
        <EvolutionTable steps={steps} updateIPRList={updateIPRList} applyIPRList={applyIPRList} opened={opened} />
      </div>
    </div>
  );
}
