import { useEvergineStore } from 'evergine-react';
import { useBoundStore } from '../../surgeries/stores/useStore';
import { useCallback, useState } from 'react';
import { CameraPosition, CapturePosition } from '../../common';
import { useShowVisibleTeetharch } from './useShowVisibleTeetharch';
import { TeethArchPosition } from '../../models';
import { useFocusCanvas } from './useFocusCanvas';

export function useCamViewChange() {
  const { teethArch, setCamView } = useBoundStore();
  const { evergineReady } = useEvergineStore();
  const { showVisibleCaptureFromPosition } = useShowVisibleTeetharch();
  const [previousTeetharchPosition, setPreviousTeetharchPosition] = useState<TeethArchPosition>();
  const { setFocusOnCanvas } = useFocusCanvas();

  const isTopOrBottomCameraPosition = (cameraPosition: CameraPosition) =>
    cameraPosition === CameraPosition.Top || cameraPosition === CameraPosition.Bottom;

  const hideTeetharchByPerspective = useCallback(
    (cameraPosition: CameraPosition) => {
      setPreviousTeetharchPosition(teethArch);
      window.App.webEventsProxy.layers.showArches(
        cameraPosition === CameraPosition.Top ? CapturePosition.LOWER : CapturePosition.UPPER
      );
    },
    [teethArch]
  );

  const restoreShowBothTeetharchesIfNecessary = useCallback(() => {
    if (previousTeetharchPosition) {
      showVisibleCaptureFromPosition[previousTeetharchPosition]();
    }
  }, [previousTeetharchPosition]);

  const setCameraPosition = useCallback(
    async (cameraPosition: CameraPosition) => {
      if (!evergineReady) {
        return;
      }

      const isViewFromTopOrBottom = isTopOrBottomCameraPosition(cameraPosition);

      if (isViewFromTopOrBottom) {
        hideTeetharchByPerspective(cameraPosition);
      } else {
        restoreShowBothTeetharchesIfNecessary();
      }

      window.App.webEventsProxy.common.setCameraPosition(cameraPosition);
      setCamView(cameraPosition);
      setFocusOnCanvas();
    },
    [evergineReady, previousTeetharchPosition, showVisibleCaptureFromPosition]
  );

  return {
    setCameraPosition
  };
}
