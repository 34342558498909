import { useNavigate } from 'react-router-dom';
import { useCaseId, useGateKeeper, useGetFiles, useRenderModels } from '../../../hooks';
import { AbilityAction, ITeethSegmentationService, OrthoAbilitySubject, SegmentationFileDTO } from '../../../shared';

import { useEvergineStore } from 'evergine-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CapturePosition,
  INJECTED_TYPES,
  ModalChangeEnumeration,
  ModalConfirmEnumeration,
  PagesWithTools,
  SegmentationNumerationStrategy,
  Stage,
  container
} from '../../../common';
import { TeethArchPosition } from '../../../models';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { useOrthBoundStore } from '../../stores/useStore';

import { useTranslation } from 'react-i18next';
import { Odontogram } from '../../../surgeries/components';
import { colorsToEvergine } from '../../../surgeries/components/odontogramTeethArch/odontogramTeethArchUtils';
import { OrthTeethPaint } from '../evergineToolbarElements';

import './teethSegmentation.scss';
import { GeneralPanels } from '../../layout/general-panels';
import { useTeethSegmentation } from '../../../hooks/orthodontics';
import { useCommonBoundStore } from '../../../common/stores/useStore';
import { OrthContextualMenu } from '../contextMenus';

export function TeethSegmentation() {
  const navigate = useNavigate();
  useGateKeeper(AbilityAction.View, OrthoAbilitySubject.TeethSegmentationScreen, () => navigate('/forbidden'));

  const { setMessageInfo, setWebBusy } = useBoundStore((state) => ({
    setMessageInfo: state.setMessageInfo,
    setWebBusy: state.setWebBusy
  }));
  const {
    isTeethSegmentationConfirmed,
    setIsTeethSegmentationConfirmed,
    showPaintPanel,
    showModalEnumeration,
    setShowModalEnumeration,
    selectedPieces,
    setFromToPieces,
    fromToPieces,
    showUpperArch,
    showLowerArch,
    currentVersion,
    stageIsLoaded,
    setShowPaintPanel,
    setSelectedPieces,
    setIsSegmentationApplied,
    setShowUpperArch,
    setShowLowerArch
  } = useOrthBoundStore((state) => ({
    isTeethSegmentationConfirmed: state.isTeethSegmentationConfirmed,
    setIsTeethSegmentationConfirmed: state.setIsTeethSegmentationConfirmed,
    showPaintPanel: state.showPaintPanel,
    showModalEnumeration: state.showModalEnumeration,
    setShowModalEnumeration: state.setShowModalEnumeration,
    selectedPieces: state.selectedPieces,
    setFromToPieces: state.setFromToPieces,
    fromToPieces: state.fromToPieces,
    showUpperArch: state.showUpperArch,
    showLowerArch: state.showLowerArch,
    currentVersion: state.currentVersion,
    stageIsLoaded: state.stageIsLoaded,
    setShowPaintPanel: state.setShowPaintPanel,
    setSelectedPieces: state.setSelectedPieces,
    setIsSegmentationApplied: state.setIsSegmentationApplied,
    setShowUpperArch: state.setShowUpperArch,
    setShowLowerArch: state.setShowLowerArch
  }));
  const isModalOpened = useCommonBoundStore((state) => state.isModalOpened);
  const { evergineReady } = useEvergineStore();
  const setTeethArch = useBoundStore((state) => state.setTeethArch);

  const teethSegmentationService = container.get<ITeethSegmentationService>(INJECTED_TYPES.ITeethSegmentationService);

  const [t] = useTranslation();
  const [caseId] = useCaseId();
  const { getSTLFile } = useGetFiles(caseId);
  useRenderModels(caseId, Stage.TeethSegmentation);
  const { getQuadrantOfPiece, selectedPaintedToothFdi, setToothToBePainted } = useTeethSegmentation();

  const [selectedArchPosition, setSelectedArchPosition] = useState<TeethArchPosition>(TeethArchPosition.BOTH);
  const [isAISegmentationReceived, setIsAISegmentationReceived] = useState<boolean>();
  const [typeStrategy, setTypeStrategy] = useState<number | undefined>(undefined);
  const [showModalConfirmation, setShowModalConfirmation] = useState<boolean>(false);
  const [isAllowedMesialDistalRenumber, setIsAllowedMesialDistalRenumber] = useState<boolean>(false);
  const [isAIEnabled, setIsAIEnabled] = useState<boolean>(true);

  const closeModal = () => {
    setFromToPieces([]);
    setTypeStrategy(undefined);
    showModalEnumeration && setShowModalEnumeration(false);
    showModalConfirmation && setShowModalConfirmation(false);
  };

  const endEnumeration = async () => {
    await endEnumerationAux(typeStrategy);
  };

  const endEnumerationAux = async (param: SegmentationNumerationStrategy) => {
    try {
      await window.App.webEventsProxy.segmentation.applyNumerationChange(
        getFromAndToPieces.fromPiece,
        getFromAndToPieces.toPiece,
        param
      );
    } catch (error) {
      console.error('Error evaluating numeration change:', error);
    }
    setFromToPieces([]);
    setTypeStrategy(undefined);
    showModalEnumeration && setShowModalEnumeration(false);
    showModalConfirmation && setShowModalConfirmation(false);
  };

  const continueNumeration = async (param: SegmentationNumerationStrategy) => {
    setTypeStrategy(param);
    try {
      const result = await window.App.webEventsProxy.segmentation.evaluateNumerationChange(
        getFromAndToPieces.fromPiece,
        getFromAndToPieces.toPiece,
        param
      );
      if (!result.canBeAutomaticallyResolved) {
        setShowModalEnumeration(false);
        return setShowModalConfirmation(true);
      }
      return endEnumerationAux(param);
    } catch (error) {
      console.error('Error evaluating numeration change:', error);
    }
  };

  useEffect(() => {
    setShowUpperArch(false);
    setShowLowerArch(false);
    setTeethArch(TeethArchPosition.BOTH_OPEN);
  }, [isModalOpened]);

  useEffect(() => {
    if (!currentVersion || !evergineReady || !stageIsLoaded) {
      return;
    }

    if (currentVersion.segmentation && currentVersion.segmentation !== null) {
      const lowerSegmentation = currentVersion.segmentation.lower || null;
      const upperSegmentation = currentVersion.segmentation.upper || null;

      if (lowerSegmentation) {
        window.App.webEventsProxy.segmentation.setInitialSegmentationData({
          faces: lowerSegmentation,
          teethArch: CapturePosition.LOWER
        });
      }

      if (upperSegmentation) {
        window.App.webEventsProxy.segmentation.setInitialSegmentationData({
          faces: upperSegmentation,
          teethArch: CapturePosition.UPPER
        });
      }
      setIsAISegmentationReceived(true);
    }
  }, [currentVersion, evergineReady, stageIsLoaded]);

  useEffect(() => {
    if (isTeethSegmentationConfirmed && isAIEnabled) {
      setIsAIEnabled(false);
      setWebBusy(true);

      const sendTeethDataToAI = async () => {
        try {
          const upperArchBase64 = await getSTLFile(CapturePosition.UPPER);

          if (upperArchBase64) {
            const upperJawFileDto: SegmentationFileDTO = { file: upperArchBase64, teethArch: CapturePosition.UPPER };
            const response = await teethSegmentationService.getAITeethSegmentation(upperJawFileDto);
            window.App.webEventsProxy.segmentation.setSegmentationData({
              faces: response.faces,
              teethArch: CapturePosition.UPPER
            });
          }

          const lowerArchBase64 = await getSTLFile(CapturePosition.LOWER);

          if (lowerArchBase64) {
            const lowerJawFileDto: SegmentationFileDTO = { file: lowerArchBase64, teethArch: CapturePosition.LOWER };
            const response = await teethSegmentationService.getAITeethSegmentation(lowerJawFileDto);
            window.App.webEventsProxy.segmentation.setSegmentationData({
              faces: response.faces,
              teethArch: CapturePosition.LOWER
            });
          }
          setIsAISegmentationReceived(true);
        } finally {
          setIsAIEnabled(true);
          setWebBusy(false);
        }
      };

      sendTeethDataToAI();
      setIsSegmentationApplied(true);
    }
    setIsTeethSegmentationConfirmed(false);
  }, [isTeethSegmentationConfirmed]);

  useEffect(() => {
    let position: TeethArchPosition;
    if (showUpperArch === true && showLowerArch === true) {
      position = TeethArchPosition.BOTH;
    } else if (showUpperArch === true) {
      position = TeethArchPosition.UPPER;
    } else if (showLowerArch === true) {
      position = TeethArchPosition.LOWER;
    } else {
      position = TeethArchPosition.BOTH;
    }
    setSelectedArchPosition(position);
  }, [showUpperArch, showLowerArch]);

  useEffect(() => {
    setMessageInfo(showPaintPanel ? 'pageInfoMessages.teethSegmentation.paintInstructions' : undefined);
  }, [showPaintPanel]);

  const handleFromEnumeration = useCallback(() => {
    if (selectedPieces && selectedPieces.length > 0) {
      return selectedPieces[0];
    }
    return;
  }, [selectedPieces]);

  const getFromAndToPieces = useMemo(() => {
    return {
      fromPiece: Number(fromToPieces[0]),
      toPiece: Number(fromToPieces[1])
    };
  }, [fromToPieces]);

  useEffect(() => {
    if (fromToPieces.length === 2) {
      const [from, to] = fromToPieces;

      const fromPieceQuadrant = getQuadrantOfPiece(from);

      if (fromPieceQuadrant) {
        setIsAllowedMesialDistalRenumber(fromPieceQuadrant?.includes(to));
      } else {
        setIsAllowedMesialDistalRenumber(false);
      }
    }
  }, [fromToPieces]);

  useEffect(() => {
    if (!evergineReady) {
      return;
    }

    window.App.webEventsProxy.segmentation.setColorsByFdi(colorsToEvergine);
    setShowPaintPanel(true);
  }, [evergineReady]);

  useEffect(() => {
    if (selectedPaintedToothFdi !== undefined) {
      setSelectedPieces([selectedPaintedToothFdi]);
      setShowPaintPanel(true);
      setToothToBePainted(Number(selectedPaintedToothFdi));
    }
  }, [selectedPaintedToothFdi]);

  return (
    <>
      <GeneralPanels pageWithTools={PagesWithTools.Segmentation}>
        <>
          {showPaintPanel && <OrthTeethPaint selectedArchPosition={selectedArchPosition} />}
          <div className="teethsegmentation">
            {!showPaintPanel && isAISegmentationReceived && (
              <div className="teethsegmentation-odontogram">
                <Odontogram archPosition={selectedArchPosition} showToggleNumbers={true} colorVersion={true} />
              </div>
            )}
          </div>
          <OrthContextualMenu />
        </>
      </GeneralPanels>
      {showModalEnumeration && (
        <ModalChangeEnumeration
          title={t('pageInfoMessages.teethSegmentation.changeNumeration')}
          yesText={t('common.accept')}
          noText={t('modal.actions.cancel')}
          distalText={t('pageInfoMessages.teethSegmentation.distalRenumeration')}
          mesialText={t('pageInfoMessages.teethSegmentation.mesialRenumeration')}
          from={handleFromEnumeration()}
          onClickClose={closeModal}
          onClickContinue={(param) => continueNumeration(param)}
          disableMesialDistalRenumber={!isAllowedMesialDistalRenumber}
        />
      )}
      {showModalConfirmation && (
        <ModalConfirmEnumeration
          title={t('pageInfoMessages.teethSegmentation.conflictElements')}
          yesText={t('pageInfoMessages.teethSegmentation.change')}
          noText={t('modal.actions.cancel')}
          from={handleFromEnumeration()}
          onClickClose={closeModal}
          onClickContinue={endEnumeration}
        />
      )}
    </>
  );
}
