import { useMemo } from 'react';

type BoltonPanelData = {
  upperRightTeeth: Record<string, string>[];
  upperLeftTeeth: Record<string, string>[];
  lowerRightTeeth: Record<string, string>[];
  lowerLeftTeeth: Record<string, string>[];
  bolton3Ratio: string;
  bolton6Ratio: string;
  bolton3Percent: string;
  bolton6Percent: string;
};

const upperRightTeetharchFdis = [11, 12, 13, 14, 15, 16];
const upperLeftTeetharchFdis = [21, 22, 23, 24, 25, 26];
const lowerRightTeetharchFdis = [41, 42, 43, 44, 45, 46];
const lowerLeftTeetharchFdis = [31, 32, 33, 34, 35, 36];

export function useBoltonPanelData() {
  const boltonPanelData: BoltonPanelData = useMemo(() => {
    const resultBolton = window.App.webEventsProxy.axisAndRoots.calculateBolton();

    return {
      bolton3Percent: resultBolton.bolton3Percent.toFixed(2) ?? 'N/A',
      bolton3Ratio: resultBolton.bolton3Ratio.toFixed(2) ?? 'N/A',
      bolton6Percent: resultBolton.bolton6Percent.toFixed(2) ?? 'N/A',
      bolton6Ratio: resultBolton.bolton6Ratio.toFixed(2) ?? 'N/A',
      upperRightTeeth: resultBolton.upperTeeth
        .filter((t) => upperRightTeetharchFdis.includes(t.fdi))
        .map((t) => ({
          key: t.fdi.toString(),
          value: t.isMissing ? 'N/A' : t.mesioDistalLength.toFixed(2)
        })),
      upperLeftTeeth: resultBolton.upperTeeth
        .filter((t) => upperLeftTeetharchFdis.includes(t.fdi))
        .map((t) => ({
          key: t.fdi.toString(),
          value: t.isMissing ? 'N/A' : t.mesioDistalLength.toFixed(2)
        })),
      lowerRightTeeth: resultBolton.lowerTeeth
        .filter((t) => lowerRightTeetharchFdis.includes(t.fdi))
        .map((t) => ({
          key: t.fdi.toString(),
          value: t.isMissing ? 'N/A' : t.mesioDistalLength.toFixed(2)
        })),
      lowerLeftTeeth: resultBolton.lowerTeeth
        .filter((t) => lowerLeftTeetharchFdis.includes(t.fdi))
        .map((t) => ({
          key: t.fdi.toString(),
          value: t.isMissing ? 'N/A' : t.mesioDistalLength.toFixed(2)
        }))
    };
  }, []);

  return {
    boltonPanelData
  };
}
