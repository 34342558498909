import { useEffect, useRef, useState } from 'react';
import { ReactComponent as MDTraslation } from '../../../../assets/icons/MD-traslation.svg';
import { ReactComponent as TIPAngle } from '../../../../assets/icons/TIP-angle.svg';
import { ReactComponent as VLTraslation } from '../../../../assets/icons/VL-traslation.svg';
import { ReactComponent as ExtrusionIntrusion } from '../../../../assets/icons/extrusion-intrusion.svg';
import { ReactComponent as Occlusion } from '../../../../assets/icons/occlusion.svg';
import { ReactComponent as PureRotation } from '../../../../assets/icons/pure-rotation.svg';
import { ReactComponent as Torque } from '../../../../assets/icons/torque.svg';
import { useCommonBoundStore } from '../../../../common/stores/useStore';
import { useOrthBoundStore } from '../../../stores/useStore';

import { DentalMovementDTO, MovementsTableDto } from '../../../../shared';
import './movementstable.scss';
import { MovementCalculationType } from '../../../../common';
import { useToothTransformStore } from '../../../stores/useToothTransformStore';

interface tableDataValues {
  id: number;
  value: number[];
}

interface MovementsTableProps {
  calculationType: MovementCalculationType;
}

export function MovementsTable({ calculationType }: MovementsTableProps) {
  const {
    currentVersion,
    showMovementsTable,
    movementsTable,
    setMovementsTable,
    upperDentalMovements,
    lowerDentalMovements
  } = useOrthBoundStore((state) => ({
    currentVersion: state.currentVersion,
    showMovementsTable: state.showMovementsTable,
    movementsTable: state.movementsTable,
    setMovementsTable: state.setMovementsTable,
    upperDentalMovements: state.upperDentalMovements,
    lowerDentalMovements: state.lowerDentalMovements
  }));
  const selectedTeethTransformData = useToothTransformStore((state) => state.selectedTeethTransformData);
  const [isBodyScrollVisible, setIsBodyScrollVisible] = useState<boolean>();
  const [tableData, setTableData] = useState<Array<tableDataValues>>([]);
  const { activeStep } = useCommonBoundStore();

  const piecesRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!piecesRef.current || !showMovementsTable) {
      return;
    }
    const isScrollVisible = piecesRef.current?.clientHeight < piecesRef.current?.scrollHeight;
    setIsBodyScrollVisible(isScrollVisible);
    getMovementsTable();
  }, [piecesRef, showMovementsTable, activeStep, calculationType, upperDentalMovements, lowerDentalMovements]);

  useEffect(() => {
    if (!movementsTable || !selectedTeethTransformData) {
      return;
    }
    refreshToothMovementsTable();
    setTableData(parseMovementsTable(movementsTable));
  }, [selectedTeethTransformData]);

  const refreshToothMovementsTable = () => {
    const toothTransform = selectedTeethTransformData;
    const actualMovementsTable: MovementsTableDto = useOrthBoundStore.getState().movementsTable;
    getMovementTableByCalculationType(activeStep, actualMovementsTable, calculationType).map(
      (movement: DentalMovementDTO) => {
        if (movement.fdi === Number(toothTransform.toothFdi)) {
          movement.extrusionIntrusion = toothTransform.extrusionIntrusion;
          movement.translationVL = toothTransform.translationVL;
          movement.translationMD = toothTransform.translationMD;
          movement.pureRotation = toothTransform.pureRotation;
          movement.torque = toothTransform.torque;
          movement.tip = toothTransform.tip;
        }
      }
    );

    useOrthBoundStore.setState({ movementsTable: actualMovementsTable });
  };

  const getMovementsTable = async () => {
    let response: MovementsTableDto = null;
    response = await window.App.webEventsProxy.movements.getMovementsTable();
    setMovementsTable(response);
    setTableData(parseMovementsTable(response));
  };

  const parseMovementsTable = (movementsTable: MovementsTableDto) => {
    const parsedData: Array<tableDataValues> = [];
    const movementsInStep = getMovementTableByCalculationType(activeStep, movementsTable, calculationType);

    movementsInStep.forEach((movement: DentalMovementDTO) => {
      const { fdi, extrusionIntrusion, translationVL, translationMD, pureRotation, torque, tip } = movement;

      const tableRow: tableDataValues = {
        id: fdi,
        value: [extrusionIntrusion, translationVL, translationMD, pureRotation, torque, tip]
      };

      parsedData.push(tableRow);
    });
    return parsedData;
  };

  const getQuadrantPieceMovements = (quadrantNumber: number, asc: boolean) => {
    const divs: JSX.Element[] = [];
    const { start, end } = getStartEndPiecesForQuadrant(quadrantNumber);
    const getDirection = (i: number) => (asc ? i <= end : i >= end);

    for (let i = start; getDirection(i); asc ? i++ : i--) {
      divs.push(
        <div className="movementstable-row" key={`quadrant-${quadrantNumber}-row-${i}`}>
          {getRowPieceMovements(i)}
        </div>
      );
    }

    return divs;
  };

  const getStartEndPiecesForQuadrant = (quadrantNumber: number) => {
    const quadrantStartEndPieces: Record<number, { start: number; end: number }> = {
      1: {
        start: 18,
        end: 11
      },
      2: {
        start: 21,
        end: 28
      },
      3: {
        start: 38,
        end: 31
      },
      4: {
        start: 41,
        end: 48
      }
    };

    return quadrantStartEndPieces[quadrantNumber];
  };

  const getRowPieceMovements = (pieceNumber?: number) => {
    const divs: JSX.Element[] = [];
    divs.push(
      <div className="movementstable-piece-number" key={pieceNumber}>
        {pieceNumber}
      </div>
    );

    return [divs, ...getCellPieceMovements(pieceNumber)];
  };

  const getOcclusionPieceMovements = () => {
    return (
      <div className="movementstable-row">
        <div className="movementstable-piece-number">
          <Occlusion />
        </div>
        {getCellPieceMovements()}
      </div>
    );
  };

  const getCellPieceMovements = (pieceNumber?: number) => {
    const divs: JSX.Element[] = [];

    for (let i = 0; i < 6; i++) {
      const piece: tableDataValues = tableData.find((piece) => piece.id === pieceNumber);

      if (!piece) continue;

      divs.push(
        <div className="movementstable-piece" key={`piece-movement-${i}`}>
          {piece.value[i]?.toFixed(2) || 0}
        </div>
      );
    }

    return divs;
  };

  const getMovementTableByCalculationType = (
    stepIndex: number,
    movementTable: MovementsTableDto,
    calculationType: MovementCalculationType
  ) => {
    switch (calculationType) {
      case MovementCalculationType.Total:
        return movementTable.movementsTotal || [];
      case MovementCalculationType.Accumulated:
        return movementTable.movementsAccumulated[stepIndex] || [];
      case MovementCalculationType.Step:
        return movementTable.movementsRelative[stepIndex] || [];
    }
  };

  return (
    <div className="movementstable">
      <div className={`movementstable-header ${isBodyScrollVisible ? 'is-scroll' : ''}`}>
        <ExtrusionIntrusion />
        <VLTraslation />
        <MDTraslation />
        <PureRotation />
        <Torque />
        <TIPAngle />
      </div>
      <div className="movementstable-body" ref={piecesRef}>
        <div className="movementstable-quadrant">{getQuadrantPieceMovements(1, false)}</div>
        <div className="movementstable-quadrant">{getQuadrantPieceMovements(2, true)}</div>
        <div className="movementstable-quadrant">{getQuadrantPieceMovements(3, false)}</div>
        <div className="movementstable-quadrant">{getQuadrantPieceMovements(4, true)}</div>
        <div className="movementstable-quadrant">{getOcclusionPieceMovements()}</div>
      </div>
    </div>
  );
}
