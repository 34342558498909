import { v4 as uuidv4 } from 'uuid';
import { ICommand } from '../../shared';
import { useCommonBoundStore } from '../../common/stores/useStore';
import { useOrthBoundStore } from '../stores/useStore';

export default class MarkStepAsModifiedWithNoAutoRecalcCommand implements ICommand {
  public id: string;
  public isEvergineCommand: boolean;
  private stepIndex: number;
  private stepToNavigate: number;
  private setterSelectedStepIndex: (stepIndex: number) => void;

  constructor(stepIndex: number, stepToNavigate: number, setterSelectedStepIndex: (stepIndex: number) => void) {
    this.id = uuidv4();
    this.isEvergineCommand = false;
    this.stepIndex = stepIndex;
    this.stepToNavigate = stepToNavigate;
    this.setterSelectedStepIndex = setterSelectedStepIndex;
  }

  execute(): Promise<void> {
    return new Promise<void>(async (resolve) => {
      window.App.webEventsProxy.movements.setStepAsStepWithNoAutoRecalc(this.stepIndex, true);
      useCommonBoundStore.setState({ activeStep: this.stepToNavigate });
      this.setterSelectedStepIndex(this.stepToNavigate);
      resolve();
    });
  }

  undo(): Promise<void> {
    return new Promise<void>(async (resolve) => {
      window.App.webEventsProxy.movements.setStepAsStepWithNoAutoRecalc(this.stepIndex, false);
      window.App.webEventsProxy.movements.goToStep(this.stepIndex, false);
      useCommonBoundStore.setState({ activeStep: this.stepIndex });
      this.setterSelectedStepIndex(this.stepIndex);
      useOrthBoundStore.setState({ areMadeChangesOnTeeth: true });
      resolve();
    });
  }
}
