import { ToolbarConfigProps } from '../../../common';
import { ReactComponent as AutoCalculate } from '../../../assets/icons/evergineTools/auto-calc.svg';
import { useOrthBoundStore } from '../../stores/useStore';

export const calcAIIntermediateStepsToolbarElement: ToolbarConfigProps = {
  icon: AutoCalculate,
  tooltipKey: 'evergineTools.autoInterpolation',
  labelKey: 'evergineTools.autoInterpolation',
  handleClick: () => {
    useOrthBoundStore.setState({ isAIInterpolationAsked: true });
  },
  isDisabled: () => {
    return !useOrthBoundStore.getState().canAskAIInterpolation;
  }
};
