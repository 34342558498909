import { useCallback } from 'react';
import { useCommonBoundStore } from '../../common/stores/useStore';
import { orthodonticsAsideConfig, OrthodonticsOrderedPhasesKeys } from '../../orthodontics/components/layout/aside';
import { ModalTypes } from '../../surgeries/components/layout';
import { useCaseId } from './useCaseId';
import { useCaseStatus } from './useCaseStatus';
import { useIprCalculation } from './useIprCalculation';

export function useCaseChanges() {
  const [caseId] = useCaseId();
  const { caseStatus } = useCaseStatus(caseId);

  const toggleModalIsOpened = useCommonBoundStore((state) => state.toggleModalIsOpened);
  const isCaseModified = useCommonBoundStore((state) => state.isCaseModified);
  const updateRouteToGo = useCommonBoundStore((state) => state.updateRouteToGo);
  const { checkIfIprsAreCalculated } = useIprCalculation();

  const onChangeRoute = useCallback(() => {
    if (!isCaseModified) {
      return;
    }
    toggleModalIsOpened(ModalTypes.ModalPreviousCasePhaseModified);
  }, []);

  const onChangeTreatmentRouteHasToRecalculateIpr = useCallback(() => {
    if (!caseStatus || !caseStatus.phases || caseStatus?.phases.length === 0) {
      return false;
    }
    const routeKey = getRouteKey();
    const currentPhase = caseStatus.phases.find((p) => p.name === routeKey);

    if (currentPhase.name == OrthodonticsOrderedPhasesKeys.Treatment) {
      const rs = checkIfIprsAreCalculated();
      if (!rs) {
        toggleModalIsOpened(ModalTypes.ModalPendingIprCalculation);
        return true;
      }
    }
    return false;
  }, [orthodonticsAsideConfig, caseStatus, checkIfIprsAreCalculated]);

  const getRouteKey = useCallback(() => {
    const combinedMenuItems = orthodonticsAsideConfig.reduce((accumulator, currentData) => {
      return accumulator.concat(currentData.items);
    }, []);

    const currentRoute = new URL(window.location.href);
    const currentRouteMenuItem = combinedMenuItems.find((item) => currentRoute.pathname.includes(item.route));
    if (!currentRouteMenuItem) {
      return null;
    }
    return currentRouteMenuItem.key;
  }, [orthodonticsAsideConfig]);

  const checkIfPreviousCompletedPhaseHasChanged = useCallback(() => {
    if (!caseStatus || !caseStatus.phases || caseStatus?.phases.length === 0) {
      return false;
    }

    const routeKey = getRouteKey();
    const currentPhase = caseStatus.phases.find((p) => p.name === routeKey);

    if (currentPhase.name == OrthodonticsOrderedPhasesKeys.Treatment) {
      checkIfIprsAreCalculated();
    }

    // TODO: need to know last active case phase from backend, for now we will use the last completed phase
    const completedItems = caseStatus.phases.filter((item) => !!item.completionDate);
    const lastCompletedItem = completedItems.reduce(
      (maxItem, currentItem) => (currentItem.completionDate > maxItem.completionDate ? currentItem : maxItem),
      completedItems[0]
    );

    if (lastCompletedItem.id > currentPhase.id) {
      return true;
    }

    return false;
  }, [orthodonticsAsideConfig, caseStatus, checkIfIprsAreCalculated]);

  return {
    isCaseModified,
    onChangeRoute,
    checkIfPreviousCompletedPhaseHasChanged,
    toggleModalIsOpened,
    updateRouteToGo,
    getRouteKey,
    onChangeTreatmentRouteHasToRecalculateIpr
  };
}
