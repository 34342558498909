import { StateCreator } from 'zustand';
import { EvolutionStep } from '../../components';

type SliceRange = {
  from: number;
  to: number;
};

export interface NavigationEvolutionStepsSlice {
  currentSliceSteps: EvolutionStep[];
  currentSliceRange: SliceRange;
  fitRowsInPanel: number;
  setCurrentSliceSteps: (currentSliceSteps: EvolutionStep[]) => void;
  setCurrentSliceRange: (currentSliceRange: SliceRange) => void;
  setFitRowsInPanel: (fitRowsInPanel: number) => void;
}

/* eslint-disable @typescript-eslint/indent */
export const createNavigationEvolutionStepsSlice: StateCreator<
  NavigationEvolutionStepsSlice,
  [],
  [],
  NavigationEvolutionStepsSlice
  /* eslint-enable @typescript-eslint/indent */
> = (set) => ({
  currentSliceSteps: [],
  currentSliceRange: { from: 0, to: 0 },
  fitRowsInPanel: 0,
  setCurrentSliceSteps: (currentSliceSteps: EvolutionStep[]) => set((_) => ({ currentSliceSteps })),
  setCurrentSliceRange: (currentSliceRange: SliceRange) => set((_) => ({ currentSliceRange })),
  setFitRowsInPanel: (fitRowsInPanel: number) => set((_) => ({ fitRowsInPanel }))
});
