import { ModifyAttachesThickness } from '../customOptions';
import { ContextMenu } from '../../../../common';
import { CustomContextMenuProps } from '../customContextMenuProps';

interface AttachesContextMenuProps extends CustomContextMenuProps {
  onClickOutside: () => void;
}

export function AttachesContextMenu({ onToggleAction, onClickOutside, positionData, show }: AttachesContextMenuProps) {
  const attacheOptions = [
    {
      labelKey: 'contextMenuOptions.attaches.addAtache',
      customJSX: <ModifyAttachesThickness onClickOutside={onClickOutside} />
    }
  ];

  return (
    <ContextMenu
      options={attacheOptions}
      positionData={positionData}
      show={show}
      onToggle={() => onToggleAction()}
      autoClose={'outside'}
    />
  );
}
