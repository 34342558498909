import { useEffect, useMemo, useRef } from 'react';
import { Constants, MinorVersion } from '../../../shared';
import { Attachment, IprLabel, Step, Tooth } from '../../evergine';
import { useCommonBoundStore } from '../../stores/useStore';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

type TimelineStepButtonProps = {
  index: number;
  isNew: boolean;
  isOverpositionActivated: boolean;
  hasMinorVersionStep: boolean;
  showMinorVersions: boolean;
  teeth?: Tooth[];
  selectedSteps?: number[];
  isDesigner?: boolean;
  isFinalPositionStage?: boolean;
  stepsLength?: number;
  isKeyStep?: boolean;
  isStepModifiedWithNoAutoRecalc?: boolean;
  onStepContextMenu?: (stepIndex: number, stepElement: HTMLElement) => void;
};

type StatusLinesType = {
  checkTeethMovement: boolean;
  steps: Step[];
  teeth: Tooth[];
  attachments: Attachment[];
  iprLabels: IprLabel[];
  isDefaultVisible: boolean;
};

type TimelineStepsProps = {
  upperSteps: Step[];
  lowerSteps: Step[];
  upperTeeth: Tooth[];
  lowerTeeth: Tooth[];
  upperAttachments: Attachment[];
  lowerAttachments: Attachment[];
  upperIprLables: IprLabel[];
  lowerIprLables: IprLabel[];
  isPlaying: boolean;
  isOverpositionActivated: boolean;
  comparerActivated: boolean;
  vertical: boolean;
  currentStep: number;
  showMinorVersions: boolean;
  stepToCompare?: number;
  maxStepsLength?: number;
  maxStepIndex?: number;
  isDisabled?: boolean;
  minorVersions?: MinorVersion[];
  selectedSteps?: number[];
  isDesigner?: boolean;
  isFinalPositionStage?: boolean;
  keyStepsIndexes?: number[];
  stepsModifiedWithNoAutoRecalcIndexes?: number[];
  onEndPlay: () => void;
  onSelectStepToCompare: (stepIndex: number) => void;
  onClickPlayerAction: (newValue: number, isCtrlKeyPressed?: boolean) => void;
  onStepContextMenu?: (stepIndex: number, stepElement: HTMLElement) => void;
};

export const TimelineSteps = ({
  upperSteps,
  lowerSteps,
  upperTeeth,
  lowerTeeth,
  upperAttachments,
  lowerAttachments,
  upperIprLables,
  lowerIprLables,
  isPlaying,
  isOverpositionActivated,
  comparerActivated,
  vertical,
  currentStep,
  showMinorVersions,
  stepToCompare,
  maxStepsLength,
  maxStepIndex,
  isDisabled,
  minorVersions,
  selectedSteps,
  isDesigner,
  isFinalPositionStage,
  keyStepsIndexes,
  stepsModifiedWithNoAutoRecalcIndexes,
  onEndPlay,
  onSelectStepToCompare,
  onClickPlayerAction,
  onStepContextMenu
}: TimelineStepsProps) => {
  const { settings } = useCommonBoundStore();
  const littleSpaceForSteps = useMemo(() => comparerActivated && !vertical, [comparerActivated, vertical]);

  const animationInterval = useMemo(() => settings?.timelineSpeedMs || 250, [settings]);
  const animationFrameIdRef = useRef<number>();

  useEffect(() => {
    const startAnimation = () => {
      const startTime = performance.now();
      let lastLogTime = 0;
      let stepIndex = currentStep;

      const animate = (timestamp: number) => {
        const currentTime = timestamp - startTime;

        if (!isPlaying || stepIndex >= maxStepsLength) {
          cancelAnimationFrame(animationFrameIdRef.current);
          onEndPlay();
        }

        if (currentTime - lastLogTime >= animationInterval) {
          lastLogTime = currentTime;
          stepIndex += 1;
          onClickPlayerAction(stepIndex);
        }
        animationFrameIdRef.current = requestAnimationFrame(animate);
      };

      animationFrameIdRef.current = requestAnimationFrame(animate);
    };

    if (isPlaying && !animationFrameIdRef.current) {
      startAnimation();
    } else if (!isPlaying && animationFrameIdRef.current) {
      cancelAnimationFrame(animationFrameIdRef.current);
      animationFrameIdRef.current = undefined;
    }

    return () => {
      if (animationFrameIdRef.current) {
        cancelAnimationFrame(animationFrameIdRef.current);
        animationFrameIdRef.current = undefined;
      }
    };
  }, [isPlaying, maxStepsLength, currentStep, onClickPlayerAction, onEndPlay, animationInterval]);

  const hasTeethMovement = (index: number, teeth: Tooth[], steps: Step[]) => {
    if (index === 0) {
      return false;
    }

    if (steps === undefined || steps === null) {
      return false;
    }

    if (!steps.some((s) => s.stepIndex === index)) {
      return false;
    }

    const currentActiveStep: Step = steps.find((s) => s.stepIndex === index);

    if (!currentActiveStep) {
      return false;
    }

    return (
      teeth?.some((tooth) => tooth.extractedInStep === index) ||
      currentActiveStep?.toothMovements?.some((m) => m.movedInCurrentStep)
    );
  };

  const onClickStep = (stepIndex: number, eventToHandle: Function, isCtrlKeyPressed?: boolean) => {
    eventToHandle(stepIndex, isCtrlKeyPressed);
    onEndPlay();
  };

  const statusLines: StatusLinesType[] = [
    {
      checkTeethMovement: true,
      steps: upperSteps,
      teeth: upperTeeth,
      attachments: upperAttachments,
      iprLabels: upperIprLables,
      isDefaultVisible: true
    },
    {
      checkTeethMovement: true,
      steps: lowerSteps,
      teeth: lowerTeeth,
      attachments: lowerAttachments,
      iprLabels: lowerIprLables,
      isDefaultVisible: true
    }
  ];

  const isNumberVisible = (isActiveCheck: boolean, index: number, hasMinorVersionStep: boolean) =>
    isActiveCheck ||
    hasMinorVersionStep ||
    (!isOverpositionActivated && !littleSpaceForSteps) ||
    ((isOverpositionActivated || littleSpaceForSteps) && !(index % 5)) ||
    index === maxStepsLength - 1;

  const hasStepState = (stateToFind: number, distinctStates: number[]) =>
    !!distinctStates && distinctStates.find((d) => (d & stateToFind) === stateToFind) !== undefined;

  const TimelineArchStatusLine = (
    stepIndex: number,
    isNew: boolean,
    i: number,
    lineData: StatusLinesType,
    isFinalPositionStage: boolean
  ) => {
    if (!lineData.steps) {
      return;
    }

    const indexStep = stepIndex === 1 && isFinalPositionStage ? maxStepIndex : stepIndex;

    const isActiveAligner: boolean = hasTeethMovement(indexStep, lineData.teeth, lineData.steps);
    const isPassiveAligner = !isActiveAligner && indexStep !== 0;
    let movementClass = '';
    if (isActiveAligner) {
      movementClass = 'active-aligner';
    } else if (isPassiveAligner) {
      movementClass = 'passive-aligner';
    }

    const currentActiveStep = lineData.steps.find((s) => s.stepIndex === indexStep);
    const isStartAttachVisible = lineData.attachments?.some((a) => a.firstStep === indexStep);
    const isIpr = lineData.iprLabels?.some((i) => i.applyStepIndex === indexStep) || false;
    const isEndAttachVisible = lineData.attachments?.some(
      (a) =>
        a.lastStep === indexStep ||
        (a.lastStep === Constants.maxLastStepForAttachments && indexStep === maxStepsLength - 1)
    );

    const isClientModification = currentActiveStep?.isClientModification;
    const modifiyingClass = `${isClientModification ? 'is-modifiying' : isNew ? 'is-new' : ''}`;
    const archClass = `${modifiyingClass ? modifiyingClass : lineData.checkTeethMovement ? movementClass : 'is-empty'}`;

    return (
      <div key={`step_arch-${stepIndex}-${i}`} className={`timeline-step_arch ${archClass}`}>
        {lineData.isDefaultVisible && !isClientModification && (
          <div className="timeline-step_lines">
            <div className={`timeline-step_line_state ${isStartAttachVisible ? 'is-attachement' : ''}`} />
            <div className={`timeline-step_line_state ${isIpr ? 'is-distance' : ''}`} />
            <div className={`timeline-step_line_state ${isEndAttachVisible ? 'is-attachement' : ''}`} />
          </div>
        )}
      </div>
    );
  };

  const StepButton = (
    isActive: boolean,
    isMultiSelected: boolean,
    isStepToCompare: boolean,
    index: number,
    hasMinorVersionStep: boolean,
    isOverpositionActivated: boolean,
    onClickHandler: Function,
    isKeyStep?: boolean,
    isStepModifiedWithNoAutoRecalc?: boolean
  ) => {
    return (
      <>
        <button
          disabled={isDisabled}
          className={`timeline-step_button ${isKeyStep ? 'is-keystep' : ''}  ${
            isStepModifiedWithNoAutoRecalc ? 'is-stepwithnoautorecalc' : ''
          }`}
          onClick={(ev) => onClickStep(index, onClickHandler, ev.ctrlKey || ev.metaKey)}
        >
          <span
            className={`timeline-step_text ${isActive && 'is-active'} ${isStepToCompare && 'is-comparing-step'} ${
              isMultiSelected && 'is-multiselected'
            } ${hasMinorVersionStep && isOverpositionActivated && 'has-minor-version'} ${
              isKeyStep ? 'is-keystep' : ''
            } ${isStepModifiedWithNoAutoRecalc ? 'is-stepwithnoautorecalc' : ''} no-select`}
          >
            {isNumberVisible(isActive, index, hasMinorVersionStep) && index}
          </span>
        </button>
      </>
    );
  };

  const TimelineStepButton = ({
    index,
    isNew,
    isOverpositionActivated,
    hasMinorVersionStep,
    showMinorVersions,
    selectedSteps,
    isDesigner,
    isFinalPositionStage,
    stepsLength,
    isKeyStep,
    isStepModifiedWithNoAutoRecalc,
    onStepContextMenu
  }: TimelineStepButtonProps) => {
    const [t] = useTranslation();

    const isLastStep = index === stepsLength;
    const indexToBePassed = isDesigner && isFinalPositionStage && isLastStep ? maxStepIndex : index;

    const isActive = currentStep === indexToBePassed;
    const isStepToCompare = stepToCompare === indexToBePassed;
    const isMultiSelected = !!selectedSteps && selectedSteps.includes(indexToBePassed) && !isActive && !isStepToCompare;

    const dataForTooltip = `tooltip-step-${index}`;

    return (
      <>
        <div
          className={`timeline-step ${isActive ? 'is-active' : ''} ${
            isFinalPositionStage ? 'finalpositionstage-timeline' : ''
          }`}
          onContextMenu={(ev: React.MouseEvent) => {
            if (onStepContextMenu) {
              ev.preventDefault();
              const target = ev.currentTarget as HTMLElement;
              onStepContextMenu(index, target);
            }
          }}
          data-for={dataForTooltip}
          data-tip
        >
          {isOverpositionActivated &&
            !showMinorVersions &&
            StepButton(
              isStepToCompare,
              false,
              isStepToCompare,
              indexToBePassed,
              hasMinorVersionStep,
              isOverpositionActivated,
              onSelectStepToCompare
            )}
          {statusLines.map((data, i) => TimelineArchStatusLine(index, isNew, i, data, isFinalPositionStage))}
          {StepButton(
            isActive,
            isMultiSelected,
            isStepToCompare,
            indexToBePassed,
            hasMinorVersionStep,
            isOverpositionActivated,
            onClickPlayerAction,
            isKeyStep,
            isStepModifiedWithNoAutoRecalc
          )}
        </div>
        {isStepModifiedWithNoAutoRecalc && (
          <ReactTooltip id={dataForTooltip} place="top" className="tooltip" type="dark">
            {t(`intermediateSteps.timeline.noAskedAutocalc`)}
          </ReactTooltip>
        )}
      </>
    );
  };

  return (
    maxStepsLength > 0 &&
    animationInterval && (
      <div className={`timeline-steps w-100 ${isFinalPositionStage ? 'is-finalposition' : ''}`}>
        {[...Array(maxStepsLength).keys()].map((i) => {
          const id = `player-step-${i}`;
          const isFirstButton = i === 0;
          const isLastButton = i === [...Array(maxStepsLength).keys()].length - 1;
          const isKeyStepToBeRemarked =
            !isFirstButton && !isLastButton && keyStepsIndexes && keyStepsIndexes?.includes(i);
          const isStepModifiedWithNoAutoRecalc = stepsModifiedWithNoAutoRecalcIndexes?.includes(i);
          return (
            <TimelineStepButton
              key={id}
              index={i}
              isNew={false}
              isOverpositionActivated={isOverpositionActivated}
              hasMinorVersionStep={
                !!minorVersions && minorVersions.some((mv) => mv.stepIndex === i) && showMinorVersions
              }
              showMinorVersions={showMinorVersions}
              onStepContextMenu={onStepContextMenu}
              isDesigner={isDesigner}
              selectedSteps={selectedSteps}
              isFinalPositionStage={isFinalPositionStage}
              stepsLength={maxStepsLength - 1}
              isKeyStep={isKeyStepToBeRemarked}
              isStepModifiedWithNoAutoRecalc={isStepModifiedWithNoAutoRecalc}
            />
          );
        })}
      </div>
    )
  );
};
