import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ApplyIprState,
  Attachment,
  AttachmentState,
  DentalMovementGroup,
  InterdentalDistance,
  IprLabel,
  Step,
  Tooth,
  ToothMovement
} from '../../common';
import {
  EvolutionAttachment,
  EvolutionInterdentalDistance,
  EvolutionStep,
  EvolutionTooth
} from '../../orthodontics/components';
import { Constants } from '../../shared';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { useMovementTable } from '../shared/useMovementTable';
import { useEvergineStore } from 'evergine-react';

export function useDentalMovementsStepsManager() {
  const { lowerDentalMovements, upperDentalMovements } = useOrthBoundStore((state) => ({
    lowerDentalMovements: state.lowerDentalMovements,
    upperDentalMovements: state.upperDentalMovements
  }));

  const { getMovementsTable, getRelativeDentalMovementsByStep } = useMovementTable();

  const [dentalMovementsSteps, setDentalMovementsSteps] = useState<EvolutionStep[]>([]);
  const [dentalMovementsApplyIPRList, setDentalMovementsApplyIPRList] = useState<IprLabel[]>([]);
  const { evergineReady } = useEvergineStore();
  const { stageIsLoaded } = useOrthBoundStore((state) => ({
    stageIsLoaded: state.stageIsLoaded
  }));

  const maxStoreStepsLength = useMemo(
    () => Math.max(upperDentalMovements?.steps?.length || 0, lowerDentalMovements?.steps?.length || 0),
    [upperDentalMovements, lowerDentalMovements]
  );

  useEffect(() => {
    if ((!upperDentalMovements && !lowerDentalMovements) || !evergineReady || !stageIsLoaded) {
      return;
    }

    const fetchData = async () => {
      const allTeeth = [...(lowerDentalMovements?.teeth ?? []), ...(upperDentalMovements?.teeth ?? [])];
      const allIprLabels = [...(lowerDentalMovements?.iprLabels ?? []), ...(upperDentalMovements?.iprLabels ?? [])];
      const groupedSteps = groupObjectsByStepIndex(lowerDentalMovements?.steps, upperDentalMovements?.steps);
      const movementTable = await getMovementsTable();
      const result: EvolutionStep[] = groupedSteps.map((step: Step) => {
        const isUpperPassiveAligner =
          step.stepIndex !== 0 &&
          !upperDentalMovements?.steps
            .find((s) => s.stepIndex === step.stepIndex)
            ?.toothMovements?.some((tm) => tm.movedInCurrentStep);

        const isLowerPassiveAligner =
          step.stepIndex !== 0 &&
          !lowerDentalMovements?.steps
            .find((s) => s.stepIndex === step.stepIndex)
            ?.toothMovements?.some((tm) => tm.movedInCurrentStep);
        const relativeDentalMovement = getRelativeDentalMovementsByStep(step.stepIndex, movementTable);

        return {
          index: step.stepIndex,
          teeth: calculateTeeth(step, allTeeth, isUpperPassiveAligner, isLowerPassiveAligner),
          attachments: calculateAttachments(step, allTeeth),
          interdentalDistances: calculateInterdentalDistances(step, allTeeth, allIprLabels),
          relativeDentalMovement: relativeDentalMovement
        };
      });

      const iprLabelsListToSave = [
        ...(lowerDentalMovements?.iprLabels || []),
        ...(upperDentalMovements?.iprLabels || [])
      ];

      setDentalMovementsSteps(result);
      setDentalMovementsApplyIPRList(iprLabelsListToSave);
    };

    fetchData();
  }, [lowerDentalMovements, upperDentalMovements, evergineReady, stageIsLoaded]);

  function calculateTeeth(
    step: Step,
    allTeeth: Tooth[],
    isUpperPassiveAligner: boolean,
    isLowerPassiveAligner: boolean
  ): EvolutionTooth[] {
    return step.toothMovements.map((toothMovement: ToothMovement) => {
      const tooth = allTeeth.find((tooth: Tooth) => tooth.id === toothMovement.toothId);
      return {
        fdi: tooth?.fdi,
        isExtracted: tooth?.extractedInStep?.valueOf() <= step.stepIndex || false,
        isMoved: toothMovement.movedInCurrentStep,
        isPassiveAligner: tooth?.fdi < 30 ? isUpperPassiveAligner : isLowerPassiveAligner
      };
    });
  }

  function calculateAttachments(step: Step, allTeeth: Tooth[]): EvolutionAttachment[] {
    const allAttachments = [...(lowerDentalMovements?.attachments ?? []), ...(upperDentalMovements?.attachments ?? [])];

    return Object.values(
      allAttachments
        .map(
          (attachment: Attachment): EvolutionAttachment => ({
            toothFdi: allTeeth.find((tooth: Tooth) => tooth.id === attachment.toothId)?.fdi,
            state: calculateAttachmentStateForStep(attachment, step)
          })
        )
        .reduce((acc: any, { toothFdi, state }) => {
          if (acc[toothFdi]) {
            acc[toothFdi].state = mergeAttachmentState(acc[toothFdi].state, state);
          } else {
            acc[toothFdi] = { toothFdi, state };
          }
          return acc;
        }, {})
    );
  }

  function calculateAttachmentStateForStep(attachment: Attachment, step: Step) {
    if (attachment.firstStep === step.stepIndex) {
      if (
        attachment.lastStep === step.stepIndex ||
        (attachment.lastStep === Constants.maxLastStepForAttachments && step.stepIndex === maxStoreStepsLength - 1)
      ) {
        return AttachmentState.Both;
      } else {
        return AttachmentState.Start;
      }
    } else if (
      attachment.lastStep === step.stepIndex ||
      (attachment.lastStep === Constants.maxLastStepForAttachments && step.stepIndex === maxStoreStepsLength - 1)
    ) {
      return AttachmentState.End;
    } else {
      return AttachmentState.None;
    }
  }

  function mergeAttachmentState(state1: AttachmentState, state2: AttachmentState): AttachmentState {
    if (state1 === AttachmentState.None) return state2;
    if (state2 === AttachmentState.None) return state1;
    if (state1 === state2) return state1;
    return AttachmentState.Both;
  }

  function calculateInterdentalDistances(
    step: Step,
    allTeeth: Tooth[],
    allIprLabels: IprLabel[]
  ): EvolutionInterdentalDistance[] {
    return step.interdentalDistances.map((interdentalDistance: InterdentalDistance) => {
      const leftTooth = allTeeth.find((tooth: Tooth) => tooth.id === interdentalDistance.leftToothId);
      const rightTooth = allTeeth.find((tooth: Tooth) => tooth.id === interdentalDistance.rightToothId);
      const iprLabel = allIprLabels?.find((l) => l.fdiLeft === leftTooth.fdi);
      const applyIprStepIndex = iprLabel?.applyStepIndex;
      const applyIprState = calculateApplyIprState(applyIprStepIndex, step.stepIndex);
      return {
        leftToothFdi: leftTooth.fdi,
        rightToothFdi: rightTooth.fdi,
        distance: applyIprState !== ApplyIprState.None ? iprLabel.value : interdentalDistance.distance,
        applyIprState: applyIprState,
        isSpaceVisible: applyIprState === ApplyIprState.None && interdentalDistance.distance > 0
      };
    });
  }

  function calculateApplyIprState(applyIprStepIndex: number | undefined, stepIndex: number): ApplyIprState {
    if (applyIprStepIndex === undefined) {
      return ApplyIprState.None;
    } else if (applyIprStepIndex === stepIndex) {
      return ApplyIprState.ApplyIpr;
    } else if (stepIndex < applyIprStepIndex) {
      return ApplyIprState.PreIpr;
    } else {
      return ApplyIprState.PostIpr;
    }
  }

  const groupObjectsByStepIndex = (lowerSteps: Step[], upperSteps: Step[]) => {
    const groupedObjects: { [key: number]: Step } = {};

    [...(lowerSteps ?? []), ...(upperSteps ?? [])].forEach((obj) => {
      const { stepIndex, toothMovements, interdentalDistances, isKeyStep } = obj;
      if (!groupedObjects[stepIndex]) {
        groupedObjects[stepIndex] = {
          stepIndex,
          toothMovements: [],
          interdentalDistances: [],
          jawTransform: [],
          isKeyStep: false
        };
      }

      if (Array.isArray(toothMovements)) {
        groupedObjects[stepIndex].toothMovements = groupedObjects[stepIndex].toothMovements.concat(toothMovements);
      }

      if (Array.isArray(interdentalDistances)) {
        groupedObjects[stepIndex].interdentalDistances =
          groupedObjects[stepIndex].interdentalDistances.concat(interdentalDistances);
      }

      groupedObjects[stepIndex].isKeyStep = groupedObjects[stepIndex].isKeyStep || isKeyStep;
    });

    return Object.values(groupedObjects);
  };

  return {
    dentalMovementsSteps,
    dentalMovementsApplyIPRList,
    setDentalMovementsApplyIPRList,
    groupObjectsByStepIndex
  };
}
