export function useHandleEventListener() {
  enum EventNames {
    Blur = 'blur',
    ContextMenu = 'contextmenu',
    Keydown = 'keydown',
    Keyup = 'keyup',
    MouseUp = 'mouseup',
    VisibilityChange = 'visibilitychange',
    Wheel = 'wheel'
  }

  const attachEventListenerToContainer = (
    container: HTMLElement | null,
    event: string,
    eventListener: EventListener
  ) => {
    if (container) {
      container.addEventListener(event, eventListener);
    }
  };

  const dettachEventListenerToContainer = (
    container: HTMLElement | null,
    event: string,
    eventListener: EventListener
  ) => {
    if (container) {
      container.removeEventListener(event, eventListener);
    }
  };

  const attachEventListenerToPage = (event: string, eventListener: EventListener) => {
    window.addEventListener(event, eventListener);
  };

  const dettachEventListenerToPage = (event: string, eventListener: EventListener) => {
    window.removeEventListener(event, eventListener);
  };

  return {
    EventNames,
    attachEventListenerToContainer,
    dettachEventListenerToContainer,
    attachEventListenerToPage,
    dettachEventListenerToPage
  };
}
