import { useCallback, useEffect } from 'react';
import { container } from '../../common/ioc/ioc.config';
import { INJECTED_TYPES } from '../../common/ioc/ioc.types';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { CaseVersionState, ICasesService, ITeethSegmentationService, PROFILES_TYPE } from '../../shared';
import { CaseVersion, PatientCase } from '../../shared/cases/case';
import { useBoundStore } from '../../surgeries/stores/useStore';

export function useCaseData(caseId: string) {
  const { patientCase, updatePatientCaseStore, versions, updateVersions } = useBoundStore((state) => ({
    patientCase: state.patientCase,
    updatePatientCaseStore: state.updatePatientCase,
    versions: state.versions,
    updateVersions: state.updateVersions
  }));
  const { currentVersion, setCurrentVersion, setIsCasePublished, setIsVersionLoaded } = useOrthBoundStore((state) => ({
    currentVersion: state.currentVersion,
    setCurrentVersion: state.setCurrentVersion,
    setIsCasePublished: state.setIsCasePublished,
    setIsVersionLoaded: state.setIsVersionLoaded
  }));
  const setWebBusy = useBoundStore((state) => state.setWebBusy);
  const isClient = useBoundStore.getState().userProfile?.type === PROFILES_TYPE.client;

  useEffect(() => {
    const fetchPatientCase = async () => {
      if (!patientCase) {
        const casesService = container.get<ICasesService>(INJECTED_TYPES.ICasesService);
        const fetchedCase = await casesService.getById(caseId);
        updatePatientCaseStore(fetchedCase);
      }
    };
    fetchPatientCase();
  }, [patientCase]);

  const getSegmentationFromVersion = useCallback(
    async (versionId: string) => {
      const teethSegmentationService = container.get<ITeethSegmentationService>(
        INJECTED_TYPES.ITeethSegmentationService
      );
      try {
        const segmentation = await teethSegmentationService.getSegmentation(caseId, versionId);
        return segmentation;
      } catch (error) {
        return null;
      }
    },
    [caseId]
  );

  const fetchPatientCaseVersions = useCallback(async () => {
    if (currentVersion) {
      return;
    }
    const casesService = container.get<ICasesService>(INJECTED_TYPES.ICasesService);

    let fetchedVersions = await casesService.getVersionsByCaseId(caseId);

    const params = new URLSearchParams(window.location.search);
    const isLegacyFromParam = params.get('legacy');

    if (isClient && (!isLegacyFromParam || isLegacyFromParam !== 'true')) {
      // Show only published versions if it's client
      fetchedVersions = fetchedVersions.filter((v) => v.state === CaseVersionState.published);
    }

    updateVersions(fetchedVersions);

    const versionIdFromParams = params.get(casesService.versionIdUrlParamIndex) || null;
    const versionFromParams = fetchedVersions.find((v) => v.id == versionIdFromParams);

    let selectedVersion;
    if (versionFromParams) {
      const segmentation = await getSegmentationFromVersion(versionFromParams.id);
      if (segmentation !== null) {
        versionFromParams.segmentation = segmentation;
      }
      setCurrentVersion(versionFromParams);
      selectedVersion = versionFromParams;
    } else {
      if (fetchedVersions && fetchedVersions.length > 0) {
        const lastVersion = fetchedVersions[fetchedVersions?.length - 1];
        const segmentation = await getSegmentationFromVersion(lastVersion.id);
        if (segmentation !== null) {
          lastVersion.segmentation = segmentation;
        }
        setCurrentVersion(lastVersion);
        selectedVersion = lastVersion;
      }
    }

    if (selectedVersion && selectedVersion.state === CaseVersionState.published) {
      setIsCasePublished(true);
    } else {
      setIsCasePublished(false);
    }
    setIsVersionLoaded(true);
  }, [versions, caseId, setCurrentVersion, currentVersion]);

  const getPatientCase = async () => {
    const casesService = container.get<ICasesService>(INJECTED_TYPES.ICasesService);
    const fetchedCase: PatientCase = await casesService.getById(caseId);
    return fetchedCase;
  };

  const getPatientCaseVersion = async (versionId: string) => {
    const casesService = container.get<ICasesService>(INJECTED_TYPES.ICasesService);
    const fetchedCaseVersion: CaseVersion = await casesService.getCaseVersion(caseId, versionId);
    return fetchedCaseVersion;
  };

  const updatePatientCase = useCallback(async (data: unknown) => {
    const casesService = container.get<ICasesService>(INJECTED_TYPES.ICasesService);
    const fetchedCase = await casesService.getById(caseId);
    const meta = fetchedCase.meta && fetchedCase.meta.length > 0 ? JSON.parse(fetchedCase.meta[0]) : '';
    const json = JSON.stringify({ ...meta, ...(data as any) });
    const updatedCase = await casesService.update(caseId, json);

    updatePatientCaseStore(updatedCase);
  }, []);

  const editVersion = useCallback(async (data: CaseVersion) => {
    const casesService = container.get<ICasesService>(INJECTED_TYPES.ICasesService);

    await casesService.editCaseVersion(caseId, data);
  }, []);

  const copyVersion = useCallback(
    async (versionIdToCopy: string) => {
      const casesService = container.get<ICasesService>(INJECTED_TYPES.ICasesService);
      setWebBusy(true);
      await casesService.cloneCaseVersion(caseId, versionIdToCopy);
      setWebBusy(false);
      fetchPatientCaseVersions();
    },
    [versions]
  );

  const deleteVersion = useCallback(
    async (versionId: string) => {
      const casesService = container.get<ICasesService>(INJECTED_TYPES.ICasesService);

      await casesService.deleteCaseVersion(caseId, versionId);
      fetchPatientCaseVersions();
    },
    [fetchPatientCaseVersions]
  );

  const getMinorVersions = useCallback(async (versionId: string, numberOfSteps: number) => {
    const casesService = container.get<ICasesService>(INJECTED_TYPES.ICasesService);

    const minorVersions = await casesService.getMinorVersions(caseId, versionId, numberOfSteps);
    return minorVersions;
  }, []);

  return {
    patientCase,
    updatePatientCase,
    getPatientCase,
    editVersion,
    copyVersion,
    deleteVersion,
    getPatientCaseVersion,
    getMinorVersions,
    fetchPatientCaseVersions
  } as const;
}
