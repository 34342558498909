import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import PreviousStepIcon from '../../../../assets/icons/previous.svg';
import { NavBarAction } from './NavBarAction';
import { useSaveAndNavigateStep } from '../../../../hooks/shared/useSaveAndNavigateStep';

export default () => {
  const [t] = useTranslation();

  const { saveAndNavigateStep } = useSaveAndNavigateStep({ isDirectNavigation: true, checkPhaseChanges: true });
  const onBackButtonClick = () => {
    saveAndNavigateStep();
  };

  const buttonId = 'previousStep';

  return (
    <>
      <NavBarAction
        icon={PreviousStepIcon}
        dataFor={buttonId}
        altText={t('navbar.actions.previousStep')}
        action={onBackButtonClick}
      />
      <ReactTooltip id={buttonId} place="bottom" effect="solid" className="tooltip" type="dark">
        {t(`navbar.actions.previousStep`)}
      </ReactTooltip>
    </>
  );
};
