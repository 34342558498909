import { ActionComponent } from '../../../../../models';

export const navBarActionsConfigOrtho = {
  content: {
    body: [
      {
        '/general-information': [
          { component: ActionComponent.nextStepAction, isDirectNavigation: true },
          { component: ActionComponent.informationAction }
        ]
      },
      {
        '/specific-information': [
          { component: ActionComponent.previousStepAction },
          { component: ActionComponent.nextStepAction, isDirectNavigation: true },
          { component: ActionComponent.informationAction }
        ]
      },
      {
        '/stl-captures': [
          { component: ActionComponent.nextStepAction, isEndOfBlock: true },
          { component: ActionComponent.undoAction },
          { component: ActionComponent.redoAction, isEndOfBlock: true },
          { component: ActionComponent.orthoTeethArch, changeSplitMode: true },
          { component: ActionComponent.camViewAction },
          { component: ActionComponent.showGridAction },
          { component: ActionComponent.showAxisAction },
          { component: ActionComponent.informationAction }
        ]
      },
      {
        '/photo-captures': [
          { component: ActionComponent.previousStepAction },
          { component: ActionComponent.nextStepDirectAction },
          { component: ActionComponent.confirmAction, isEndOfBlock: true },
          { component: ActionComponent.informationAction }
        ]
      },
      {
        '/dicom-captures': [
          { component: ActionComponent.previousStepAction },
          { component: ActionComponent.nextStepDirectAction },
          { component: ActionComponent.confirmAction, isEndOfBlock: true },
          { component: ActionComponent.orthoTeethArch, changeSplitMode: true },
          { component: ActionComponent.showAxisAction },
          { component: ActionComponent.camViewAction },
          { component: ActionComponent.informationAction }
        ]
      },

      {
        '/mesh-correction': [
          { component: ActionComponent.previousStepAction },
          { component: ActionComponent.nextStepDirectAction },
          { component: ActionComponent.confirmAction, isEndOfBlock: true },
          { component: ActionComponent.informationAction }
        ]
      },
      {
        '/import-master-3d': [
          { component: ActionComponent.previousStepAction },
          { component: ActionComponent.nextStepAction, isDirectNavigation: true, checkPhaseChanges: true },
          { component: ActionComponent.confirmAction, isEndOfBlock: true },
          { component: ActionComponent.informationAction }
        ]
      },
      {
        '/dental-movements': [
          { component: ActionComponent.orthoTeethArch, changeSplitMode: true, isEndOfBlock: true },
          { component: ActionComponent.uploadNewModelFileAction },
          { component: ActionComponent.camViewAction },
          { component: ActionComponent.showGridAction },
          { component: ActionComponent.showAxisAction },
          { component: ActionComponent.informationAction }
        ]
      },
      {
        '/teeth-segmentation': [
          { component: ActionComponent.previousStepAction },
          {
            component: ActionComponent.nextStepAction,
            isEndOfBlock: true,
            isDirectNavigation: true,
            checkPhaseChanges: true
          },
          { component: ActionComponent.undoAction },
          { component: ActionComponent.redoAction, isEndOfBlock: true },
          { component: ActionComponent.orthoTeethArch, changeSplitMode: false },
          { component: ActionComponent.camViewAction },
          { component: ActionComponent.showGridAction },
          { component: ActionComponent.showAxisAction },
          { component: ActionComponent.informationAction }
        ]
      },
      {
        '/axis-roots': [
          { component: ActionComponent.previousStepAction },
          {
            component: ActionComponent.nextStepAction,
            isEndOfBlock: true,
            isDirectNavigation: true,
            checkPhaseChanges: true
          },
          { component: ActionComponent.undoAction },
          { component: ActionComponent.redoAction, isEndOfBlock: true },
          { component: ActionComponent.orthoTeethArch, changeSplitMode: false },
          { component: ActionComponent.camViewAction },
          { component: ActionComponent.showGridAction },
          { component: ActionComponent.showAxisAction },
          { component: ActionComponent.informationAction }
        ]
      },
      {
        '/treatment': [
          { component: ActionComponent.previousStepAction },
          {
            component: ActionComponent.nextStepAction,
            isEndOfBlock: true,
            isDirectNavigation: true,
            checkPhaseChanges: true
          },
          { component: ActionComponent.undoAction },
          { component: ActionComponent.redoAction, isEndOfBlock: true },
          { component: ActionComponent.orthoTeethArch, changeSplitMode: true },
          { component: ActionComponent.camViewAction },
          { component: ActionComponent.showGridAction },
          { component: ActionComponent.showAxisAction },
          { component: ActionComponent.informationAction }
        ]
      },
      {
        '/ipr': [
          { component: ActionComponent.previousStepAction },
          {
            component: ActionComponent.nextStepAction,
            isEndOfBlock: true,
            isDirectNavigation: true,
            checkPhaseChanges: true
          },
          { component: ActionComponent.undoAction },
          { component: ActionComponent.redoAction, isEndOfBlock: true },
          { component: ActionComponent.orthoTeethArch, changeSplitMode: false },
          { component: ActionComponent.camViewAction },
          { component: ActionComponent.showGridAction },
          { component: ActionComponent.showAxisAction },
          { component: ActionComponent.informationAction }
        ]
      },
      {
        '/attaches': [
          { component: ActionComponent.previousStepAction },
          {
            component: ActionComponent.nextStepAction,
            isEndOfBlock: true,
            isDirectNavigation: true,
            checkPhaseChanges: true
          },
          { component: ActionComponent.undoAction },
          { component: ActionComponent.redoAction, isEndOfBlock: true },
          { component: ActionComponent.orthoTeethArch, changeSplitMode: false },
          { component: ActionComponent.camViewAction },
          { component: ActionComponent.showGridAction },
          { component: ActionComponent.showAxisAction },
          { component: ActionComponent.informationAction }
        ]
      },
      {
        '/publish': [
          { component: ActionComponent.previousStepAction },
          { component: ActionComponent.undoAction },
          { component: ActionComponent.redoAction, isEndOfBlock: true },
          { component: ActionComponent.orthoTeethArch, changeSplitMode: false },
          { component: ActionComponent.camViewAction },
          {
            component: ActionComponent.showGridAction
          },
          { component: ActionComponent.informationAction }
        ]
      }
    ]
  }
};
