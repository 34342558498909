import Dropdown from 'react-bootstrap/esm/Dropdown';
import { useTranslation } from 'react-i18next';
import { CustomButton } from './CustomButton';
import { ReactComponent as ArrowRight } from '../../../assets/icons/chevron-right.svg';

type ContextSubMenuProps = {
  children: SubMenuOptions[];
  labelKey: string;
  id?: string;
};

type SubMenuOptions = {
  labelKey: string;
  action?: () => void;
  disabled?: boolean;
};

export function ContextSubMenu({ children, labelKey, id }: ContextSubMenuProps) {
  const [t] = useTranslation();
  return (
    <Dropdown className="context-menu--submenu" key={id} drop="end">
      <Dropdown.Toggle as={CustomButton} className="context-menu">
        {t(labelKey)}
        <ArrowRight />
      </Dropdown.Toggle>
      <Dropdown.Menu className="context-menu">
        {children?.map((option, index) => {
          const id = `context-menu__item-children-${index}`;
          return (
            <Dropdown.Item key={id} className="context-menu" onClick={option?.action} disabled={option.disabled}>
              {t(option.labelKey)}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
