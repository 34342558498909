import { useTranslation } from 'react-i18next';
import { MovementType, SourceOfMovement } from '../../common';
import { useCommonBoundStore } from '../../common/stores/useStore';
import { TreatmentChangeKey, TreatmentChangeValue, decodeTreatmentChangeKey } from '../../orthodontics/models';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { RequestedChangeToShow } from '../../surgeries/components/layout';

export function useTreatmentSuggestedChanges() {
  const [t] = useTranslation();
  const { treatmentSuggestedChanges, setTreatmentSuggestedChanges, setShowMovements } = useOrthBoundStore();
  const { setIsNewStepClientModification } = useCommonBoundStore();

  const getMappedMovementName = (movement: MovementType) => {
    const movementToStringMap: { [key: string]: string } = {
      [MovementType.ExtrusionIntrusion]: 'dentalMovements.movementsTool.teeth.extrusionIntrusion',
      [MovementType.TranslationVL]: 'dentalMovements.movementsTool.teeth.vlTraslation',
      [MovementType.TranslationMD]: 'dentalMovements.movementsTool.teeth.mdTraslation',
      [MovementType.PureRotation]: 'dentalMovements.movementsTool.teeth.pureRotation',
      [MovementType.MesialRotation]: 'dentalMovements.movementsTool.teeth.mesialRotation',
      [MovementType.DistalRotation]: 'dentalMovements.movementsTool.teeth.distalRotation',
      [MovementType.Torque]: 'dentalMovements.movementsTool.teeth.torque',
      [MovementType.TIP]: 'dentalMovements.movementsTool.teeth.tip',
      [MovementType.MultiplePlane]: 'dentalMovements.movementsTool.teeth.multiplePlane',
      [MovementType.Reset]: 'dentalMovements.movementsTool.teeth.reset'
    };

    return movementToStringMap[movement];
  };

  const getMappedSourceName = (source: SourceOfMovement) => {
    const sourceToStringMap: Record<SourceOfMovement, string> = {
      [SourceOfMovement.Crown]: 'dentalMovements.movementsTool.teeth.crown',
      [SourceOfMovement.Root]: 'dentalMovements.movementsTool.teeth.root'
    };

    return sourceToStringMap[source];
  };

  const getTreatmentChangesToShow = (): RequestedChangeToShow[] => {
    const changes: RequestedChangeToShow[] = [];

    for (const treatmentChangeKey in treatmentSuggestedChanges) {
      if (treatmentSuggestedChanges.hasOwnProperty(treatmentChangeKey)) {
        const changeKey: TreatmentChangeKey = decodeTreatmentChangeKey(treatmentChangeKey);
        const changeValue: TreatmentChangeValue = treatmentSuggestedChanges[treatmentChangeKey];

        const valueWithMeasure = `${changeValue.value > 0 ? '+' : ''}${changeValue.value}${changeValue.measureUnit}`;
        const kind = `${t(getMappedMovementName(changeKey.kind as MovementType))}`;
        const source = `${t(getMappedSourceName(changeKey.source as SourceOfMovement))}`;
        const request = `${valueWithMeasure} ${t('common.of')} ${kind} ${source}`;

        changes.push({
          request,
          pieceIdentifier: changeKey.pieceIdentifier
        });
      }
    }

    return changes;
  };

  const cancelTreatmentChanges = () => {
    setIsNewStepClientModification(false);
    window.App.webEventsProxy.common.setEditionMode(false);
    setShowMovements(false);
    setTreatmentSuggestedChanges({});
  };

  return {
    getTreatmentChangesToShow,
    cancelTreatmentChanges
  };
}
