import { FunctionComponent } from 'react';
import { evergineOrthoToolbarConfig } from '../../../orthodontics/components/';
import { ModalTypes } from './../../../surgeries/components/layout/modal/modalTypes/modal-types.enum';

export type ToolbarConfigProps = {
  icon: FunctionComponent;
  customId?: string;
  tooltipKey?: string;
  labelKey?: string;
  handleClick?: () => void;
  shortcut?: string;
  renderCustomComponent?: boolean;
  customComponent?: any;
  isHidden?: () => boolean;
  openModal?: boolean;
  modalType?: ModalTypes;
  keepOpen?: boolean;
  hiddenForClient?: boolean;
  isDisabled?: () => boolean;
  conditionalIcon?: () => FunctionComponent;
};

export const toolbarConfig: { [key: number]: ToolbarConfigProps[] } = {
  ...evergineOrthoToolbarConfig
};
