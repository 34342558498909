import { useEvergineStore } from 'evergine-react';
import { useEffect, useState } from 'react';
import { ModelInstance, Split, Viewport } from '../common';
import { CaseType, TeethArchPosition } from '../models';
import { useOrthBoundStore } from '../orthodontics/stores/useStore';
import { useBoundStore } from '../surgeries/stores/useStore';
import { Stage } from '../common/evergine/types';

export function useBaseCaptureActionManager(modelsId: any, modelStringType: string, caseType: CaseType) {
  const { evergineReady } = useEvergineStore();
  const { splitMode } = useBoundStore();
  const { orthTeethArch } = useOrthBoundStore();
  const [showUpperCapture, setShowUpperCapture] = useState(true);
  const [showLowerCapture, setShowLowerCapture] = useState(true);

  useEffect(() => {
    if (!evergineReady) {
      return;
    }

    const upperModel = modelsId['UPPER'][modelStringType];
    const lowerModel = modelsId['LOWER'][modelStringType];

    const view: { [key: string]: ModelInstance[] } = {
      [TeethArchPosition.UPPER]: [{ modelId: upperModel }],
      [TeethArchPosition.LOWER]: [{ modelId: lowerModel }],
      [TeethArchPosition.BOTH]: [{ modelId: upperModel }]
    };

    if (splitMode === Split.Single) {
      const currentModel = upperModel != null ? upperModel : lowerModel != null ? lowerModel : null;
      const model: ModelInstance[] = orthTeethArch ? view[orthTeethArch] : [{ modelId: currentModel }];
      const viewPort: Viewport | null = model ? { modelInstances: model } : null;

      if (caseType === CaseType.Surgery) {
        setShowUpperCapture((!upperModel && !lowerModel) || !!upperModel);
        setShowLowerCapture(!upperModel && !!lowerModel);
      } else {
        setShowUpperCapture(orthTeethArch === TeethArchPosition.UPPER || orthTeethArch === TeethArchPosition.BOTH);
        setShowLowerCapture(orthTeethArch === TeethArchPosition.LOWER || orthTeethArch === TeethArchPosition.BOTH);
      }
    } else {
      setShowLowerCapture(true);
      setShowUpperCapture(true);
    }
  }, [splitMode, orthTeethArch]);

  return { showLowerCapture, showUpperCapture } as const;
}
