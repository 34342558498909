import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg';
import { useOrthBoundStore } from '../../../../stores/useStore';
import { useCommonBoundStore } from '../../../../../common/stores/useStore';
import { useAttaches } from '../../../../../hooks';
import { ModalTypes } from '../../../../../surgeries/components/layout';
import { tadsTypes } from './tadsTypes';
import { AttacheModel } from '../attaches/attacheModel/AttacheModel';
import { ToolPanelDraggableContainer } from '../ToolPanelDraggableContainer';

import './tadsTools.scss';

export function TadsTools() {
  const { setShowTadsPanel, setAttachActive, setShowAttachesPanel } = useOrthBoundStore((state) => ({
    setShowTadsPanel: state.setShowTadsPanel,
    setAttachActive: state.setAttachActive,
    setShowAttachesPanel: state.setShowAttachesPanel
  }));
  const [t] = useTranslation();
  const { noTeethSelected, inFirstOrLastStep, noAttacheSelected } = useAttaches();

  const PANEL_SIZE = 144;

  const toolPosition = useMemo(() => {
    return document.getElementById('orthTadsButton')?.getBoundingClientRect();
  }, []);

  const { toggleModalIsOpened } = useCommonBoundStore();

  const handleClose = useCallback(() => {
    setShowTadsPanel(false);
    setAttachActive(null);
  }, [setShowTadsPanel, setAttachActive]);

  const showDeleteModal = useCallback(() => {
    toggleModalIsOpened(ModalTypes.ModalDeleteAttach);
  }, [toggleModalIsOpened]);

  const mustDisableRemove = useCallback(() => {
    return (noTeethSelected && noAttacheSelected) || inFirstOrLastStep;
  }, [noTeethSelected, noAttacheSelected, inFirstOrLastStep]);

  useEffect(() => {
    setShowAttachesPanel(false);
  }, []);

  return (
    <ToolPanelDraggableContainer
      panelSize={PANEL_SIZE}
      toolPosition={toolPosition}
      handlerElementClass="handleOrthTADS"
    >
      <div className="tadstools no-select">
        <div className="tadstools-header">
          <div className={`tadstools-title handleOrthTADS`}>
            <div>{t('evergineTools.tads')}</div>
          </div>

          <button type="button" className="tadstools-close" onClick={handleClose}>
            <CloseIcon className="clickable" />
          </button>
        </div>
        <div className="tadstools-content">
          <div className="tadstools-models">
            {tadsTypes.map((attacheType) => (
              <AttacheModel model={attacheType} key={`tadtype-${attacheType.id}`} />
            ))}
          </div>
          <button
            onClick={showDeleteModal}
            type="button"
            className="btn btn-outline-primary flex-fill tadstools-content__delete"
            disabled={mustDisableRemove()}
          >
            {t('common.delete')}
          </button>
        </div>
      </div>
    </ToolPanelDraggableContainer>
  );
}
