import { CSSProperties, useCallback, useEffect, useMemo } from 'react';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import { useTranslation } from 'react-i18next';

import './contextMenu.scss';
import { ContextSubMenu } from './ContextSubMenu';

export type ContextMenuOptions = {
  labelKey: string;
  action?: () => void;
  children?: ContextMenuOptions[];
  disabled?: boolean;
  isHidden?: boolean;
  checked?: boolean;
  hasUpperDivider?: boolean;
  customJSX?: React.ReactNode;
};

export interface ContextMenuProps {
  options: ContextMenuOptions[];
  positionData: CSSProperties;
  show?: boolean;
  autoClose?: 'outside' | 'inside' | boolean;
  onToggle: () => void;
}

export function ContextMenu({ options, positionData, show, autoClose = true, onToggle }: ContextMenuProps) {
  const [t] = useTranslation();
  const Separator = () => {
    return <div className="context-menu__separator" />;
  };

  const visibleOptions = useMemo(() => options.filter((o) => !o.isHidden), [options]);

  return (
    <Dropdown className="context-menu" style={positionData} show={show} onToggle={onToggle} autoClose={autoClose}>
      <Dropdown.Menu className="context-menu">
        {visibleOptions.map((option, index) => {
          const id = `context-menu__item-${index}`;

          return option?.children ? (
            <div key={id}>
              <ContextSubMenu children={option.children} labelKey={option.labelKey} />
            </div>
          ) : (
            <div key={id}>
              {index !== 0 && option.hasUpperDivider && <Separator />}
              <Dropdown.Item className="context-menu" onClick={option.action} disabled={option.disabled}>
                {option.customJSX ? option.customJSX : t(option.labelKey)}
              </Dropdown.Item>
            </div>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
