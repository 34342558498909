import React from 'react';
import { useTranslation } from 'react-i18next';
import Logo from '../../../assets/images/logo.png';

import './simplemessagescreen.scss';

interface SimpleMessageScreenProps {
  message: string;
  icon: React.ElementType;
  subMessage?: string;
}

export function SimpleMessageScreen({ message, icon: Icon, subMessage }: SimpleMessageScreenProps) {
  const [t] = useTranslation();
  return (
    <div className="simplemessage">
      <div className="simplemessage-logo">
        <img src={Logo} alt={t('common.company')} />
      </div>
      <Icon />
      <div className="simplemessage-message">
        <h2 className="simplemessage-message_title">{message}</h2>
        {subMessage && <h3 className="simplemessage-message_subtitle">{subMessage}</h3>}
      </div>
    </div>
  );
}
