import { useCallback, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { INJECTED_TYPES, container } from '../../../../../common';
import Radio from '../../../../../common/components/radio/Radio';
import { useCommonBoundStore } from '../../../../../common/stores/useStore';
import { useCaseId, useChat, useUserProfileData } from '../../../../../hooks';
import { useOrthBoundStore } from '../../../../../orthodontics/stores/useStore';
import { ClientPlanValidationEnum } from '../../../../../shared/clientPlanValidations/clientPlanValidation';
import { IClientPlanValidationService } from '../../../../../shared/clientPlanValidations/iclientPlanValidation.service';
import { useBoundStore } from '../../../../stores/useStore';
import { useToothTransformStore } from '../../../../../orthodontics/stores/useToothTransformStore';

export function ModalClientValidationsEdit() {
  const {
    toggleModalIsOpened,
    setIsNewStepClientModification,
    updateIsVersionDisabled,
    setIsThereFeedbackForTreatment
  } = useCommonBoundStore((state) => ({
    toggleModalIsOpened: state.toggleModalIsOpened,
    setIsNewStepClientModification: state.setIsNewStepClientModification,
    updateIsVersionDisabled: state.updateIsVersionDisabled,
    setIsThereFeedbackForTreatment: state.setIsThereFeedbackForTreatment
  }));
  const { currentVersion } = useOrthBoundStore((state) => ({
    currentVersion: state.currentVersion
  }));
  const setTeethTransformDataList = useToothTransformStore((state) => state.setTeethTransformDataList);
  const setChatTextToSend = useBoundStore((state) => state.setChatTextToSend);

  const [t] = useTranslation();
  const [caseId] = useCaseId();
  const { sendChatMessage } = useChat(caseId);
  const [userProfile] = useUserProfileData();

  const [isClientModification, setIsClientModification] = useState(false);
  const [isDescriptionRequired, setIsDescriptionRequired] = useState(false);

  const form = useRef<HTMLFormElement>();

  const onCloseButtonClick = () => {
    toggleModalIsOpened('');
    updateIsVersionDisabled(false);
    setIsNewStepClientModification(false);
    window.App.webEventsProxy.common.setEditionMode(false);
  };

  const onContinueButtonClick = useCallback(() => {
    if (!form.current) {
      return;
    }

    const description = form.current[2] as HTMLTextAreaElement;
    const hasDescription = !!description.value.trim() && description.checkValidity();
    setChatTextToSend(description.value);

    const isNotValid = !hasDescription && !isClientModification;

    if (isNotValid) {
      setIsDescriptionRequired(true);
      return;
    }

    setIsDescriptionRequired(false);

    if (isClientModification) {
      updateIsVersionDisabled(true);
      setIsNewStepClientModification(true);
      setTeethTransformDataList([]);
      window.App.webEventsProxy.common.setEditionMode(true);
    } else {
      sendClientValidation(description, hasDescription);
    }

    toggleModalIsOpened('');
  }, [caseId, isClientModification, userProfile, toggleModalIsOpened]);

  const sendClientValidation = useCallback(
    (description: HTMLTextAreaElement, hasDescription: boolean) => {
      const clientPlanValidationService = container.get<IClientPlanValidationService>(
        INJECTED_TYPES.IClientPlanValidationService
      );

      clientPlanValidationService
        .clientPlanValidation(caseId, currentVersion?.id, {
          clientId: userProfile?.id,
          result: ClientPlanValidationEnum.RequestedChanges,
          requestedChangesText: description.value,
          requestedMovements: [],
          isClientModification: false,
          treatmentChangeItems: []
        })
        .then(() => {
          setIsThereFeedbackForTreatment(true);
          sendChatMessage(ClientPlanValidationEnum.RequestedChanges, description.value, hasDescription);
          toast.success(`${t('modal.clientValidation.edit.requestedChanges')}`);
        });
    },
    [onContinueButtonClick]
  );

  const onRadioButtonsChange = useCallback((selection: boolean) => {
    const description = form.current[2] as HTMLTextAreaElement;
    const hasDescription = !!description.value.trim() && description.checkValidity();

    setIsClientModification(selection);
    setIsDescriptionRequired(!selection && !hasDescription);
  }, []);

  return (
    <div className="modal-content" data-testid={'modal-client-validations-edit'}>
      <div className="modal-body d-flex flex-column">
        <h3 className="modal-title mb-4">{t('modal.clientValidation.edit.title')}</h3>
        <p className="modal-text mb-3">{t('modal.clientValidation.edit.description')}</p>

        <Form ref={form}>
          <div className="modal-row">
            <Form.Group className="modal-col">
              <Radio
                active={isClientModification}
                label={t('common.yes')}
                callBack={() => onRadioButtonsChange(true)}
              />
            </Form.Group>
            <Form.Group className="modal-col">
              <Radio
                active={isClientModification === false}
                label={t('common.no')}
                callBack={() => onRadioButtonsChange(false)}
              />
            </Form.Group>
          </div>
          <Form.Group>
            <Form.Label className="modal-label">
              {t('modal.clientValidation.edit.formDescription')}
              {isDescriptionRequired && (
                <span className="modal-danger">({t('modal.clientValidation.edit.required')})</span>
              )}
            </Form.Label>
            <Form.Control as="textarea" rows={5} style={{ maxHeight: '400px' }} />
          </Form.Group>
        </Form>
      </div>

      <div className="modal-footer d-flex flex-grow-1">
        <button onClick={onCloseButtonClick} type="button" className="btn btn-outline-primary" data-bs-dismiss="modal">
          {t(`modal.actions.cancel`)}
        </button>
        <button onClick={onContinueButtonClick} data-testid="cv-send" type="button" className="btn btn-primary">
          {t(`modal.actions.send`)}
        </button>
      </div>
    </div>
  );
}
