import create from 'zustand';
import { createToothTransformSlice, ToothTransformSlice } from './slices/toothTransformSlice';

export type ToothTransformState = ToothTransformSlice;

/* eslint-enable*/

export const useToothTransformStore = create<ToothTransformState>()((...a) => ({
  ...createToothTransformSlice(...a)
}));
