import { CameraPosition, ContextMenuOptions } from '../../common';
import { useCamViewChange } from '..';
export function useProtrusionViewContextMenu() {
  const { setCameraPosition } = useCamViewChange();
  const protrusionOptions = [
    {
      labelKey: 'contextMenuOptions.protrusionView.rightBuccalProtrusion',
      action: () => {
        setCameraPosition(CameraPosition.VestibularHighlightRight);
      },
      disabled: false,
      isHidden: false,
      hasUpperDivider: true
    },
    {
      labelKey: 'contextMenuOptions.protrusionView.leftBuccalProtrusion',
      action: () => {
        setCameraPosition(CameraPosition.VestibularHighlightLeft);
      },
      disabled: false,
      isHidden: false
    },
    {
      labelKey: 'contextMenuOptions.protrusionView.incisorProtrusion',
      action: () => {
        setCameraPosition(CameraPosition.IncisorHighlight);
      },
      disabled: false,
      isHidden: false
    }
  ] as ContextMenuOptions[];
  return { protrusionOptions } as const;
}
