import { useTranslation } from 'react-i18next';
import Accordion from 'react-bootstrap/esm/Accordion';
import { Language } from '../../../../../app.i18n';
import Radio from '../../../radio/Radio';

interface ConfigLanguageSelectorProps {
  localCurrentLanguage: string;
  changeLocalLanguage: (language: string) => void;
}

export function ConfigLanguageSelector({ localCurrentLanguage, changeLocalLanguage }: ConfigLanguageSelectorProps) {
  const [t] = useTranslation();

  return (
    <Accordion>
      <Accordion.Item eventKey="language">
        <Accordion.Header className="configuration-section--title">
          {t('navbar.actions.configuration.language.title')}
        </Accordion.Header>
        <Accordion.Body>
          <Radio
            labelOnRight={false}
            expanded={true}
            active={localCurrentLanguage === Language.English}
            label={t('navbar.actions.configuration.language.english')}
            callBack={() => changeLocalLanguage(Language.English)}
          />
          <Radio
            labelOnRight={false}
            expanded={true}
            active={localCurrentLanguage === Language.Spanish}
            label={t('navbar.actions.configuration.language.spanish')}
            callBack={() => changeLocalLanguage(Language.Spanish)}
          />
          <Radio
            labelOnRight={false}
            expanded={true}
            active={localCurrentLanguage === Language.Portuguese}
            label={t('navbar.actions.configuration.language.portuguese')}
            callBack={() => changeLocalLanguage(Language.Portuguese)}
          />
          <Radio
            labelOnRight={false}
            expanded={true}
            active={localCurrentLanguage === Language.French}
            label={t('navbar.actions.configuration.language.french')}
            callBack={() => changeLocalLanguage(Language.French)}
          />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
