import { useEffect } from 'react';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { isTadType } from '../../orthodontics/components/evergineToolbarElements/tools/tads/tadsTypes';
import { isAttacheType } from '../../orthodontics/components/evergineToolbarElements/tools/attaches/attacheTypes';

export function useAttachmentPanels() {
  const { setShowTadsPanel, setShowAttachesPanel, selectedAttache, isAttacheSelected } = useOrthBoundStore((state) => ({
    setShowTadsPanel: state.setShowTadsPanel,
    setShowAttachesPanel: state.setShowAttachesPanel,
    selectedAttache: state.selectedAttache,
    isAttacheSelected: state.isAttacheSelected
  }));

  useEffect(() => {
    if (!isAttacheSelected || selectedAttache === null) {
      return;
    }

    if (isTadType(selectedAttache.type)) {
      setShowAttachesPanel(false);
      setShowTadsPanel(true);
    } else if (isAttacheType(selectedAttache.type)) {
      setShowTadsPanel(false);
      setShowAttachesPanel(true);
    }
  }, [selectedAttache, isAttacheSelected]);
}
