import { useNavigate } from 'react-router-dom';
import { useCaseId, useGateKeeper } from '../../../hooks';
import { AbilityAction, CommonAbilitySubject } from '../../../shared';
import { Odontogram } from '../../../surgeries/components/odontogram';
import { BaseInfo } from './BaseInfo';
import './generalinfo.scss';
import { useBoundStore } from '../../../surgeries/stores/useStore';

export function GeneralInfo() {
  const navigate = useNavigate();
  useGateKeeper(AbilityAction.View, CommonAbilitySubject.GeneralInfoScreen, () => navigate('/forbidden'));

  const patientCase = useBoundStore((state) => state.patientCase);

  return (
    <div className="generalinfo container-md">
      {patientCase && (
        <div className="row ">
          <div className="generalinfo-odontogram col-lg-4  col-md-12">
            <Odontogram
              showTitle={true}
              selectedPieces={[]}
              highlightPieces={patientCase.patient.pieces?.map((piece) => piece.number) ?? []}
            />
          </div>
          <BaseInfo patientCase={patientCase} />
        </div>
      )}
    </div>
  );
}
