import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import { AttachmentType, IconTextInfo } from '../../../../../../common';
import { useCallback, useMemo } from 'react';
import { useUtils } from '../../../../../../hooks/shared/useUtils';
import { useOrthBoundStore } from '../../../../../stores/useStore';
import { useAttaches } from '../../../../../../hooks';

import './attacheModel.scss';

interface AttacheModelProps {
  model: IconTextInfo;
}

export function AttacheModel({ model }: AttacheModelProps) {
  const [t] = useTranslation();
  const { updateMovements } = useUtils();
  const { noTeethSelected, inFirstOrLastStep, noAttacheSelected } = useAttaches();
  const { selectedTeethId, attachActive, setAttachActive, selectedAttache } = useOrthBoundStore((state) => ({
    selectedTeethId: state.selectedTeethId,
    attachActive: state.attachActive,
    setAttachActive: state.setAttachActive,
    selectedAttache: state.selectedAttache
  }));

  const handleAttach = (type: AttachmentType) => {
    setAttachActive(type);

    if (selectedTeethId.length > 0) {
      selectedTeethId.forEach((id) => {
        window.App.webEventsProxy.attachments.addAttachment(id, type);
      });
    } else if (selectedAttache && !!selectedAttache.toothFdi) {
      window.App.webEventsProxy.attachments.addAttachment(selectedAttache.toothFdi, type);
    }

    updateMovements();
  };

  const isActive = useMemo(() => {
    if (attachActive === model.typeAtt) {
      return true;
    }
    return false;
  }, [attachActive]);

  const mustDisable = useCallback(() => {
    return (noTeethSelected && noAttacheSelected) || inFirstOrLastStep;
  }, [noTeethSelected, noAttacheSelected, inFirstOrLastStep]);

  return (
    <div className="attachemodel" key={model.id}>
      <button
        data-for={model.id}
        data-tip
        id={model.id}
        disabled={mustDisable()}
        className={`etoolbar-button clickable ${isActive ? 'is-active' : ''}`}
        onClick={() => handleAttach(model.typeAtt)}
      >
        <div>{<model.icon />}</div>
        <ReactTooltip id={model.id} place="top" effect="solid" className="tooltip" type="dark">
          {t(model.text)}
        </ReactTooltip>
      </button>
    </div>
  );
}
