import { StateCreator } from 'zustand';

export enum CaseIdentifierType {
  CaseId = 'caseId',
  PatientName = 'patientName'
}

export interface DesignerSettingsSlice {
  caseIdentifierType: CaseIdentifierType;
  setCaseIdentifierType: (caseIdentifierType: CaseIdentifierType) => void;
}
export const createDesignerSettingsSlice: StateCreator<DesignerSettingsSlice, [], [], DesignerSettingsSlice> = (
  set
) => ({
  caseIdentifierType: CaseIdentifierType.CaseId,
  setCaseIdentifierType: (caseIdentifierType: CaseIdentifierType) => set(() => ({ caseIdentifierType }))
});
