import { useContext, useMemo, useState } from 'react';
import { AbilityAction, AbilityContext, OrthoAbilitySubject } from '../../shared';
import { useDentalMovements } from '../orthodontics';
import { useCaseId } from './useCaseId';

export function useGeneralPanels() {
  const [evolutionPanelWidth, setEvolutionPanelWidth] = useState<number>();
  const getWidthFromEvolutionPanel = (getWidthFunction: () => number) => {
    const width = getWidthFunction();
    setEvolutionPanelWidth(width);
  };

  const ability = useContext(AbilityContext);
  const [caseId] = useCaseId();
  const { showInfoPanel, handleInfoPanelToggle } = useDentalMovements(caseId);

  const showLateralPanelIfAllowed = useMemo(() => {
    if (!ability || ability.can(AbilityAction.View, OrthoAbilitySubject.InfoPanel)) {
      return true;
    }
    return false;
  }, [showInfoPanel, ability, handleInfoPanelToggle]);

  return {
    getWidthFromEvolutionPanel,
    evolutionPanelWidth,
    showLateralPanelIfAllowed
  };
}
