import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadExcelFile } from '../../../../common/utils';
import { useHistoryTreatmentChanges } from '../../../../hooks/orthodontics';
import { AbilityAction, AbilityContext, OrthoAbilitySubject } from '../../../../shared';
import { HistoryItem } from '../../../models';
import { Accordion } from './Accordion/Accordion';
import { ListChanges } from './ListChanges/ListChanges';
import './changesTab.scss';

export function ChangesTab() {
  const { historyChanges } = useHistoryTreatmentChanges();
  const [t] = useTranslation();
  const getTranslation = (key: string) => t(`dentalMovements.excelColumns.${key}`);
  const ability = useContext(AbilityContext);
  const canDownloadFile = ability.can(AbilityAction.Manage, OrthoAbilitySubject.DownloadDentalMovementHistoric);

  const getFileName = (data: HistoryItem) => {
    const fileName = `${getTranslation('modifications')}-${data.versionName.replace(/\s/g, '')}-${data.date}`;

    return fileName;
  };

  const onDownload = (data: HistoryItem) => {
    const fileName = getFileName(data);

    const header = [
      getTranslation('versionName'),
      getTranslation('date'),
      getTranslation('description'),
      getTranslation('piece')
    ];

    const excelData = [
      header,
      ...data.modifications.map((mod) => [data.versionName, data.date, mod.description, mod.piece])
    ];

    downloadExcelFile(excelData, fileName);
  };

  return (
    <div className="changes-tab accordion">
      {historyChanges.length > 0 ? (
        historyChanges.map((data, i) => (
          <Accordion
            title={data.versionName}
            date={data.date}
            key={`key-${data.versionName}-${i}`}
            download={canDownloadFile}
            onDownload={() => onDownload(data)}
          >
            <ListChanges items={data.modifications} />
          </Accordion>
        ))
      ) : (
        <p className="no-data">{t('changes.notChanges')}</p>
      )}
    </div>
  );
}
