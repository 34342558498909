import { injectable } from 'inversify';
import { INJECTED_TYPES, container } from '../../common';
import { IHttpService } from '../http';
import { AIMovementsRequestDto, AIMovementsResponseDto } from './AIMovements';
import { IAIMovementsService } from './IAIMovements.service';

@injectable()
export class AIMovementsService implements IAIMovementsService {
  private _httpService = container.get<IHttpService>(INJECTED_TYPES.IHttpService);

  private readonly apiEndpoint = '/ai/movements';

  public async getAIMovementsPrediction(requestDto: AIMovementsRequestDto): Promise<AIMovementsResponseDto> {
    const result = await this._httpService.post<AIMovementsResponseDto>(this.apiEndpoint, requestDto);
    return result.data;
  }

  private mapAIMovementsResponseDtoFromString(data: string): AIMovementsResponseDto {
    try {
      return JSON.parse(data) as AIMovementsResponseDto;
    } catch (error) {
      return {
        steps: []
      } as AIMovementsResponseDto;
    }
  }
}
